import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const CreateTable = ({ vuelo }) => {
    const row = vuelo;
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
            fontSize: "200pt",
        },
    });

    const classes = useStyles();

    return (
        <div style={{ marginBottom: 30 }}>
            <h2>Scheduled Flight</h2>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                    <TableCell align="center" style={{ fontSize: "14px" }}>
                            #
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Date
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Operation
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Customer
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Origin
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Destination
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Route
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Position
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            STD
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            STA
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Aircraft
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Chargeable weight
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            State
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: "14px" }}>
                            Remarks
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {row ? (
                        <TableRow>
                             <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.id_comercial}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.fecha ? row.fecha.split("T")[0] : ""}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.cod_operacion}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.cod_cliente}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.ciudad_origen}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.ciudad_destino}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.ruta}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.nombre_posicion}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.departure_prog}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.arrival_prog}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.cod_equipo_programado}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.peso_comercial}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.nombre_estado}
                            </TableCell>
                            <TableCell
                                align="center"
                                style={{ fontSize: "14px" }}
                            >
                                {row.observaciones}
                            </TableCell>
                        </TableRow>
                    ) : null}
                </TableBody>
            </Table>
        </div>
    );
};

export default CreateTable;
