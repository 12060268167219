import React, { useState, useEffect } from "react";
import http from "../utils/Http";
import moment from "moment";
import Modal from "../utils/Modal";
//import 'bootstrap/dist/css/bootstrap.min.css'

const horas = [
	"UTC 00:00",
	"01:00",
	"02:00",
	"03:00",
	"04:00",
	"05:00",
	"06:00",
	"07:00",
	"08:00",
	"09:00",
	"10:00",
	"11:00",
	"12:00",
	"13:00",
	"14:00",
	"15:00",
	"16:00",
	"17:00",
	"18:00",
	"19:00",
	"20:00",
	"21:00",
	"22:00",
	"23:00",
];

const horasLocal = [
	"Local 19:00",
	"20:00",
	"21:00",
	"22:00",
	"23:00",
	"00:00",
	"01:00",
	"02:00",
	"03:00",
	"04:00",
	"05:00",
	"06:00",
	"07:00",
	"08:00",
	"09:00",
	"10:00",
	"11:00",
	"12:00",
	"13:00",
	"14:00",
	"15:00",
	"16:00",
	"17:00",
	"18:00",
];

const hours = [
	...horas,
	...horas,
	...horas,
	...horas,
	...horas,
	...horas,
	...horas,
];

let midnight = new Date();
midnight.setHours(0, 0, 0, 0);

let midnightMoment = moment(midnight);

let day1 = moment(midnightMoment).add(1, "d");
let day2 = moment(midnightMoment).add(2, "d");
let day3 = moment(midnightMoment).add(3, "d");
let day4 = moment(midnightMoment).add(4, "d");
let day5 = moment(midnightMoment).add(5, "d");
let day6 = moment(midnightMoment).add(6, "d");

let days = [midnightMoment, day1, day2, day3, day4, day5, day6];

let dias = days.map((day) => day.format("ddd D/M"));

let startDay;

function getHours(timestamp) {
	let date = moment(timestamp);

	if (date < midnightMoment) {
		const hours1 = date.format("HH");
		const hours = parseInt(hours1);
		return hours;
	}
	const hours = date.diff(midnightMoment, "hours");
	return hours;
}

function setHours(timing) {
	if (!timing) return null;

	return {
		arrival: getHours(timing.arrival),
		departure: getHours(timing.departure),
		a: timing.arrival,
		d: timing.departure,
		eta: timing.eta,
		dayplus: timing.dayplusATD,
	};
}

function isInsideTime(timing, index) {
	if (!timing) return false;
	return timing === index;
}

function isInside(timing, index) {
	if (!timing) return false;
	const date = moment(timing);
	if (date < midnightMoment) {
		return false;
	}
	const after = index >= timing.departure;
	const before = index < timing.arrival;
	return after && before;
}

function insideManto(timing, index) {
	if (!timing) return false;
	const after = index >= timing.entry.entry;
	const before = index < timing.exit.exit;
	return after && before;
}

function RowMantenimiento({ indexed, nowHours }) {
	const [open, setOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	// Se formatea el tiempo actual local y se convierte a UTC
	let actualTime = moment(new Date().toUTCString());
	actualTime = getHours(actualTime.format()) + normalizarHora();

	let formated;
	let length = 0;
	const tds = hours.map((hour, index) => {
		// Se valida si se debe pintar la linea de tiempo actual
		const timeNow = isInsideTime(actualTime, index) ? "vl" : "";

		const manto = indexed.find((mtn) => {
			formated = moment(mtn.entry.en);
			const selectedDay = new Date();
			const selectedDate = moment(selectedDay);
			let selected = startDay ? startDay : selectedDate;
			selected = moment(selected).format("yyyy-MM-DD");
			let selectedMnt = moment(formated).format("yyyy-MM-DD");
			if (selected > selectedMnt) {
				mtn.entry.entry = 0;
			}
			length = mtn.exit.exit - mtn.entry.entry;
			const inside = insideManto(mtn, index);
			return inside;
		});

		if (manto) {
			const start = index === manto.entry.entry;
			if (start) {
				let style = {
					background: "#003783",
					fontSize: 14,
					color: "#fff",
					textAlign: "center",
					height: 50,
				};

				if (manto.tipo === "Not Scheduled") {
					style = {
						...style,
						background: "red",
					};
				}

				function onClick() {
					setOpen(true);

					setModalContent({
						entry: manto.entry.en,
						exit: manto.exit.ex,
						remarks: manto.remarks ? manto.remarks : "...",
						update_date: manto.update_date ? manto.update_date : "...",
					});
				}

				return (
					<td
						key={index}
						colSpan={length}
						style={style}
						onClick={onClick}
						className={timeNow}
					>
						Maintenance {<strong>{manto.tipo}</strong>}
					</td>
				);
			}
			return null;
		}
		return <td key={index} className={timeNow}></td>;
	});

	return (
		<>
			<Modal
				data={modalContent}
				open={open}
				handleClose={() => {
					setOpen(false);
				}}
				type="maintenance"
			/>
			{tds}
		</>
	);
}

const formatTime = (fecha) => {
	return fecha.replace("T", " ");
};

function RowItinerary({ indexed, nowHours }) {
	// Estado para la ventana modal
	const [open, setOpen] = useState(false);
	const [modalContent, setModalContent] = useState("");
	// Se formatea el tiempo actual local y se convierte a UTC
	let actualTime = moment().utc();

	actualTime = actualTime.format();
	actualTime = getHours(actualTime) + normalizarHora();

	const tds = hours.map((hour, index) => {
		// Se valida si se debe pintar la linea de tiempo actual
		const timeNow = isInsideTime(actualTime, index) ? "vl" : "";

		const flight = indexed.find((flight) => {
			const timing = flight.begin;
			const inside = isInside(timing, index);

			return inside;
		});

		if (flight) {
			const timing = flight.begin;
			const start = index === timing.departure;
			if (start) {
				const length = timing.arrival - timing.departure;
				// 1 = actual. 2 = Nuevo
				const colorr = flight.cod_antiguedad === "1" ? "#eb9834" : "#8c34eb";

				const style = {
					textAlign: "center",
					color: "#fff",
					background: `${colorr}`,
				};

				function onClick() {
					setOpen(true);
					setModalContent({
						ruta: flight.ruta,
						departure: flight.departure,
						arrival: flight.arrival,
						observaciones: flight.observaciones,
						cod_comercial: flight.cod_comercial,
						id_itinerario: flight.id_itinerario,
					});
				}

				return (
					<td
						className={"noBorder " + timeNow}
						key={index}
						colSpan={length}
						style={style}
						onClick={onClick}
					>
						{flight.ruta}
					</td>
				);
			}

			return null;
		}

		return <td className={timeNow} key={index}></td>;
	});

	return (
		<>
			<Modal
				data={modalContent}
				open={open}
				handleClose={() => {
					setOpen(false);
				}}
				type={"itinerary"}
			/>
			{tds}
		</>
	);
}

const normalizarHora = () => {
	const actualTimeLocal = moment();
	const answer = Math.abs(parseInt(actualTimeLocal.format("Z")));
	return answer;
};

function Row({ indexed, color, label, type, nowHours }) {
	const [open, setOpen] = useState(false);
	const [modalContent, setModalContent] = useState({
		departureCodes: [],
		arrivalCodes: [],
	});

	// Se formatea el tiempo actual local y se convierte a UTC
	let actualTime = moment().utc();

	actualTime = actualTime.format();
	actualTime = getHours(actualTime) + normalizarHora();
	//console.log(actualTime + normalizarHora());

	const tds = hours.map((hour, index) => {
		// Se valida si se debe pintar la linea de tiempo actual
		const timeNow = isInsideTime(actualTime, index) ? "vl" : "";

		const flight = indexed.find((flight) => {
			const timing = flight[type];

			// if (timing && type === "real") {
			//     const finished = timing.arrival < nowHours;
			//     if (!finished) return false;
			// }

			const inside = isInside(timing, index);

			return inside;
		});

		if (flight && label !== "route") {
			const timing = flight[type];
			const start = index === timing.departure;
			if (start) {
				const length = timing.arrival - timing.departure;

				const style = { textAlign: "center", color: "#ffffff" };

				if (color) {
					style.background = color;
				}

				const atd =
					flight.real.d !== undefined && flight.real.d !== null
						? flight.real.d
						: null; // actual time departure
				const std =
					flight.programed.d !== undefined && flight.programed.d !== null
						? flight.programed.d
						: null; // scheduled time of departure

				// Conjunto de declaraciones para verificar si hay una demora en la salida del vuelo
				const programed = moment(std);
				const real = moment(atd);
				const diferencia = real.diff(programed, "minutes");
				const demora = diferencia > 20;

				// verifica la demora
				if (type === "real" && demora) {
					style.background = "#deac09";
				}

				// Se cambia el background dependiendo si se esta ejecutando en el momento

				// if (type === "programed" && label === "number") {
				//     const green = isInside(timing, nowHours);
				//     if (green) {
				//         style.background = "green";
				//     }
				// }

				function onClick() {
					setOpen(true);
					setModalContent({
						crew: flight.crew
							? {
									captain: flight.crew.captain
										? flight.crew.captain
										: "Pending...",
									copilot: flight.crew.copilot
										? flight.crew.copilot
										: "Pending...",
									engineer: flight.crew.engineer
										? flight.crew.engineer
										: "Pending...",
									technician: flight.crew.technician
										? flight.crew.technician
										: "Pending...",
							  }
							: null,
						departure: formatTime(timing.d),
						arrival: formatTime(timing.a),
						operacion: flight.operacion,
						cliente: flight.cliente,
						posicion: flight.posicion,
						cw: flight.cw,
						id: flight.id,
						number: flight.number,
						departureCodes: flight.departureCodes,
						arrivalCodes: flight.arrivalCodes,
						observaciones: flight.observaciones,
						departureMessage: flight.departureMessage,
					});
				}

				return (
					<td
						className={"noBorder " + timeNow}
						key={index}
						colSpan={length}
						style={style}
						onClick={onClick}
					>
						{`${flight["route"]} ` +
							(flight[label] ? `(${flight[label]})` : "")}
					</td>
				);
			}

			return null;
		}

		return <td className={timeNow} key={index}></td>;
	});

	return (
		<>
			<Modal
				data={modalContent}
				open={open}
				handleClose={() => {
					setOpen(false);
				}}
				type={type}
			/>
			{tds}
		</>
	);
}

// 21 de febrero
function Plane({ airplane, now }) {
	const itinerarys = airplane.itinerary.map((itinerario) => {
		const begin = setHours(itinerario);
		return { ...itinerario, begin };
	});

	const indexed = airplane.flights.map((flight) => {
		const programed = setHours(flight.programed);
		const real = setHours(flight.real);
		return { ...flight, programed, real };
	});

	const manto = airplane.maintenance.map((mantenimiento) => {
		let entry = getHours(mantenimiento.entry);
		entry = { entry, en: mantenimiento.entry };
		let exit = getHours(mantenimiento.exit);
		exit = { exit, ex: mantenimiento.exit };
		return { ...mantenimiento, entry, exit };
	});

	const routes = (
		<Row indexed={indexed} label="route" type="programed" nowHours={now} />
	);

	const itinerario = <RowItinerary indexed={itinerarys} nowHours={now} />;

	const programeds = (
		<Row
			indexed={indexed}
			color="#0e84e6"
			label="number"
			type="programed"
			nowHours={now}
		/>
	);
	const reals = (
		<Row
			indexed={indexed}
			color="green"
			label="number"
			type="real"
			nowHours={now}
		/>
	);

	const maintenance = <RowMantenimiento indexed={manto} nowHours={now} />;

	return (
		<>
			<tr className="noBorder">
				<td
					rowSpan="5"
					style={{
						left: 0,
						position: "sticky",
						background: "#edf2f6",
						outline: "1px solid",
					}}
				>
					{airplane.matricula}
				</td>
				{routes}
			</tr>
			<tr className="test-default">{itinerario}</tr>
			<tr className="test-default">{programeds}</tr>
			<tr className="test-default">{reals}</tr>
			<tr className="test-default">{maintenance}</tr>
		</>
	);
}

// programado morado
// real ? verde : rojo
// amarillo cuando se esta ejecutando en utc

const Itinerario = () => {
	const hours = getHours(moment());
	const [now, setNow] = useState(hours);
	const [startDate, buscar] = useState({
		fecha: "",
	});

	// Renderice cada 1 minuto
	useEffect(() => {
		const interval = setInterval(() => {
			const time = moment();
			const hours = getHours(time);
			setNow(hours);
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	const [tmp, setTmp] = useState([]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		buscar((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	useEffect(() => {
		const dia = new Date();
		const formated = moment(dia).format("yyyy-MM-DD");

		http
			.get(`/laswatch/${formated}`)
			.then((response) => {
				setTmp(response.data);
			})
			.catch((error) => {
				console.log(error.message);
			});
	}, []);

	const search = () => {
		const fecha = moment(startDate.fecha).format("yyyy-MM-DD");
		startDay = moment(startDate.fecha);

		http
			.get(`/laswatch/${fecha}`)
			.then((response) => {
				midnight = new Date(`${fecha} 00:00:00`);
				midnightMoment = moment(midnight);
				day1 = moment(midnightMoment).add(1, "d");
				day2 = moment(midnightMoment).add(2, "d");
				day3 = moment(midnightMoment).add(3, "d");
				day4 = moment(midnightMoment).add(4, "d");
				day5 = moment(midnightMoment).add(5, "d");
				day6 = moment(midnightMoment).add(6, "d");
				days = [midnightMoment, day1, day2, day3, day4, day5, day6];
				dias = days.map((day) => day.format("ddd D/M"));
				setTmp(response.data);
				console.log(tmp);
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	const headersDays = dias.map((day, index) => (
		<th
			key={index}
			colSpan="24"
			className="text2"
			style={{
				textAlign: "center",
				position: "sticky",
				top: 75,
				background: "#edf2f6",
				zIndex: 250,
				border: 0,
				outline: "1px solid",
			}}
		>
			{day}
		</th>
	));

	const headersHoras = horas.map((hora, index) => (
		<th
			key={index}
			style={{
				position: "sticky",
				top: 114,
				background: "#edf2f6",
				zIndex: 250,
				border: 0,
				outline: "1px solid",
			}}
		>
			{hora.toString()}
		</th>
	));

	const headersHorasLocal = horasLocal.map((hora, index) => (
		<th
			key={index}
			style={{
				position: "sticky",
				top: 170,
				background: "#edf2f6",
				zIndex: 250,
				border: 0,
				outline: "1px solid",
			}}
		>
			{hora.toString()}
		</th>
	));

	const seven = (
		<>
			{headersHoras}
			{headersHoras}
			{headersHoras}
			{headersHoras}
			{headersHoras}
			{headersHoras}
			{headersHoras}
		</>
	);

	const sevenLocal = (
		<>
			{headersHorasLocal}
			{headersHorasLocal}
			{headersHorasLocal}
			{headersHorasLocal}
			{headersHorasLocal}
			{headersHorasLocal}
			{headersHorasLocal}
		</>
	);

	const planes = tmp.map((airplane) => {
		return <Plane airplane={airplane} key={airplane.matricula} now={now} />;
	});

	const absolute = {
		position: "fixed",
		background: "#003783",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		right: 0,
		height: 75,
		zIndex: 1000,
	};

	const cerrarSesion = () => {
		window.localStorage.clear();
	};

	return (
		<>
			<div style={absolute}>
				<p style={{ color: "#fff", fontSize: 55, marginLeft: "200px" }}>
					LAS Watch UTC
				</p>
				<a
					href="/"
					onClick={cerrarSesion}
					style={{ marginLeft: "200px", color: "#fff" }}
				>
					Sign out
				</a>
			</div>

			<div style={{ height: 75 }}></div>
			<div className="campo-form" style={{ marginBottom: "6px" }}>
				<div>
					<h4 style={{ marginLeft: "20px" }}>Pick a Date:</h4>
				</div>
				<input
					type="date"
					name="fecha"
					style={{ marginLeft: "20px" }}
					value={startDate.fecha}
					onChange={handleChange}
				/>

				<input
					type="button"
					className="btn btn-primario"
					value="Search"
					style={{ marginLeft: "20px" }}
					onClick={search}
				/>

				<div
					style={{
						marginLeft: "20px",
						width: "600px",
						display: "flex",
						textAlign: "center",
						color: "#fff",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							backgroundColor: "#0e84e6",
							width: "110px",
							marginRight: "20px",
							textAlign: "center",
						}}
					>
						Programed
					</div>
					<div
						style={{
							backgroundColor: "green",
							width: "100px",
							marginRight: "20px",
						}}
					>
						Actual
					</div>
					<div
						style={{
							backgroundColor: "#deac09",
							width: "100px",
							marginRight: "20px",
						}}
					>
						Actual with delay
					</div>
					<div style={{ background: "#003783", marginRight: "20px" }}>
						Maintenance Scheduled
					</div>
					<div style={{ background: "red", marginRight: "20px" }}>
						Maintenance Not Scheduled
					</div>
					<div style={{ background: "#eb9834", marginRight: "20px" }}>
						Monthly Itinerary{" "}
					</div>
					<div style={{ background: "#8c34eb" }}>Annual Itinerary </div>
				</div>
			</div>

			<table border="1">
				<thead>
					<tr>
						<th
							rowSpan="3"
							style={{
								position: "sticky",
								left: 0,
								top: 75,
								background: "#edf2f6",
								zIndex: 500,
								border: 0,
								outline: "1px solid",
							}}
						>
							Aircraft
						</th>
						{headersDays}
					</tr>

					<tr>{seven}</tr>
					<tr>{sevenLocal}</tr>
				</thead>
				<tbody>{planes}</tbody>
			</table>
		</>
	);
};

export default Itinerario;
