import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

// El minimo tamaño que tendra la tabla
const useStyles = makeStyles({
	table: {
		minWidth: 650,
		fontSize: "200pt",
	},
});

const ListaItinerario = ({ listaItinerario }) => {
	const classes = useStyles();

	return (
		<Fragment>
			<Table className={classes.table} id="vuelos">
				<TableHead>
					<TableRow>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							#
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Date
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Operation
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Customer
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Origin
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Destination
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Route
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Aircraft
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Tail
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							STD
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							STA
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Antiquity
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Remarks
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}></TableCell>
					</TableRow>
				</TableHead>
				{listaItinerario ? (
					<TableBody>
						{listaItinerario.map((itinerario) => (
							<TableRow key={itinerario.id_itinerario}>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.id_itinerario}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.fecha.split("T")[0]}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.cod_operacion}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.cod_cliente}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.ciudad_origen}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.ciudad_destino}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.ruta}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.cod_equipo_programado}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.nombre_matricula_avion}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.departure_prog}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.arrival_prog}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.titulo_antiguedad}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									{itinerario.observaciones}
								</TableCell>
								<TableCell align="center" style={{ fontSize: "14px" }}>
									<Button
										variant="contained"
										component={Link}
										color="inherit"
										style={{ width: 75 }}
										to={{
											pathname: "completarItinerario",
											itinerario,
										}}
									>
										Modify
									</Button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				) : null}
			</Table>
		</Fragment>
	);
};

export default ListaItinerario;
