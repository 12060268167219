import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

import Sidebar from "./layout/Sidebar";
import Barra from "./layout/Barra";
import ListarVuelo from "./vuelo/ListarVuelo";

const Main = () => {
	const local = JSON.parse(window.localStorage.getItem("datos"));

	const { rol } = local;

	const redirect = rol === 7;

	const availability = rol === 12;

	const mantenimento = rol === 9;

	const gestion = rol === 10;

	const itinerarioComercial = rol === 11;

	return (
		<Fragment>
			{redirect === true ? (
				<Redirect to={"/itinerario"} />
			) : availability ? (
				<Redirect to={"/availability"} />
			) : mantenimento === true ? (
				<Redirect to={"/mantenimiento"} />
			) : gestion === true ? (
				<Redirect to={"/gestion"} />
			) : itinerarioComercial === true ? (
				<Redirect to={"/itinerarioComercial"} />
			) : (
				<div className="contenedor-app">
					{rol === 5 || rol === 6 || rol === 2 || rol === 3 || rol === 1 ? (
						<Sidebar />
					) : null}

					<div className="seccion-principal">
						<Barra />

						<main>
							<div className="contenedor-tareas">
								<ListarVuelo />
							</div>
						</main>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Main;
