import React, { Fragment, useState, useEffect } from "react";
import http from "../utils/Http";
import Combobox from "../utils/Combobox";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Comercial = () => {
	// Se obtiene el rol del usuario
	const local = JSON.parse(window.localStorage.getItem("datos"));
	//console.log(local);

	const MySwal = withReactContent(Swal);

	const [vueloItinerado, setVueloItinerado] = useState([]);

	const [itinerado, setItinerado] = useState();

	const [vuelo, guardarVuelo] = useState({
		cod_operacion: 0,
		cod_estado: 6,
		cod_cliente: 0,
		cod_equipo_programado: 0,
		fecha: "",
		ciudad_origen: "",
		ciudad_destino: "",
		ruta: "",
		departure_prog: "",
		arrival_prog: "",
		peso_comercial: 0,
		observaciones: "",
		cod_posicion: "",
		cod_matricula_avion: "",
	});

	const [redirect, setRedirect] = useState(false);
	const {
		cod_operacion,
		cod_cliente,
		cod_equipo_programado,
		fecha,
		ciudad_origen,
		ciudad_destino,
		ruta,
		departure_prog,
		arrival_prog,
		cod_posicion,
		cod_matricula_avion,
	} = vuelo;

	const handleChange = (e) => {
		const { name, value } = e.target;

		guardarVuelo((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const guardarDatos = () => {
		//Validar que los campos requeridos esten llenos
		if (
			fecha === "" ||
			cod_operacion === "0" ||
			cod_cliente === "0" ||
			ciudad_origen === "" ||
			ciudad_destino === "" ||
			ruta === "" ||
			departure_prog === "" ||
			arrival_prog === "" ||
			cod_equipo_programado === "0" ||
			cod_posicion === "" ||
			cod_matricula_avion === "0"
		) {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: <p style={{ fontSize: "200%" }}>There are empty fields</p>,
				icon: "warning",
				confirmButtonText: "Try again",
			});
			return;
		}

		const data = { data: [vuelo, local, itinerado] };

		//Hacer el push a la base de datos
		http
			.post("/comercial", data)
			.then((response) => {
				if (response.data.insert) {
					guardarVuelo({
						fecha: "",
						cod_operacion: 0,
						cod_cliente: 0,
						ciudad_origen: "",
						ciudad_destino: "",
						ruta: "",
						departure_prog: "",
						arrival_prog: "",
						cod_equipo_programado: "",
						peso_comercial: 0,
						cod_estado: 0,
						observaciones: "",
						cod_posicion: "",
						cod_matricula_avion: 0,
					});
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Success</p>,
						html: <p style={{ fontSize: "200%" }}>Succesfully created</p>,
						icon: "success",
						confirmButtonText: "Continue",
					})
						.then(() => {
							setRedirect(true);
						})
						.catch(() => {
							console.log("IDK how this would be possible");
						});
				}
			})
			.catch((error) => {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Error</p>,
					html: <p style={{ fontSize: "200%" }}>Failed update</p>,
					icon: "error",
					confirmButtonText: "Try again",
				});
				console.log(error);
			});
		//Esconder los campos
	};

	const ancho = { width: "40%" };

	useEffect(() => {
		if (vuelo.fecha) {
			const loadData = async () => {
				const itinerarioData = await http.get("/listaItinerarioComercial", {
					params: { date: vuelo.fecha },
				});
				setVueloItinerado(itinerarioData.data);
			};

			loadData();
		}
	}, [vuelo.fecha]);

	const showItinerado = () => {
		return vueloItinerado.map((vuelo) => {
			return (
				<option
					value={vuelo.id_itinerario}
					key={vuelo.id_itinerario}
				>{`${vuelo.id_itinerario} ${vuelo.ruta}`}</option>
			);
		});
	};

	const cargar = (e) => {
		const itinerary = vueloItinerado.find(
			(vuelo) => vuelo.id_itinerario === e.target.value
		);
		if (itinerary) {
			guardarVuelo({
				cod_operacion: itinerary.cod_operacion,
				cod_estado: 6,
				cod_cliente: itinerary.cod_cliente,
				cod_equipo_programado: itinerary.cod_equipo_programado,
				fecha: vuelo.fecha,
				ciudad_origen: itinerary.ciudad_origen,
				ciudad_destino: itinerary.ciudad_destino,
				ruta: itinerary.ruta,
				departure_prog: itinerary.departure_prog,
				arrival_prog: itinerary.arrival_prog,
				peso_comercial: 0,
				observaciones: itinerary.observaciones,
				cod_posicion: "",
				cod_matricula_avion: itinerary.cod_matricula_avion,
			});

			setItinerado(itinerary.id_itinerario);
		} else {
			setItinerado(null);
		}
	};

	useEffect(() => {
		if (vuelo.cod_matricula_avion === "4") {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: (
					<p style={{ fontSize: "200%" }}>
						Asegurarse que la base tenga arrancador neumático por problemas de
						APU.
					</p>
				),
				icon: "warning",
				confirmButtonText: "Ok",
			});
		}
	}, [vuelo.cod_matricula_avion]);

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<div className="form-usuario">
				<div className="contenedor-form sombra-dark">
					<h2>New Flight</h2>

					<div className="campo-form">
						<label> Date: </label>
						<input
							type="date"
							name="fecha"
							onChange={handleChange}
							value={vuelo.fecha}
							style={ancho}
						/>
					</div>

					<div className="campo-form">
						<label> Itinerary </label>
						<select name="itinerado" onChange={cargar}>
							<option value={null} key={0}>
								select
							</option>
							{showItinerado()}
						</select>
					</div>

					<div className="campo-form">
						<label>Operation</label>
						<Combobox
							url="/operacion"
							oName="cod_operacion"
							property="operacion"
							value="id_operacion"
							handleChange={handleChange}
							actualValue={
								vuelo.cod_operacion === 0 ? "0" : parseInt(vuelo.cod_operacion)
							}
						/>
					</div>

					<div className="campo-form">
						<label> Customer</label>
						<Combobox
							url="/cliente"
							oName="cod_cliente"
							property="cliente"
							value="id_cliente"
							handleChange={handleChange}
							actualValue={
								vuelo.cod_operacion === 0 ? "0" : parseInt(vuelo.cod_cliente)
							}
						/>
					</div>

					<div className="campo-form">
						<label> Origin </label>
						<Combobox
							url="/ciudadO"
							oName="ciudad_origen"
							property="nombre_ciudad_origen"
							value="id_ciudad_origen"
							handleChange={handleChange}
							origen="origen"
							vuelo={vuelo}
							guardarVuelo={guardarVuelo}
							actualValue={
								vuelo.ciudad_origen === 0 ? "0" : parseInt(vuelo.ciudad_origen)
							}
						/>
					</div>

					<div className="campo-form">
						<label> Destination</label>
						<Combobox
							url="/ciudadD"
							oName="ciudad_destino"
							property="nombre_ciudad_destino"
							value="id_ciudad_destino"
							handleChange={handleChange}
							destino="destino"
							vuelo={vuelo}
							guardarVuelo={guardarVuelo}
							actualValue={
								vuelo.ciudad_destino === 0
									? "0"
									: parseInt(vuelo.ciudad_destino)
							}
						/>
					</div>

					<div className="campo-form">
						<label> Route </label>
						<input
							disabled
							type="text"
							name="ruta"
							value={vuelo.ruta}
							style={ancho}
						/>
					</div>

					<div className="campo-form">
						<label> Position </label>
						<Combobox
							url="/posicion"
							oName="cod_posicion"
							property="nombre_posicion"
							value="id_posicion"
							handleChange={handleChange}
						/>
					</div>

					<div className="campo-form">
						<label> STD </label>
						<input
							type="time"
							name="departure_prog"
							value={vuelo.departure_prog}
							onChange={handleChange}
							style={ancho}
						/>
						<label style={{ paddingLeft: 20 }}>UTC</label>
					</div>

					<div className="campo-form">
						<label> STA </label>
						<input
							type="time"
							name="arrival_prog"
							value={vuelo.arrival_prog}
							onChange={handleChange}
							style={ancho}
						/>
						<label style={{ paddingLeft: 20 }}>UTC</label>
					</div>

					<div className="campo-form">
						<label> Aircraft</label>
						<Combobox
							url="/equipo_programado"
							oName="cod_equipo_programado"
							property="nombre_equipo_programado"
							value="id_equipo_programado"
							handleChange={handleChange}
							actualValue={
								vuelo.cod_equipo_programado === 0
									? "0"
									: parseInt(vuelo.cod_equipo_programado)
							}
						/>
					</div>

					<div className="campo-form">
						<label> Tail </label>

						<Combobox
							url="/matricula_avion"
							oName="cod_matricula_avion"
							property="nombre_matricula_avion"
							value="id_matricula_avion"
							// params={vuelo.cod_equipo_programado}
							handleChange={handleChange}
							actualValue={
								vuelo.cod_matricula_avion === 0
									? "0"
									: parseInt(vuelo.cod_matricula_avion)
							}
						/>
					</div>

					<div className="campo-form">
						<label> Chargeable weight </label>
						<input
							type="number"
							name="peso_comercial"
							onChange={handleChange}
							value={vuelo.peso_comercial}
						/>
						&nbsp;
						<label>Kg</label>
					</div>

					<div className="campo-form">
						<input
							type="button"
							className="btn btn-primario btn-block"
							value="Save"
							onClick={guardarDatos}
						/>
					</div>

					<div className="campo-form">
						<input
							type="button"
							className="btn btn-primario btn-block"
							value="Cancel"
							onClick={() => setRedirect(true)}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Comercial;
