import React, { useState } from "react";
import http from "../utils/Http";
import { getItinerarysheet } from "../utils/Reports/itineraryReport";
import DateRange from "../utils/DateRange";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ReporteItinerario = () => {
	const [dateRange, setRange] = useState({
		fechaInicio: new Date().toISOString().split("T")[0],
		fechaFinal: new Date().toISOString().split("T")[0],
	});

	const [dataSet, setDataSet] = useState([]);
	// const [rawSheet, setRawSheet] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFileReady, setIsFileReady] = useState(false);

	const handleChange = (e) => {
		setRange({
			...dateRange,
			[e.target.name]: e.target.value,
		});
	};

	const search = async () => {
		try {
			setIsLoading(true);
			const tmp = await http.get("/listaItinerarioByDate", {
				params: {
					startDate: dateRange.fechaInicio,
					endDate: dateRange.fechaFinal,
				},
			});

			// const raw = await http.get("/reporte/rawTripulacion", {
			// 	params: {
			// 		startDate: dateRange.fechaInicio,
			// 		endDate: dateRange.fechaFinal,
			// 	},
			// });

			if (tmp.data.length === 0) {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Info</p>,
					html: (
						<p style={{ fontSize: "200%" }}>No such data for selected range</p>
					),
					icon: "info",
					confirmButtonText: "Try again",
				});
			} else {
				setDataSet(getItinerarysheet(tmp.data.flat()));
				// setRawSheet(getRawSheet(raw.data));
				setIsFileReady(true);
				setTimeout(() => {
					setIsFileReady(false);
				}, 1000);
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Error</p>,
				html: <p style={{ fontSize: "200%" }}>An error has occurred</p>,
				icon: "error",
				confirmButtonText: "Try again",
			});
			console.log(error);
		}
	};

	return (
		<div>
			<DateRange
				title="Itinerary report"
				datasets={[
					{ dataset: dataSet, sheetTitle: "Itinerary Report" },
					// { dataset: rawSheet, sheetTitle: "Raw" },
				]}
				loading={isLoading}
				fileReady={isFileReady}
				disable={isLoading || isFileReady}
				value={dateRange}
				handleChange={handleChange}
				search={search}
			/>
		</div>
	);
};

export default ReporteItinerario;
