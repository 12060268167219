/**
 * Describes styles and data for "reporte mayor" report, for its sheet "base"
 * Describe los estilos y los datos para el reporte mayor, para la hoja "base"
 */
import moment from "moment";
import "moment-duration-format";

const colors = {
	comercial: "f0d11c",
	programacion: "92d050",
	despacho: "9bc2e6",
	pf: "203764",
	"no tocar": "fb8605",
	"ops vuelo": "7030a0",
	manto: "ea4335",
};

const border = {
	top: {
		style: "thin",
	},
	bottom: {
		style: "thin",
	},
	left: {
		style: "thin",
	},
	right: {
		style: "thin",
	},
};

function getCellStyle(type) {
	return {
		font: {
			sz: "10",
		},
		border,
		numFmt: type,
	};
}

// Conservar la constante para no modificar tanto el archivo
const cellStyle = {
	font: {
		sz: "10",
	},
	border,
};

function getStyle(area) {
	return {
		font: {
			sz: "10",
			bold: true,
			color: {
				rgb: "ffffff",
			},
		},
		fill: {
			patternType: "solid",
			fgColor: {
				rgb: colors[area],
			},
		},
		border,
	};
}

const sumDelays = (data) => {
	if (data.length === 0) return "00:00";

	let answer = moment.duration(0);

	data.forEach((item) => {
		answer.add(moment.duration(item.delay_time), "minutes");
	});

	answer = answer.format("HH:mm");

	return answer.length === 2 ? `00:${answer}` : answer;
};

const sumTotal = (departure, arrival) => {
	const sum = moment
		.duration(departure)
		.add(moment.duration(arrival), "minutes")
		.format("HH:mm");

	return sum.length === 2 ? `00:${sum}` : sum;
};

function getBaseDataSet(data) {
	return [
		{
			columns: [
				{ title: "id_vuelo", width: { wpx: 85 }, style: getStyle("comercial") },
				{ title: "Fecha", width: { wpx: 85 }, style: getStyle("comercial") },
				{ title: "Dia", width: { wpx: 50 }, style: getStyle("no tocar") },
				{ title: "Mes", width: { wpx: 50 }, style: getStyle("no tocar") },
				{
					title: "Operación + Int/Nal",
					width: { wpx: 100 },
					style: getStyle("comercial"),
				},
				{
					title: "Codigo Cliente",
					width: { wpx: 60 },
					style: getStyle("comercial"),
				},
				{ title: "Cliente", width: { wpx: 60 }, style: getStyle("comercial") },
				{ title: "Origen", width: { wpx: 60 }, style: getStyle("comercial") },
				{ title: "Destino", width: { wpx: 60 }, style: getStyle("comercial") },
				{ title: "Ruta", width: { wpx: 80 }, style: getStyle("no tocar") },
				{
					title: "Departure Prog",
					width: { wpx: 85 },
					style: getStyle("comercial"),
				},
				{
					title: "Arrival Prog",
					width: { wpx: 85 },
					style: getStyle("comercial"),
				},
				{
					title: "Equipo programado x cliente",
					width: { wpx: 85 },
					style: getStyle("comercial"),
				},
				{
					title: "Peso comercial",
					width: { wpx: 85 },
					style: getStyle("comercial"),
				},
				{ title: "Estado", width: { wpx: 85 }, style: getStyle("comercial") },
				{
					title: "Observaciones",
					width: { wpx: 85 },
					style: getStyle("comercial"),
				},
				{ title: "HK", width: { wpx: 85 }, style: getStyle("programacion") },
				{
					title: "Consecutivo Hoja de vuelo",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "BH Block Hour",
					width: { wpx: 85 },
					style: getStyle("manto"),
				},
				{
					title: "Takeoff",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Landing",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "FH Flight Hour",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "T.O.W (LBS)",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Nivel de Vuelo",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Total Fuel (LBS)",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Fuel Burn (LBS)",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Fuel Rem (LBS)",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Fuel added (Gls)",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Piloto",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Copiloto",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Ing Vuelo",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Tecnico",
					width: { wpx: 85 },
					style: getStyle("programacion"),
				},
				{
					title: "Código salida 1",
					width: { wpx: 70 },
					style: getStyle("despacho"),
				},
				{
					title: "Tiempo salida 1",
					width: { wpx: 70 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Código salida 2",
					width: { wpx: 70 },
					style: getStyle("despacho"),
				},
				{
					title: "Tiempo salida 2",
					width: { wpx: 70 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Código salida 3",
					width: { wpx: 70 },
					style: getStyle("despacho"),
				},
				{
					title: "Tiempo salida 3",
					width: { wpx: 70 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Código llegada 1",
					width: { wpx: 70 },
					style: getStyle("despacho"),
				},
				{
					title: "Tiempo llegada 1",
					width: { wpx: 70 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Código llegada 2",
					width: { wpx: 70 },
					style: getStyle("despacho"),
				},
				{
					title: "Tiempo llegada 2",
					width: { wpx: 70 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Código llegada 3",
					width: { wpx: 70 },
					style: getStyle("despacho"),
				},
				{
					title: "Tiempo llegada 3",
					width: { wpx: 70 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Observaciones",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "N° de vuelo",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{ title: "Avion", width: { wpx: 85 }, style: getStyle("despacho") },
				{ title: "Pallets", width: { wpx: 85 }, style: getStyle("ops vuelo") },
				{
					title: "Landing Weight",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{ title: "CG% MAC", width: { wpx: 85 }, style: getStyle("despacho") },
				{
					title: "Departure Time",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Arrival Time",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Proveedor de combustible",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Payload (LBS/kg)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{ title: "# Ticket", width: { wpx: 85 }, style: getStyle("despacho") },
				{
					title: "Galones Tanqueados",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Basic Fuel (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Alternate Fuel (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Holding Fuel (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Contingency Fuel (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "XTR Fuel (LBS-KG)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Tankering Fuel",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Ballast Fuel",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "TAXI FUEL (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "Combustible taxeo real",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "ACTUAL RAMP FUEL (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "TAKEOFF FUEL (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "LANDING FUEL (LBS-KGS)",
					width: { wpx: 85 },
					style: getStyle("despacho"),
				},
				{
					title: "HORA DE REPORTE TRIP",
					width: { wpx: 85 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Precio x galon USD",
					width: { wpx: 85 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Valor Total de Combustible COP",
					width: { wpx: 85 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Landing fuel planing",
					width: { wpx: 85 },
					style: getStyle("ops vuelo"),
				},
				{
					title: "Operado Vs Planing",
					width: { wpx: 85 },
					style: getStyle("ops vuelo"),
				},
				{ title: "TRM (DIA)", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "Int/Nal", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "Horas Bloque", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "Horas Voladas", width: { wpx: 85 }, style: getStyle("pf") },
				{
					title: "PESO DESPACHO KG",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "CONTROL DE PESO KG",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{ title: "PESO OFRECIDO", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "KM RECORRIDOS", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "CICLOS", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "Fuel Added Gls", width: { wpx: 85 }, style: getStyle("pf") },
				{ title: "Fuel Burn Gls", width: { wpx: 85 }, style: getStyle("pf") },
				{
					title: "Costo de Combustible añadido",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "Fuel burn/HB Galones",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "Costo de combustible BH COP",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "Costo de combustible BH USD",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "Position",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "Ticket # 2",
					width: { wpx: 85 },
					style: getStyle("pf"),
				},
				{
					title: "OTP(On time Performance)",
					width: { wpx: 120 },
					style: getStyle("no tocar"),
				},
				{
					title: "Total Departure Delays",
					width: { wpx: 120 },
					style: getStyle("no tocar"),
				},
				{
					title: "Total Arrival Delays",
					width: { wpx: 120 },
					style: getStyle("no tocar"),
				},
				{
					title: "Total Delay",
					width: { wpx: 70 },
					style: getStyle("no tocar"),
				},
				{
					title: "Load Factor(%)",
					width: { wpx: 85 },
					style: getStyle("no tocar"),
				},
				{ title: "", width: { wpx: 85 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
			],
			data: data.map((data) => [
				{
					value: data.id_comercial ? data.id_comercial : "",
					style: getCellStyle(3),
				},
				{ value: data.fecha ? data.fecha.split("T")[0] : "", style: cellStyle },
				{ value: data.dia ? data.dia : "", style: getCellStyle(1) },
				{ value: data.mes ? data.mes : "", style: getCellStyle(1) },
				{
					value: data.codigo_operacion ? data.codigo_operacion : "",
					style: cellStyle,
				},
				{
					value: data.codigo_cliente ? data.codigo_cliente : "",
					style: cellStyle,
				},
				{ value: data.cliente ? data.cliente : "", style: cellStyle },
				{
					value: data.codigo_ciudad_origen ? data.codigo_ciudad_origen : "",
					style: cellStyle,
				},
				{
					value: data.codigo_ciudad_destino ? data.codigo_ciudad_destino : "",
					style: cellStyle,
				},
				{ value: data.ruta ? data.ruta : "", style: cellStyle },
				{
					value: data.departure_prog ? data.departure_prog : "",
					style: cellStyle,
				},
				{ value: data.arrival_prog ? data.arrival_prog : "", style: cellStyle },
				{
					value: data.nombre_equipo_programado
						? data.nombre_equipo_programado
						: "",
					style: cellStyle,
				},
				{
					value: data.peso_comercial ? data.peso_comercial : "",
					style: getCellStyle(3),
				},
				{
					value: data.nombre_estado ? data.nombre_estado : "",
					style: cellStyle,
				},
				{
					value: data.observaciones_combustible
						? data.observaciones_combustible
						: "",
					style: cellStyle,
				},
				{
					value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "",
					style: cellStyle,
				},
				{
					value: data.concecutivo_vuelo ? data.concecutivo_vuelo : "",
					style: cellStyle,
				},
				{ value: data.block_hour ? data.block_hour : "", style: cellStyle },
				{ value: data.takeoff ? data.takeoff : "", style: cellStyle },
				{ value: data.landing ? data.landing : "", style: cellStyle },
				{ value: data.flight_hour ? data.flight_hour : "", style: cellStyle },
				{ value: data.tow ? data.tow : "", style: getCellStyle(3) },
				{
					value: data.nivel_vuelo ? data.nivel_vuelo : "",
					style: getCellStyle(3),
				},
				{
					value: data.total_fuel ? data.total_fuel : "",
					style: getCellStyle(3),
				},
				{ value: data.fuel_burn ? data.fuel_burn : "", style: getCellStyle(3) },
				{ value: data.fuel_rem ? data.fuel_rem : "", style: getCellStyle(3) },
				{ value: data.fuel_add ? data.fuel_add : "", style: getCellStyle(3) },
				{
					value: data.nombre_capitan ? data.nombre_capitan : "",
					style: cellStyle,
				},
				{
					value: data.nombre_copiloto ? data.nombre_copiloto : "",
					style: cellStyle,
				},
				{
					value: data.nombre_ingeniero ? data.nombre_ingeniero : "",
					style: cellStyle,
				},
				{
					value: data.nombre_tecnico ? data.nombre_tecnico : "",
					style: cellStyle,
				},
				{
					value:
						data.departure_codes[0] !== undefined
							? data.departure_codes[0].codigo
							: "",
					style: cellStyle,
				},
				{
					value:
						data.departure_codes[0] !== undefined
							? data.departure_codes[0].delay_time
							: "",
					style: cellStyle,
				},
				{
					value:
						data.departure_codes[1] !== undefined
							? data.departure_codes[1].codigo
							: "",
					style: cellStyle,
				},
				{
					value:
						data.departure_codes[1] !== undefined
							? data.departure_codes[1].delay_time
							: "",
					style: cellStyle,
				},
				{
					value:
						data.departure_codes[2] !== undefined
							? data.departure_codes[2].codigo
							: "",
					style: cellStyle,
				},
				{
					value:
						data.departure_codes[2] !== undefined
							? data.departure_codes[2].delay_time
							: "",
					style: cellStyle,
				},
				{
					value:
						data.arrival_codes[0] !== undefined
							? data.arrival_codes[0].codigo
							: "",
					style: cellStyle,
				},
				{
					value:
						data.arrival_codes[0] !== undefined
							? data.arrival_codes[0].delay_time
							: "",
					style: cellStyle,
				},
				{
					value:
						data.arrival_codes[1] !== undefined
							? data.arrival_codes[1].codigo
							: "",
					style: cellStyle,
				},
				{
					value:
						data.arrival_codes[1] !== undefined
							? data.arrival_codes[1].delay_time
							: "",
					style: cellStyle,
				},
				{
					value:
						data.arrival_codes[2] !== undefined
							? data.arrival_codes[2].codigo
							: "",
					style: cellStyle,
				},
				{
					value:
						data.arrival_codes[2] !== undefined
							? data.arrival_codes[2].delay_time
							: "",
					style: cellStyle,
				},
				{
					value: data.observaciones_despacho ? data.observaciones_despacho : "",
					style: cellStyle,
				},
				{ value: data.numero_vuelo ? data.numero_vuelo : "", style: cellStyle },
				{
					value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "",
					style: cellStyle,
				},
				{ value: data.pallets ? data.pallets : "", style: getCellStyle(1) },
				{
					value: data.landing_weight ? data.landing_weight : "",
					style: getCellStyle(3),
				},
				{ value: data.cg ? data.cg : "", style: cellStyle },
				{
					value: data.departure_time ? data.departure_time : "",
					style: cellStyle,
				},
				{ value: data.arrival_time ? data.arrival_time : "", style: cellStyle },
				{
					value: data.nombre_provedor ? data.nombre_provedor : "",
					style: cellStyle,
				},
				{ value: data.payload ? data.payload : "", style: getCellStyle(3) },
				{
					value: data.ticket
						? data.nombre_estado.toLowerCase() === "operado"
							? data.ticket
							: ""
						: "",
					style: getCellStyle(3),
				},
				{ value: data.addfuel ? data.addfuel : "", style: getCellStyle(3) },
				{
					value: data.basic_fuel ? data.basic_fuel : "",
					style: getCellStyle(3),
				},
				{
					value: data.alternate_fuel ? data.alternate_fuel : "",
					style: getCellStyle(3),
				},
				{
					value: data.holding_fuel ? data.holding_fuel : "",
					style: getCellStyle(3),
				},
				{
					value: data.contingency ? data.contingency : "",
					style: getCellStyle(3),
				},
				{ value: data.xtr_fuel ? data.xtr_fuel : "", style: getCellStyle(3) },
				{
					value: data.tankering ? data.tankering : "",
					style: getCellStyle(2),
				},
				{
					value: data.ballast ? data.ballast : "",
					style: getCellStyle(2),
				},
				{ value: data.taxi_fuel ? data.taxi_fuel : "", style: getCellStyle(3) },
				{
					value: data.combustible_taxeo_real ? data.combustible_taxeo_real : "",
					style: getCellStyle(3),
				},
				{
					value: data.actual_ramp_fuel ? data.actual_ramp_fuel : "",
					style: getCellStyle(3),
				},
				{
					value: data.takeoff_fuel ? data.takeoff_fuel : "",
					style: getCellStyle(3),
				},
				{
					value: data.landing_fuel ? data.landing_fuel : "",
					style: getCellStyle(3),
				},
				{
					value: data.hora_reporte_trip ? data.hora_reporte_trip : "",
					style: cellStyle,
				},
				{
					value: data.precio_galon_facturado ? data.precio_galon_facturado : "",
					style: cellStyle,
				},
				{
					value: data.valor_total_combustible_cop
						? data.valor_total_combustible_cop
						: "",
					style: getCellStyle(8),
				},
				{
					value: data.landing_fuel_planing ? data.landing_fuel_planing : "",
					style: getCellStyle(3),
				},
				{
					value: data.operado_vs_planing ? data.operado_vs_planing : "",
					style: getCellStyle(3),
				},
				{ value: data.trm_dia ? data.trm_dia : "", style: getCellStyle(8) },
				{ value: data.tipo ? data.tipo : "", style: cellStyle },
				{
					value: data.horas_bloque ? data.horas_bloque : "",
					style: getCellStyle(2),
				},
				{
					value: data.horas_voladas ? data.horas_voladas : "",
					style: getCellStyle(2),
				},
				{
					value: data.peso_despacho ? data.peso_despacho : "",
					style: getCellStyle(3),
				},
				{
					value: data.control_peso_kg ? data.control_peso_kg : "",
					style: getCellStyle(3),
				},
				{
					value: data.peso_ofrecido ? data.peso_ofrecido : "",
					style: getCellStyle(3),
				},
				{ value: data.distancia ? data.distancia : "", style: cellStyle },
				{ value: data.ciclos ? data.ciclos : "", style: cellStyle },
				{
					value: data.fuel_added_gls ? data.fuel_added_gls : "",
					style: getCellStyle(2),
				},
				{
					value: data.fuel_burn_gls ? data.fuel_burn_gls : "",
					style: getCellStyle(2),
				},
				{
					value: data.costo_combustible_anadido
						? data.costo_combustible_anadido
						: "",
					style: getCellStyle(8),
				},
				{
					value: data.fuel_burn_hb_gals ? data.fuel_burn_hb_gals : "",
					style: cellStyle,
				},
				{
					value: data.costo_combustible_bh_cop
						? data.costo_combustible_bh_cop
						: "",
					style: getCellStyle(8),
				},
				{
					value: data.costo_combustible_bh_usd
						? data.costo_combustible_bh_usd
						: "",
					style: getCellStyle(8),
				},
				{
					value: data.posicion ? data.posicion : "",
					style: getCellStyle(8),
				},
				{
					value: data.ticket2 ? data.ticket2 : "",
					style: getCellStyle(1),
				},
				{
					value: data.nombre_estado === "Operado" ? parseInt(data.otp) : "",
					style: getCellStyle(1),
				},
				{
					value:
						data.nombre_estado === "Operado"
							? sumDelays(data.departure_codes)
							: "",
					style: getCellStyle(1),
				},
				{
					value:
						data.nombre_estado === "Operado"
							? sumDelays(data.arrival_codes)
							: "",
					style: getCellStyle(1),
				},
				{
					value:
						data.nombre_estado === "Operado"
							? sumTotal(
									sumDelays(data.departure_codes),
									sumDelays(data.arrival_codes)
							  )
							: "",
					style: getCellStyle(1),
				},
				{
					value: data.nombre_estado === "Operado" ? data.loadFactor : "",
					style: getCellStyle(1),
				},
				{ value: "" },
			]),
		},
	];
}

export default getBaseDataSet;

// export default function getBaseDataSet(data) {
//     return [
//         {
//             columns: [
//                 { title: "id_vuelo", width: { wpx: 85 } },
//             ],
//             data: data.map(
//                 (data) => [
//                     { value: 123321.456654, style: { numFmt: "d/mm/yyyy" } },
//                 ]
//             )
//         }
//     ];
// }
