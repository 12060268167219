/**
 * Describes styles and data for "reporte combustible" report
 */

const colors = {
    comercial: "f0d11c",
    programacion: "92d050",
    despacho: "9bc2e6",
    pf: "203764",
    "no tocar": "fb8605",
    "ops vuelo": "7030a0",
    manto: "ea4335"
}

const border = {
    top: {
        style: "thin"
    },
    bottom: {
        style: "thin"
    },
    left: {
        style: "thin"
    },
    right: {
        style: "thin"
    }
}

const cellStyle = {
    font: {
        sz: "10",
    },
    border
};

function getStyle(area) {
    return {
        font: {
            sz: "10",
            bold: true,
            color: {
                rgb: "ffffff"
            }
        },
        fill: {
            patternType: "solid",
            fgColor: {
                rgb: colors[area]
            }
        },
        border
    };
}

// Using DS for brevety which stands for DataSet
const CombustibleReportDS = (data) => {
    return [
        {
            columns: [
                { title: "Fecha", width: { wpx: 85 }, style: getStyle("comercial") },
                { title: "HK", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Origen", width: { wpx: 60 }, style: getStyle("comercial") },
                { title: "Destino", width: { wpx: 60 }, style: getStyle("comercial") },
                { title: "Takeoff", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Landing", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Departure Prog", width: { wpx: 85 }, style: getStyle("comercial") },
                { title: "Arrival Prog", width: { wpx: 85 }, style: getStyle("comercial") },
                { title: "T.O.W (LBS)", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Landing Weight", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "Basic Fuel (LBS-KGS)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "Alternate Fuel (LBS-KGS)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "Holding Fuel (LBS-KGS)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "Contingency Fuel (LBS-KGS)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "XTR Fuel (LBS-KG)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "ACTUAL RAMP FUEL (LBS-KGS)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "Fuel Burn (LBS)", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Total Fuel (LBS)", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Payload (LBS/kg)", width: { wpx: 85 }, style: getStyle("despacho") },
                { title: "Fuel added (Gls)", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "Precio x galon USD", width: { wpx: 85 }, style: getStyle("ops vuelo") },
                { title: "FH Flight Hour", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "BH Block Hour", width: { wpx: 85 }, style: getStyle("manto") },
                { title: "Fuel Rem (LBS)", width: { wpx: 85 }, style: getStyle("programacion") },
                { title: "", width: { wpx: 85 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
            ],
            data: data.map(
                (data) => [
                    { value: data.fecha ? data.fecha.split("T")[0] : "", style: cellStyle },
                    { value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "", style: cellStyle },
                    { value: data.codigo_ciudad_origen ? data.codigo_ciudad_origen : "", style: cellStyle },
                    { value: data.codigo_ciudad_destino ? data.codigo_ciudad_destino : "", style: cellStyle },
                    { value: data.takeoff ? data.takeoff : "", style: cellStyle },
                    { value: data.landing ? data.landing : "", style: cellStyle },
                    { value: data.departure_time ? data.departure_time : "", style: cellStyle },
                    { value: data.arrival_time ? data.arrival_time : "", style: cellStyle },
                    { value: data.tow ? data.tow : "", style: cellStyle },
                    { value: data.landing_weight ? data.landing_weight : "", style: cellStyle },
                    { value: data.basic_fuel ? data.basic_fuel : "", style: cellStyle },
                    { value: data.alternate_fuel ? data.alternate_fuel : "", style: cellStyle },
                    { value: data.holding_fuel ? data.holding_fuel : "", style: cellStyle },
                    { value: data.contingency ? data.contingency : "", style: cellStyle },
                    { value: data.xtr_fuel ? data.xtr_fuel : "", style: cellStyle },
                    { value: data.actual_ramp_fuel ? data.actual_ramp_fuel : "", style: cellStyle },
                    { value: data.fuel_burn ? data.fuel_burn : "", style: cellStyle },
                    { value: data.total_fuel ? data.total_fuel : "", style: cellStyle },
                    { value: data.payload ? data.payload : "", style: cellStyle },
                    { value: data.fuel_added_gls ? data.fuel_added_gls : "", style: cellStyle },
                    { value: data.precio_galon_facturado ? data.precio_galon_facturado : "", style: cellStyle },
                    { value: data.flight_hour ? data.flight_hour : "", style: cellStyle },
                    { value: data.block_hour ? data.block_hour : "", style: cellStyle },
                    { value: data.fuel_rem ? data.fuel_rem : "", style: cellStyle },
                    { value: "" }
                ]
            )
        }
    ];
}

export default CombustibleReportDS;