/**
 * Funciones de utilidad para aplicar estilos a las celdas de excel.
 *
 * Para los formatos de celda
 * Retorna el estilo con un formato de la celda de excel (type), formatos aplicables a la propiedad numFmt
 * 0 -> General
 * 1 -> Numero
 * 2 -> Numero con separador de miles y dos decimales
 * 3 -> Numero con separador de miles sin decimales
 * 4 -> Unknown
 * 5 -> Moneda con separador de miles
 * 6 -> Moneda con separador de miles
 * 7 -> Moneda con separador de miles y dos decimales
 * 8 -> Moneda con separador de miles y dos decimales
 * 9 -> porcentaje
 */

const colors = {
  azul: "1155cc",
  verde: "38761d",
  naranja: "fb8605",
  azulClaro: "9bc2e6",
  verdeClaro: "92d050",
  blanco: "ffffff",
};

function getFont(sz = 10, bold = false, rgb = "000000") {
  return {
    sz,
    bold,
    color: {
      rgb,
    },
  };
}

const getFill = (rgb = "ffffff") => {
  return {
    patternType: "solid",
    fgColor: {
      rgb,
    },
  };
};

const getBorder = (
  top = "thin",
  bottom = "thin",
  left = "thin",
  right = "thin"
) => {
  return {
    top: {
      style: top,
    },
    bottom: {
      style: bottom,
    },
    left: {
      style: left,
    },
    right: {
      style: right,
    },
  };
};

/**
 *
 * @param {function} font
 * @param {function} fill
 * @param {function} border
 * @param {function} numFmt
 * @returns if not arguments,a default cell style (letra negra 10px, fondo blanco solido, bordes delgados y con formato de celda general)
 */
function getCellStyle(
  font = getFont(),
  fill = undefined,
  border = getBorder(),
  numFmt = 0
) {
  return {
    font,
    fill,
    border,
    numFmt,
  };
}

export { colors, getFont, getFill, getBorder, getCellStyle };
