import React, { Fragment, useState, useEffect } from "react";
import Tabla from "../utils/Table";
import { Redirect } from "react-router-dom";
import Combobox from "../utils/Combobox";
import http from "../utils/Http";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CompletarComercial = ({ location }) => {
	const MySwal = withReactContent(Swal);

	const vuelo = location.vuelo;

	const local = JSON.parse(window.localStorage.getItem("datos"));

	const [redirect, setRedirect] = useState(false);

	const [completar, setCompletar] = useState({
		cod_operacion: 0,
		cod_estado: 1,
		cod_cliente: 0,
		cod_equipo_programado: 0,
		fecha: "",
		ciudad_origen: "",
		ciudad_destino: "",
		ruta: "",
		departure_prog: "",
		arrival_prog: "",
		peso_comercial: 0,
		observaciones: "",
		cod_posicion: "",
		cod_matricula_avion: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		setCompletar((prevState) => ({
			...prevState,
			[name]: value,
			id_comercial: vuelo.id_comercial,
		}));
	};

	const ancho = { width: "40%" };

	const onSubmit = (e) => {
		e.preventDefault();

		// verificar que todos los campos esten llenos
		if (completar.cod_estado === 0 || completar.cod_estado === "0") {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: (
					<p style={{ fontSize: "200%" }}>Flight state is required at least</p>
				),
				icon: "warning",
				confirmButtonText: "Try again",
			});
			return;
		}

		const data = { data: [completar, local] };

		http
			.put("/comercial", data)
			.then((response) => {
				if (response.data.insert) {
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Success</p>,
						html: <p style={{ fontSize: "200%" }}>Succesfully updated</p>,
						icon: "success",
						confirmButtonText: "Continue",
					})
						.then(() => {
							setRedirect(true);
						})
						.catch(() => {
							console.log("IDK how this would be possible");
						});
				} else {
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Time depleted to change the data.
								<br />
								If not, contact the administrator
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
				}
			})
			.catch((error) => {
				console.log(error);
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Error</p>,
					html: <p style={{ fontSize: "200%" }}>Failed update</p>,
					icon: "error",
					confirmButtonText: "Try again",
				});
			});
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				const result = await http.get(`/comercial/${vuelo.id_comercial}`);
				setCompletar(result.data[0]);
			} catch (error) {
				console.log({ error });
			}
		};

		loadData();
	}, [vuelo.id_comercial]);

	useEffect(() => {
		if (completar.cod_matricula_avion === "4") {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: (
					<p style={{ fontSize: "200%" }}>
						Asegurarse que la base tenga arrancador neumático por problemas de
						APU.
					</p>
				),
				icon: "warning",
				confirmButtonText: "Ok",
			});
		}
	}, [completar.cod_matricula_avion]);

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<div className="contenedor-app">
				<div className="seccion-principal">
					<h1>Commercial</h1>

					<Tabla vuelo={vuelo} />

					<div className="contenedor-tareas">
						<div
							className="form-usuario"
							style={{ maxHeight: "500px", overflow: "auto" }}
						>
							<div className="contenedor-form sombra-dark">
								<form onSubmit={onSubmit}>
									<div className="campo-form">
										<label> Date: </label>
										<input
											type="date"
											name="fecha"
											onChange={handleChange}
											value={completar.fecha.split("T")[0]}
											style={ancho}
										/>
									</div>

									<div className="campo-form">
										<label>Operation</label>
										<Combobox
											url="/operacion"
											oName="cod_operacion"
											property="operacion"
											value="id_operacion"
											handleChange={handleChange}
											actualValue={
												completar.cod_operacion === 0
													? "0"
													: parseInt(completar.cod_operacion)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Customer</label>
										<Combobox
											url="/cliente"
											oName="cod_cliente"
											property="cliente"
											value="id_cliente"
											handleChange={handleChange}
											actualValue={
												completar.cod_operacion === 0
													? "0"
													: parseInt(completar.cod_cliente)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Origin </label>
										<Combobox
											url="/ciudadO"
											oName="ciudad_origen"
											property="nombre_ciudad_origen"
											value="id_ciudad_origen"
											handleChange={handleChange}
											origen="origen"
											vuelo={completar}
											guardarVuelo={setCompletar}
											actualValue={
												completar.ciudad_origen === 0
													? "0"
													: parseInt(completar.ciudad_origen)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Destination</label>
										<Combobox
											url="/ciudadD"
											oName="ciudad_destino"
											property="nombre_ciudad_destino"
											value="id_ciudad_destino"
											handleChange={handleChange}
											destino="destino"
											vuelo={completar}
											guardarVuelo={setCompletar}
											actualValue={
												completar.ciudad_destino === 0
													? "0"
													: parseInt(completar.ciudad_destino)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Route </label>
										<input
											disabled
											type="text"
											name="ruta"
											value={completar.ruta}
											style={ancho}
										/>
									</div>

									<div className="campo-form">
										<label> Position </label>
										<Combobox
											url="/posicion"
											oName="cod_posicion"
											property="nombre_posicion"
											value="id_posicion"
											handleChange={handleChange}
											actualValue={
												completar.cod_posicion === 0
													? "0"
													: parseInt(completar.cod_posicion)
											}
										/>
									</div>

									<div className="campo-form">
										<label> STD </label>
										<input
											type="time"
											name="departure_prog"
											value={completar.departure_prog}
											onChange={handleChange}
											style={ancho}
										/>
										<label style={{ paddingLeft: 20 }}>UTC</label>
									</div>

									<div className="campo-form">
										<label> STA </label>
										<input
											type="time"
											name="arrival_prog"
											value={completar.arrival_prog}
											onChange={handleChange}
											style={ancho}
										/>
										<label style={{ paddingLeft: 20 }}>UTC</label>
									</div>

									<div className="campo-form">
										<label> Aircraft</label>
										<Combobox
											url="/equipo_programado"
											oName="cod_equipo_programado"
											property="nombre_equipo_programado"
											value="id_equipo_programado"
											handleChange={handleChange}
											actualValue={
												completar.cod_equipo_programado === 0
													? "0"
													: parseInt(completar.cod_equipo_programado)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Tail </label>

										<Combobox
											url="/matricula_avion"
											oName="cod_matricula_avion"
											property="nombre_matricula_avion"
											value="id_matricula_avion"
											handleChange={handleChange}
											actualValue={
												completar.cod_matricula_avion === 0
													? "0"
													: parseInt(completar.cod_matricula_avion)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Chargeable weight </label>
										<input
											type="number"
											name="peso_comercial"
											onChange={handleChange}
											value={completar.peso_comercial}
										/>
										&nbsp;
										<label>Kg</label>
									</div>

									<div className="campo-form">
										<label> State</label>
										<Combobox
											url="/estado"
											oName="cod_estado"
											property="nombre_estado"
											value="id_estado"
											handleChange={handleChange}
											actualValue={
												completar.cod_estado === 4
													? "4"
													: parseInt(completar.cod_estado)
											}
										/>
									</div>

									<div className="campo-form">
										<label> Remarks </label>
										<textarea
											name="observaciones"
											onChange={handleChange}
											value={completar.observaciones}
										></textarea>
									</div>

									<div className="campo-form">
										<input
											type="submit"
											className="btn btn-primario btn-block"
											value="Save"
										/>
									</div>
								</form>

								<div className="campo-form">
									<input
										type="button"
										className="btn btn-primario btn-block"
										value="Cancel"
										onClick={() => setRedirect(true)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CompletarComercial;
