import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "60%",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	border: "1px solid #000",
	borderRadius: 3,
};

const CustomModal = ({ data, open, handleClose, type }) => {
	return (
		<Modal open={open} onClose={handleClose}>
			<Box sx={style}>
				<Typography variant="h2" component="h1" sx={{ fontWeight: "bold" }}>
					{type === "availability" ? (
						<>Maintenance</>
					) : type === "maintenance" ? (
						<>Maintenance</>
					) : type === "programed" ? (
						<>Scheduled flight # {data.number}</>
					) : type === "itinerary" ? (
						<>Itinerary # {`${data.id_itinerario}`}</>
					) : (
						<>Flight # {data.number}</>
					)}
				</Typography>

				<Typography align="justify" sx={{ fontSize: "100%" }}>
					{type === "availability" ? (
						<Grid container spacing={3}>
							<Grid item xs={6}>
								Day in:{data.date_in}
							</Grid>
							<Grid item xs={6}>
								Day out: {data.date_out}
							</Grid>
							<Grid item xs={6}>
								Hour in: {data.hour_in}
							</Grid>
							<Grid item xs={6}>
								Hour Out: {data.hour_out}
							</Grid>
							<Grid item xs={6}>
								Type Maintenance: {data.tipo_mantenimiento}
							</Grid>
							<Grid item xs={6}>
								SubType: {data.nombre_subMantenimiento}
							</Grid>
							<Grid item xs={6}>
								{data.hk}
							</Grid>
							<Grid item xs={6}>
								Last Update:{data.update?.slice(0, 16)}
							</Grid>
							<Grid item xs={6}>
								Remark:{data.remarks}
							</Grid>
							<Grid item xs={6}>
								Id Mantenimiento: {data.id}
							</Grid>
						</Grid>
					) : type === "maintenance" ? (
						<Grid container spacing={3}>
							<Grid item xs={6}>
								Entry: {data.entry}
							</Grid>
							<Grid item xs={6}>
								Exit: {data.exit}
							</Grid>
							<Grid item xs={6}>
								Remarks: {data.remarks}
							</Grid>
							<Grid item xs={6}>
								Last Update: {data.update_date?.slice(0, 16)}
							</Grid>
						</Grid>
					) : type === "itinerary" ? (
						<>
							<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
								Route {data.ruta}
							</Typography>

							<Grid container spacing={3}>
								<Grid item xs={6}>
									Departure: {String(data.departure).replace("T", " ")}
								</Grid>
								<Grid item xs={6}>
									Arrival: {String(data.arrival).replace("T", " ")}
								</Grid>
							</Grid>
							<Divider></Divider>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
										Remarks
									</Typography>
									{data.observaciones}
								</Grid>
								<Grid item xs={6}>
									<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
										ID Comercial
									</Typography>
									{data.cod_comercial ? data.cod_comercial : "Pending..."}
								</Grid>
							</Grid>
						</>
					) : (
						<>
							<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
								Track # {data.id}
							</Typography>
							{type === "programed" ? (
								<Grid container spacing={3}>
									<Grid item xs={6}>
										STD: {data.departure}
									</Grid>
									<Grid item xs={6}>
										STA: {data.arrival}
									</Grid>
								</Grid>
							) : (
								<Grid container spacing={3}>
									<Grid item xs={6}>
										ATD: {data.departure}
									</Grid>
									<Grid item xs={6}>
										ATA: {data.arrival}
									</Grid>
								</Grid>
							)}

							<Divider></Divider>

							<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
								Crew
							</Typography>
							{data.crew ? (
								<Grid container spacing={3}>
									<Grid item xs={6}>
										Captain: {data.crew.captain}
									</Grid>
									<Grid item xs={6}>
										First Officer: {data.crew.copilot}
									</Grid>
									<Grid item xs={6}>
										Engineer: {data.crew.engineer}
									</Grid>
									<Grid item xs={6}>
										Technician: {data.crew.technician}
									</Grid>
								</Grid>
							) : null}

							<Divider></Divider>

							{type === "programed" ? (
								<>
									<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
										Information
									</Typography>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											Operation: {data.operacion}
										</Grid>
										<Grid item xs={6}>
											Customer: {data.cliente}
										</Grid>
										<Grid item xs={6}>
											Position: {data.posicion}
										</Grid>
										<Grid item xs={6}>
											Chargeable weight: {data.cw}
										</Grid>
									</Grid>
								</>
							) : data.departureCodes.length > 0 &&
							  data.arrivalCodes.length > 0 ? (
								<>
									<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
										Delay codes
									</Typography>
									<Grid container spacing={3}>
										<Grid item xs={6}>
											Departure codes
											{data.departureCodes.map((code) => {
												return (
													<Grid container spacing={3}>
														<Grid item xs={3}>
															{code.codigo}
														</Grid>
														<Grid item xs={3}>
															{code.delay_time}
														</Grid>
													</Grid>
												);
											})}
										</Grid>
										<Grid item xs={6}>
											Arrival codes
											{data.arrivalCodes.map((code) => {
												return (
													<Grid container spacing={3}>
														<Grid item xs={3}>
															{code.codigo}
														</Grid>
														<Grid item xs={3}>
															{code.delay_time}
														</Grid>
													</Grid>
												);
											})}
										</Grid>
									</Grid>
									<Divider></Divider>
								</>
							) : null}
							{type === "real" ? (
								<>
									<Typography sx={{ fontSize: "150%", fontWeight: "bold" }}>
										MVT
									</Typography>
									<div
										dangerouslySetInnerHTML={{
											__html: data.departureMessage
												? createMessage(
														data.departureMessage,
														data.observaciones
												  )
												: "Pending message...",
										}}
									/>
								</>
							) : null}
						</>
					)}
				</Typography>

				<input
					type="button"
					className="btn btn-primario btn-block"
					value="Close"
					onClick={handleClose}
				/>
			</Box>
		</Modal>
	);
};

const createMessage = (message, remarks) => {
	const messageTmp = remarks ? `<p>Desc: ${remarks}</p>` : "";

	return message + messageTmp;
};

export default CustomModal;
