import React, { useState, useEffect } from "react";
import Tabla from "../utils/Table";
import http from "../utils/Http";
import { Redirect } from "react-router-dom";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const DespachoCuenta = ({ location }) => {
    const MySwal = withReactContent(Swal);
    const vuelo = location.vuelo;
    const [redirect, setRedirect] = useState(false);
    const [value, setValue] = useState({
        cod_comercial: "",
        ticket: "",
        addfuel: ""
    });


    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await http.get(
                    `/despachoraw/${vuelo.id_comercial}`
                );
                if (data.data.length > 0) {
                    setValue(data.data[0]);
                } else {
                    MySwal.fire({
                        title: <p style={{ fontSize: '200%' }}>Info</p>,
                        html: <p style={{ fontSize: '200%' }}>No such information for this flight</p>,
                        icon: 'info'
                    });
                }
            } catch (error) {
                console.log(error);
            }
        };
        loadData();
    }, [vuelo.id_comercial,MySwal]);

    // const saveData = async () => {
    //     if (value.valor_factura === "") {
    //         alert("Debes ingresar un valor");
    //         return;
    //     }

    //     try {
    //         const save = await http.put("/despacho", value);
    //         if (save.statusText === "OK") {
    //             alert("Se ingreso la data correctamente");
    //             setRedirect(true);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    return (
        <>
            {redirect ? <Redirect to={"/main"} /> : ""}
            <h1>Dispached</h1>

            <Tabla vuelo={vuelo} />

            <div className="contenedor-tareas">
                <div className="form-usuario7">
                    <div className="contenedor-form sombra-dark">
                        <div className="campo-form">
                            <label>Ticket Number</label>
                            <input
                                type="text"
                                disabled
                                value={value.ticket}
                            />
                        </div>

                        <div className="campo-form">
                            <label>Tankering Fuel</label>
                            <input
                                type="text"
                                disabled
                                value={value.addfuel}
                            />
                        </div>

                        <div className="campo-form">
                            <input
                                type="button"
                                className="btn btn-primario btn-block"
                                value="Back"
                                onClick={() => setRedirect(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DespachoCuenta;
