/**
 * Describe el estilo y los datos del reporte de la tripulación de los vuelos
 * para la hoja tripulación
 */

import moment from "moment";
import "moment-duration-format";

let sumBloque = "00:00";
let sumVolado = "00:00";

const colors = {
	azul: "1155cc",
	verde: "38761d",
	naranja: "fb8605",
};

const border = {
	top: {
		style: "thin",
	},
	bottom: {
		style: "thin",
	},
	left: {
		style: "thin",
	},
	right: {
		style: "thin",
	},
};

const cellStyle = {
	font: {
		sz: "10",
	},
	border,
};

// const estilo = {
// 	font: {
// 		sz: "16",
// 	},
// 	alignment: {
// 		wrapText: true,
// 		vertical: "center",
// 		horizontal: "center",
// 	},
// };

function getStyle(color) {
	return {
		font: {
			sz: "10",
			bold: true,
			color: {
				rgb: "ffffff",
			},
		},
		fill: {
			patternType: "solid",
			fgColor: {
				rgb: colors[color],
			},
		},
		border,
	};
}

function addTime(time) {
	const firstTime = moment.duration(time);
	const secondTime = moment.duration(sumBloque);

	sumBloque = firstTime.add(secondTime);

	if (sumBloque._data.hours === 0) {
		sumBloque = `00:${sumBloque.format("hh:mm")}`;
		return sumBloque;
	}

	sumBloque = sumBloque.format("hh:mm");

	return sumBloque;
}

function addTime2(time) {
	const firstTime = moment.duration(time);
	const secondTime = moment.duration(sumVolado);

	sumVolado = firstTime.add(secondTime);

	if (sumVolado._data.hours === 0) {
		sumVolado = `00:${sumVolado.format("hh:mm")}`;
		return sumVolado;
	}

	sumVolado = sumVolado.format("hh:mm");

	return sumVolado;
}

function excelBitacora(data) {
	return [
		{
			columns: [
				{ title: "Matricula", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Fecha", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Route", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "No Log", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Bloque", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Volado", width: { wpx: 100 }, style: getStyle("azul") },
				{
					title: "Día",
					width: { wpx: 100 },
					style: getStyle("azul"),
				},
				{
					title: "Noche",
					width: { wpx: 100 },
					style: getStyle("azul"),
				},
				{ title: "IFR", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "VFR", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Total", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Ciclos", width: { wpx: 100 }, style: getStyle("azul") },
				{
					title: "Tiempo Bloque",
					width: { wpx: 100 },
					style: getStyle("azul"),
				},
				{ title: "Nac", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Int", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "Total", width: { wpx: 100 }, style: getStyle("azul") },
				{ title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
			],
			data: data.map((data, index) => [
				{
					value: data.tail ? data.tail : "",
					style: cellStyle,
				},
				{
					value: data.fecha ? data.fecha.split("T")[0] : "",
					style: cellStyle,
				},
				{
					value: data.ruta ? data.ruta : "",
					style: cellStyle,
				},
				{
					value: data.logPage ? data.logPage : "",
					style: cellStyle,
				},
				{
					value: data.horas.horaBloque ? data.horas.horaBloque : "",
					style: cellStyle,
				},
				{
					value: data.flight_hour ? data.flight_hour : "",
					style: cellStyle,
				},
				{
					value: data.horas.horaDia ? data.horas.horaDia : "",
					style: cellStyle,
				},
				{
					value: data.horas.horaNocturna ? data.horas.horaNocturna : "",
					style: cellStyle,
				},
				{
					value: "00:00",
					style: cellStyle,
				},
				{ value: "00:00", style: cellStyle },
				{
					value: data.horas.horaBloque ? addTime(data.horas.horaBloque) : "",
					style: cellStyle,
				},
				{ value: index + 1, style: cellStyle },
				{
					value: data.flight_hour ? addTime2(data.flight_hour) : "",
					style: cellStyle,
				},

				{
					value: data.tipoVuelo
						? data.tipoVuelo === "Internacional"
							? 1
							: 0
						: "",
					style: cellStyle,
				},
				{
					value: data.tipoVuelo
						? data.tipoVuelo === "Internacional"
							? 0
							: 1
						: "",
					style: cellStyle,
				},
				{
					value: index + 1,
					style: cellStyle,
				},
				{ value: "" },
			]),
		},
	];
}

export default excelBitacora;
