import React from "react";

const VerPcp = ({ equipoProgramado, programacion }) => { //I need vuelo, Programacion

    return (
        <>
            <div className="campo-form">
                <label>Captain:</label>
                <input
                    type="text"
                    disabled
                    value={programacion.nombre_capitan}
                />
            </div>

            {
                programacion.cod_capitan_observador ?
                    <div className="campo-form">
                        <label>Captain observer:</label>
                        <input
                            type="text"
                            disabled
                            value={programacion.cod_capitan_observador}
                        />
                    </div>
                    :
                    null
            }

            <div className="campo-form">
                <label>Copilot:</label>
                <input
                    type="text"
                    disabled
                    value={programacion.nombre_copiloto}
                />
            </div>

            {
                programacion.cod_copiloto_observador ?
                    <div className="campo-form">
                        <label>Copilot observer:</label>
                        <input
                            type="text"
                            disabled
                            value={programacion.cod_copiloto_observador}
                        />
                    </div>
                    :
                    null
            }

            {
                equipoProgramado.includes("727") ?
                    <>
                        <div className="campo-form">
                            <label>Engineer:</label>
                            <input
                                type="text"
                                disabled
                                value={
                                    programacion.nombre_ingeniero ?
                                        programacion.nombre_ingeniero
                                        : "Pending..."
                                }
                            />
                        </div>

                        {
                            programacion.cod_ingeniero_observador ?
                                <div className="campo-form">
                                    <label>Ingeneer observer:</label>
                                    <input
                                        type="text"
                                        disabled
                                        value={programacion.cod_ingeniero_observador}
                                    />
                                </div>
                                :
                                null
                        }


                    </>
                    :
                    null
            }

            <div className="campo-form">
                <label>Technitian:</label>
                <input
                    type="text"
                    disabled
                    value={
                        programacion.nombre_tecnico
                            ? programacion.nombre_tecnico
                            : "Pending..."
                    }
                />
            </div>

            {
                programacion.cod_tecnico_observador ?
                    <div className="campo-form">
                        <label>Technitian observer:</label>
                        <input
                            type="text"
                            disabled
                            value={programacion.cod_tecnico_observador}
                        />
                    </div>
                    :
                    null
            }


            {/*let there be blood*/}

            <div className="campo-form">
                <label>Log Page</label>
                <input
                    type="text"
                    name="concecutivo_vuelo"
                    min="0"
                    disabled
                    value={
                        programacion.concecutivo_vuelo ?
                            programacion.concecutivo_vuelo
                            :
                            "Pending..."
                    }
                />
            </div>

            <div className="campo-form">
                <label>TakeOff</label>
                <input
                    type="text"
                    name="takeoff"
                    value={
                        programacion.takeoff ?
                            programacion.takeoff
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>

            <div className="campo-form">
                <label>Landing</label>
                <input
                    type="text"
                    name="landing"
                    value={
                        programacion.landing ?
                            programacion.landing
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>


            <div className="campo-form">
                <label>TOW</label>
                <input
                    type="text"
                    name="tow"
                    value={
                        programacion.tow ?
                            programacion.tow
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>

            <div className="campo-form">
                <label>Flight level</label>
                <input
                    type="text"
                    name="nivel_vuelo"
                    value={
                        programacion.nivel_vuelo ?
                            programacion.nivel_vuelo
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>

            <div className="campo-form">
                <label>Total Fuel</label>
                <input
                    type="text"
                    name="total_fuel"
                    value={
                        programacion.total_fuel ?
                            programacion.total_fuel
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>

            <div className="campo-form">
                <label>Fuel Burned</label>
                <input
                    type="text"
                    name="fuel_burn"
                    value={
                        programacion.fuel_burn
                            ?
                            programacion.fuel_burn
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>

            <div className="campo-form">
                <label>Fuel added</label>
                <input
                    type="text"
                    name="fuel_add"
                    value={
                        programacion.fuel_add ?
                            programacion.fuel_add
                            :
                            "Pending..."
                    }
                    disabled
                />
            </div>
        </>
    )
};

export default VerPcp;