import { getCellStyle, colors, getFont, getFill } from "../cellstyle.util";
import moment from "moment";
import "moment-duration-format";

const getItinerarysheet = (data) => {
	return [
		{
			columns: styleItineraryHeaders(),
			data: styleItineraryData(data),
		},
	];
};

const horaPorcentaje = (hour) => {
	if (!hour) return null;

	const hourDuration = moment.duration(hour);

	return hourDuration._milliseconds / 3600000;
};

const horasBloque = (begin, end) => {
	const durationEnd = moment.duration(end);

	//Añadir un día si el arrival es al día siguiente
	if (end < begin) {
		durationEnd.add(1, "d");
	}

	const difference = durationEnd.subtract(begin);

	if (difference._data.hours === 0) {
		return `00:${difference.format("hh:mm")}`;
	}

	return difference.format("hh:mm");
};

const styleItineraryHeaders = () => {
	const headerStyle = getCellStyle(
		getFont(undefined, true, undefined),
		getFill(colors["azulClaro"])
	);
	return [
		{ title: "ID Itinerario", width: { wpx: 100 }, style: headerStyle },
		{ title: "ID Comercial", width: { wpx: 100 }, style: headerStyle },
		{ title: "Date", width: { wpx: 100 }, style: headerStyle },
		{ title: "Operation", width: { wpx: 100 }, style: headerStyle },
		{ title: "Customer", width: { wpx: 100 }, style: headerStyle },
		{ title: "Origin", width: { wpx: 100 }, style: headerStyle },
		{ title: "Destination", width: { wpx: 100 }, style: headerStyle },
		{ title: "Route", width: { wpx: 100 }, style: headerStyle },
		{ title: "Aircarft", width: { wpx: 100 }, style: headerStyle },
		{ title: "Tail", width: { wpx: 100 }, style: headerStyle },
		{ title: "STD", width: { wpx: 100 }, style: headerStyle },
		{ title: "STA", width: { wpx: 100 }, style: headerStyle },
		{ title: "Antiquity", width: { wpx: 100 }, style: headerStyle },
		{ title: "Remarks", width: { wpx: 100 }, style: headerStyle },
		{ title: "Estado", width: { wpx: 100 }, style: headerStyle },
		{ title: "HB Basica", width: { wpx: 100 }, style: headerStyle },
		{ title: "HB Real", width: { wpx: 100 }, style: headerStyle },
		{ title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
	];
};

const styleItineraryData = (data) => {
	//const numberStyle = getCellStyle(undefined, undefined, undefined, 1);
	return data.map((data) => [
		{
			value: data.id_itinerario ? data.id_itinerario : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_comercial ? data.cod_comercial : "",
			style: getCellStyle(),
		},
		{
			value: data.fecha ? data.fecha.split("T")[0] : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_operacion ? data.cod_operacion : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_cliente ? data.cod_cliente : "",
			style: getCellStyle(),
		},
		{
			value: data.ciudad_origen ? data.ciudad_origen : "",
			style: getCellStyle(),
		},
		{
			value: data.ciudad_destino ? data.ciudad_destino : "",
			style: getCellStyle(),
		},
		{
			value: data.ruta ? data.ruta : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_equipo_programado ? data.cod_equipo_programado : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "",
			style: getCellStyle(),
		},
		{
			value: data.departure_prog ? data.departure_prog : "",
			style: getCellStyle(),
		},
		{
			value: data.arrival_prog ? data.arrival_prog : "",
			style: getCellStyle(),
		},
		{
			value: data.titulo_antiguedad ? data.titulo_antiguedad : "",
			style: getCellStyle(),
		},
		{
			value: data.observaciones ? data.observaciones : "",
			style: getCellStyle(),
		},
		{
			value: data.estado ? data.estado : "",
			style: getCellStyle(),
		},
		{
			value: data.hbBasica ? data.hbBasica : "",
			style: getCellStyle(),
		},
		{
			value:
				data.realDeparture && data.realArrival
					? horaPorcentaje(horasBloque(data.realDeparture, data.realArrival))
					: "",
			style: getCellStyle(),
		},
		{ value: "" },
	]);
};

export { getItinerarysheet };
