import React, { Fragment, useState } from 'react';
import http from '../utils/Http';
import Tabla from '../utils/Table'
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Operaciones = ({ location }) => {
    const MySwal = withReactContent(Swal);

    const vuelo = location.vuelo;
    const [operacionesvuelo, setOperacionesVuelo] = useState({
        cod_comercial: "",
        tiempo_demora_salida: "",
        tiempo_demora_llegada: "",
        pallets: "",
        hora_reporte: "",
        precio_galon: ""
    });


    const handleChange = (e) => {
        const { name, value } = e.target;

        setOperacionesVuelo((prevState) => ({
            ...prevState,
            [name]: value,
            cod_comercial: vuelo.id_comercial
        }));
    };

    const [redirect, setRedirect] = useState(false);
    const { tiempo_demora_salida, tiempo_demora_llegada, pallets, hora_reporte, precio_galon } = operacionesvuelo;

    const guardar = (e) => {
        e.preventDefault();

        if (tiempo_demora_llegada === "" ||
            tiempo_demora_salida === "" ||
            pallets === "" ||
            hora_reporte === "" ||
            precio_galon === ""
        ) {
            MySwal.fire({
                title: <p style={{ fontSize: '200%' }}>Warning</p>,
                html: <p style={{ fontSize: '200%' }}>There are empty fields. Must verify</p>,
                icon: 'warning',
                confirmButtonText: 'Try again'
            });
        }

        // Despues de que pasen todas las validaciones
        http.post("/operacionesVuelo", operacionesvuelo)
            .then((response) => {
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Success</p>,
                    html: <p style={{ fontSize: '200%' }}>Succesfully updated</p>,
                    icon: 'success',
                    confirmButtonText: 'Continue'
                }).then(() => {
                    setRedirect(true);
                }).catch(() => {
                    console.log("IDK how this would be possible");
                });
            })
            .catch((error) => {
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Error</p>,
                    html: <p style={{ fontSize: '200%' }}>Failed update</p>,
                    icon: 'error',
                    confirmButtonText: 'Try again'
                });
                console.log(error);
            });
    }

    return (
        <Fragment>

            {redirect
                ? <Redirect to={'/main'} />
                : ""
            }

            <h1>Operaciones de vuelo</h1>

            <Tabla
                vuelo={vuelo}
            />

            <div className="form-usuario1" style={{ maxHeight: "500px", overflow: "auto" }}>
                <div className="contenedor-form sombra-dark">

                    <form onSubmit={guardar}>
                        <div className="campo-form">
                            <label>Tiempo demora salida</label>
                            <input
                                type="text"
                                className="input-text"
                                name="tiempo_demora_salida"
                                onChange={handleChange}
                                value={operacionesvuelo.tiempo_demora_salida}
                            />
                        </div>

                        <div className="campo-form">
                            <label>Tiempo demora Llegada</label>
                            <input
                                type="text"
                                className="input-text"
                                name="tiempo_demora_llegada"
                                onChange={handleChange}
                                value={operacionesvuelo.tiempo_demora_llegada}
                            />
                        </div>

                        <div className="campo-form">
                            <label>Pallets</label>
                            <input
                                type="number"
                                name="pallets"
                                onChange={handleChange}
                                value={operacionesvuelo.pallets}
                            />
                        </div>

                        <div className="campo-form">
                            <label>Hora reporte Trip</label>
                            <input
                                type="time"
                                className="input-text"
                                name="hora_reporte"
                                onChange={handleChange}
                                value={operacionesvuelo.hora_reporte}
                            />
                        </div>

                        <div className="campo-form">
                            <label>Precio Galon USD</label>
                            <input
                                type="number"
                                name="precio_galon"
                                onChange={handleChange}
                                value={operacionesvuelo.precio_galon}
                            />
                        </div>


                        <div className="campo-form">
                            <input
                                type="submit"
                                className="btn btn-primario btn-block"
                                value="Guardar"
                            />
                        </div>
                    </form>

                    <div className="campo-form">
                        <input
                            type="button"
                            className="btn btn-primario btn-block"
                            value="Cancelar"
                            onClick={() => setRedirect(true)}
                        />
                    </div>

                </div>


            </div>



        </Fragment>

    );
}

export default Operaciones
