import React, { Fragment } from "react";
import Combobox from ".././utils/Combobox";

const VerFlightOperations = ({ programacion }) => {
	return (
		<Fragment>
			<h2>Flight Operations</h2>

			<form>
				<div className="campo-form">
					<label>
						Captain <span className="required">*</span>
					</label>

					<Combobox
						url="/capitan"
						oName="cod_capitan"
						property="nombre_capitan"
						value="id_capitan"
						desabilitar={true}
						actualValue={
							programacion.cod_capitan === "" ? null : programacion.cod_capitan
						}
					/>
				</div>

				<div className="campo-form">
					<label>Captain Observer</label>

					<Combobox
						url="/capitan"
						oName="cod_capitan_observador"
						property="nombre_capitan"
						value="id_capitan"
						desabilitar={true}
						actualValue={
							programacion.cod_capitan_observador === ""
								? null
								: programacion.cod_capitan_observador
						}
					/>
				</div>

				<div className="campo-form">
					<label>
						First Officer <span className="required">*</span>
					</label>

					<Combobox
						url="/copiloto"
						oName="cod_copiloto"
						property="nombre_copiloto"
						value="id_copiloto"
						desabilitar={true}
						actualValue={
							programacion.cod_copiloto === ""
								? null
								: programacion.cod_copiloto
						}
					/>
				</div>

				<div className="campo-form">
					<label>First Officer Observer</label>

					<Combobox
						url="/copiloto"
						oName="cod_copiloto_observador"
						property="nombre_copiloto"
						value="id_copiloto"
						desabilitar={true}
						actualValue={
							programacion.cod_copiloto_observador === ""
								? null
								: programacion.cod_copiloto_observador
						}
					/>
				</div>

				{
					/*checkIngeneer()*/ true ? (
						<>
							<div className="campo-form">
								<label>
									Flight Engineer <span className="required">*</span>
								</label>

								<Combobox
									url="/ingeniero"
									oName="cod_ingeniero"
									property="nombre_ingeniero"
									value="id_ingeniero"
									desabilitar={true}
									actualValue={
										programacion.cod_ingeniero === ""
											? null
											: programacion.cod_ingeniero
									}
								/>
							</div>

							<div className="campo-form">
								<label>Flight Engineer Obsever</label>

								<Combobox
									url="/ingeniero"
									oName="cod_ingeniero_observador"
									property="nombre_ingeniero"
									value="id_ingeniero"
									desabilitar={true}
									actualValue={
										programacion.cod_ingeniero_observador === ""
											? null
											: programacion.cod_ingeniero_observador
									}
								/>
							</div>
						</>
					) : null
				}

				<div className="campo-form">
					<label>Technitian</label>

					<Combobox
						url="/tecnico"
						oName="cod_tecnico"
						property="nombre_tecnico"
						value="id_tecnico"
						desabilitar={true}
						actualValue={
							programacion.cod_tecnico === "" ? null : programacion.cod_tecnico
						}
					/>
				</div>

				<div className="campo-form">
					<label>Technitian Observer</label>

					<Combobox
						url="/tecnico"
						oName="cod_tecnico_observador"
						property="nombre_tecnico"
						value="id_tecnico"
						desabilitar={true}
						actualValue={
							programacion.cod_tecnico_observador === ""
								? null
								: programacion.cod_tecnico_observador
						}
					/>
				</div>

				<div className="campo-form">
					<label>Crew report hour</label>
					<input
						type="time"
						style={{ width: "40%" }}
						name="hora_reporte_trip"
						value={programacion.hora_reporte_trip}
						disabled
					/>
					<label style={{ marginLeft: 10 }}>UTC</label>
				</div>
			</form>
		</Fragment>
	);
};

export default VerFlightOperations;
