import React, { useState } from "react";
import http from "../utils/Http";
import CombustibleReportDS from "../utils/Reports/combustibleReport";
import DateRange from "../utils/DateRange";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ReporteCombustibleRange = () => {
  const [dateRange, setRange] = useState({
    fechaInicio: new Date().toISOString().split("T")[0],
    fechaFinal: new Date().toISOString().split("T")[0],
  });

  const [dataSet, setDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileReady, setIsFileReady] = useState(false);

  const handleChange = (e) => {
    setRange({
      ...dateRange,
      [e.target.name]: e.target.value,
    });
  };

  const search = async () => {
    try {
      setIsLoading(true);
      const tmp = await http.get("/combustible/no/report", {
        params: {
          fechaInicio: dateRange.fechaInicio,
          fechaFinal: dateRange.fechaFinal,
        },
      });

      if (tmp.data.length === 0) {
        MySwal.fire({
          title: <p style={{ fontSize: "200%" }}>Info</p>,
          html: (
            <p style={{ fontSize: "200%" }}>No such data for selected range</p>
          ),
          icon: "info",
          confirmButtonText: "Try again",
        });
      } else {
        setDataSet(CombustibleReportDS(tmp.data));
        setIsFileReady(true);
        setTimeout(() => {
          setIsFileReady(false);
        }, 1000);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      MySwal.fire({
        title: <p style={{ fontSize: "200%" }}>Error</p>,
        html: <p style={{ fontSize: "200%" }}>An error has occurred</p>,
        icon: "error",
        confirmButtonText: "Try again",
      });
      console.log(error);
    }
  };

  return (
    <div>
      <p
        style={{
          fontSize: "125%",
          fontFamily: "sans-serif",
          margin: 15,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Generate fuel report
      </p>

      <DateRange
        value={dateRange}
        handleChange={handleChange}
        disable={isLoading || isFileReady}
        title={"Fuel report"}
        datasets={[{ dataset: dataSet, sheetTitle: "Fuel" }]}
        fileReady={isFileReady}
        loading={isLoading}
        search={search}
      />
    </div>
  );
};

export default ReporteCombustibleRange;
