import React, { Fragment, useState, useEffect } from "react";
import Tabla from "../utils/Table";
import http from "../utils/Http";
import { Redirect } from "react-router-dom";

const VerCombustible = ({ location }) => {
    const vuelo = location.vuelo;
    const [redirect, setRedirect] = useState(false);
    const [combustible, setCombustible] = useState({
        cod_comercial: "",
        fecha: "",
        precio_galon_facturado: "",
        
    });

    useEffect(() => {
        const id = vuelo.id_comercial;
        http
            .get(`/combustible/${id}`)
            .then((response) => {
                if (response.data.length > 0) {
                    setCombustible(response.data[0]);
                    //console.log(response.data[0].moneda);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [vuelo.id_comercial]);

    const handleChange = (e) => {
        setCombustible({
            ...combustible,
            [e.target.name]: e.target.value,
            cod_comercial: vuelo.id_comercial,
        });
    };

    return (
        <Fragment>
            {redirect ? <Redirect to={"/main"} /> : ""}
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                <h1> Combustible</h1>
                <Tabla vuelo={vuelo} />
            </div>

            <div style={{ marginTop: 20 }}>
                <div className="contenedor-tareas">
                    <div className="form-usuario5">
                        <div className="contenedor-form sombra-dark">
                            <div className="campo-form">
                                <label> Fecha: </label>
                                <input
                                    type="date"
                                    name="fecha"
                                    onChange={handleChange}
                                    value={combustible.fecha?combustible.fecha.split("T")[0]:""}
                                    disabled
                                />
                            </div>

                          

                            <div className="campo-form">
                                <label> Precio X Galon facturado USD: </label>
                                <input
                                    type="number"
                                    name="precio_galon_facturado"
                                    onChange={handleChange}
                                    value={combustible.precio_galon_facturado}
                                    disabled
                                />
                            </div>

                          

                            <center>
                                <input
                                    type="button"
                                    className="btn btn-primario"
                                    value="Regresar"
                                    onClick={() => setRedirect(true)}
                                />
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default VerCombustible;
