import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
//import TextField from "@mui/material/TextField";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import SendIcon from "@mui/icons-material/Send";
import { Redirect } from "react-router-dom";
import Header from "../layout/Barra";
import http from "../utils/Http";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextField from "@mui/material/TextField";

const Movimiento = ({ location }) => {
	const MySwal = withReactContent(Swal);
	const vuelo = location.vuelo;

	const [typemessage, setTypeMessage] = useState({
		departure: false,
		arrival: false,
	});

	const [extra, setExtra] = useState({
		remain: "",
		time: "",
	});

	let limpiar = typemessage.departure || typemessage.arrival;

	//const [despacho.observaciones,setdespacho.observaciones] = useState("");

	const tipoAeronave =
		vuelo.cod_equipo_programado !== null
			? vuelo.cod_equipo_programado.includes("727")
			: false;

	const [codigodemora, setCodigoDemora] = useState(null);

	const [matriculas, setMatriculas] = useState([]);

	const [visible, setVisible] = useState(false);

	const [despacho, setDespacho] = useState({
		id_despacho: 0,
		cod_comercial: 0,
		cod_provedor_combustible: 3,
		cod_matricula_avion: 0,
		observaciones: "",
		numero_vuelo: "",
		landing_weight: 0,
		cg: 0,
		departure_time: "",
		arrival_time: "",
		payload: 0,
		ticket: 0,
		basic_fuel: 0,
		alternate_fuel: 0,
		holding_fuel: 0,
		contingency: 0,
		xtr_fuel: 0,
		taxi_fuel: 0,
		combustible_taxeo_real: 0,
		actual_ramp_fuel: 0,
		takeoff_fuel: 0,
		landing_fuel: 0,
		valor_factura: 0,
		pallets: 0,
		who_made_takeoff: "",
		who_made_landing: "",
		addfuel: 0,
		categoria_demora: 0,
		cod_iata_code_salida: 0,
		takeoff: "",
		eta: "",
		departureMessage: null,
		arrivalMessage: null,
		observacionesArrival: "",
	});

	const validarSend =
		despacho.numero_vuelo &&
		vuelo.fecha &&
		despacho.cod_matricula_avion &&
		vuelo.ciudad_origen &&
		despacho.departure_time &&
		despacho.takeoff &&
		despacho.eta &&
		vuelo.ciudad_destino &&
		codigodemora &&
		despacho.takeoff_fuel &&
		despacho.payload;

	const handleChange = (e) => {
		setTypeMessage({
			...typemessage,
			[e.target.name]: true,
		});
		setDespacho({
			...despacho,
			departureMessage: construirMensaje(),
			arrivalMessage: messageArrival(),
		});
		setTimeout(() => {
			setVisible(true);
		}, 1000);
	};

	const handleChangeRemak = (e) => {
		if (typemessage.arrival) {
			setDespacho({
				...despacho,
				observacionesArrival: e.target.value,
			});
		} else {
			setDespacho({
				...despacho,
				[e.target.name]: e.target.value,
			});
		}
	};

	const sendMessage = () => {
		const bases = {
			origen: vuelo.ciudad_origen,
			destino: vuelo.ciudad_destino,
		};

		// Llamar al end point para enviar los correos
		const msgTmp = typemessage.departure
			? construirMensaje()
			: messageArrival();
		const id = despacho.id_despacho;
		console.log(id);
		http
			.post("/despacho/movimiento", {
				msg: msgTmp,
				bases,
				id,
			})
			.then((response) => {
				if (response.data.send) {
					http
						.put("/despacho", despacho)
						.then((response) => {
							console.log("Se grabo en base");
						})
						.catch((error) => {
							console.log({ error });
						});

					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Success</p>,
						html: <p style={{ fontSize: "200%" }}>Message sent</p>,
						icon: "success",
						confirmButtonText: "Continue",
					})
						.then(() => {
							setRedirect(true);
						})
						.catch(() => {
							console.log("IDK how this would be possible");
						});
				}
			})
			.catch((error) => {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Error</p>,
					html: (
						<p style={{ fontSize: "200%" }}>
							Can't send message now. Try again later
						</p>
					),
					icon: "error",
					confirmButtonText: "Try again",
				});
				console.log({ error });
			});
	};

	useEffect(() => {
		const loadMatricula = async () => {
			try {
				const result = await http.get("/matricula_avion");
				setMatriculas(result.data[0]);
			} catch (error) {
				console.log(error);
			}
		};

		const loadData = async () => {
			try {
				const result = await http.get(`/despachoraw/${vuelo.id_comercial}`);
				if (result.data.length !== 0) {
					setDespacho(result.data[0]);
					loadDemora();
				}
			} catch (error) {
				console.log(error);
			}
		};

		const loadDemora = async () => {
			try {
				const demora = await http.get(
					`/comercial/${vuelo.id_comercial}/departure_delay_codes`
				);
				if (demora.data.length !== 0) {
					setCodigoDemora(demora.data);
				}
			} catch (error) {
				console.log(error);
			}
		};

		loadMatricula();
		loadData();
	}, [vuelo.id_comercial]);

	const cadenaDemora = () => {
		let demoras = "DL";
		codigodemora[0].forEach((demora) => {
			demoras +=
				demora.codigo !== undefined ? demora.codigo.split(" ")[0] + "/" : "";
		});

		codigodemora[0].forEach((demora) => {
			demoras += demora.delay_time.replace(":", "") + "/";
		});

		return demoras.substring(0, demoras.length - 1);
	};

	const [redirect, setRedirect] = useState(false);

	const findHk = (cod_matricula) => {
		const hk = matriculas.find(
			(matricula) => matricula.id_matricula_avion === cod_matricula
		);

		return hk !== undefined ? hk.nombre_matricula_avion : null;
	};

	const validarCancel = () => {
		if (typemessage.departure || typemessage.arrival) {
			setTypeMessage({
				departure: false,
				arrival: false,
			});
			setVisible(false);
		} else {
			// se deberia redirigir a la pagina anterior
			setRedirect(true);
		}
	};

	const construirMensaje = () => {
		const registration = `<p>${despacho.numero_vuelo.toUpperCase()}/${vuelo.fecha
			.split("T")[0]
			.substring(8)}.${
			despacho.cod_matricula_avion !== null
				? findHk(despacho.cod_matricula_avion)
				: "Pending..."
		}.${vuelo.ciudad_origen}</p>`;
		const ad = `<p>AD${
			despacho.departure_time !== null
				? despacho.departure_time.replace(":", "")
				: "Pending..."
		}/${
			despacho.takeoff !== null
				? despacho.takeoff.replace(":", "")
				: "Pending..."
		} EA${
			despacho.eta !== null ? despacho.eta.replace(":", "") : "Pending..."
		} ${vuelo.ciudad_destino}</p>`;
		const demora =
			codigodemora !== null && codigodemora[0].length > 0
				? `<p>${cadenaDemora()}</p>`
				: null;
		const tof = `<p>TOF${
			despacho.takeoff_fuel !== 0 ? despacho.takeoff_fuel : " Pending..."
		}</p>`;
		const si = `<p>SI PAYLOAD ${
			despacho.payload !== 0 ? despacho.payload : " Pending..."
		} ${tipoAeronave ? "LB" : "KG"}</p>`;
		const msg = `<p>MVT</p>
${registration}
${ad}
${demora ? demora : ""}
${tof}
${si + despacho.observaciones} `;

		return msg;
	};

	const messageArrival = () => {
		const message = `<p>MVT</p>
	<p>
		${despacho.numero_vuelo.toUpperCase()}
		${vuelo.fecha.split("T")[0].substring(8)}.
		${
			despacho.cod_matricula_avion !== null
				? findHk(despacho.cod_matricula_avion)
				: "Pending..."
		}
		.${vuelo.ciudad_destino}
	</p>
	<p>
		AA
		${
			despacho.arrival_time !== null
				? despacho.arrival_time.replace(":", "")
				: "Pending..."
		}
		${extra.time !== "" ? `/${extra.time.replace(":", "")}` : ""}
	</p>
	${codigodemora !== null ? `<p>${cadenaDemora()}</p>` : null}
	
		
		${extra.remain !== "" ? `<p>Remain: ${extra.remain} Kgs</p>` : ""}
	
	${
		despacho.observacionesArrival !== null &&
		despacho.observacionesArrival !== "" &&
		despacho.observacionesArrival !== undefined
			? `<p>Remarks:${despacho.observacionesArrival}</p>`
			: ""
	}`;

		return message;
	};

	return (
		<>
			{redirect ? <Redirect to={"/main"} /> : ""}
			<div>
				<header>
					<Header />
				</header>
				<main>
					<Typography variant="h2" gutterBottom>
						Send Message
					</Typography>

					<div className="container-mov">
						{/* Se muestra lo que se enviara por mensaje */}
						<div className="hijo-mov">
							<div style={{ margin: "20vh" }}>
								{typemessage.departure ? (
									<>
										<p>MVT</p>
										<p>
											{despacho.numero_vuelo.toUpperCase()}/
											{vuelo.fecha.split("T")[0].substring(8)}.
											{despacho.cod_matricula_avion !== null
												? findHk(despacho.cod_matricula_avion)
												: "Pending..."}
											.{vuelo.ciudad_origen}
										</p>
										<p>
											AD
											{despacho.departure_time !== null
												? despacho.departure_time.replace(":", "")
												: "Pending..."}
											/
											{despacho.takeoff !== null
												? despacho.takeoff.replace(":", "")
												: "Pending..."}{" "}
											EA
											{despacho.eta !== null
												? despacho.eta.replace(":", "")
												: "Pending..."}{" "}
											{vuelo.ciudad_destino}
										</p>
										{codigodemora !== null && codigodemora[0].length > 0 ? (
											<p>{cadenaDemora()}</p>
										) : null}
										<p>
											TOF
											{despacho.takeoff_fuel !== 0
												? despacho.takeoff_fuel
												: " Pending..."}
										</p>
										<p>
											SI PAYLOAD{" "}
											{despacho.payload !== 0
												? despacho.payload
												: " Pending..."}{" "}
											{tipoAeronave ? "LB" : "KG"}
										</p>
										<p>{despacho.observaciones}</p>
									</>
								) : null}

								{typemessage.arrival ? (
									<>
										{console.log(messageArrival())}
										<p>MVT</p>
										<p>
											{despacho.numero_vuelo.toUpperCase()}
											{vuelo.fecha.split("T")[0].substring(8)}.
											{despacho.cod_matricula_avion !== null
												? findHk(despacho.cod_matricula_avion)
												: "Pending..."}
											.{vuelo.ciudad_destino}
										</p>
										<p>
											AA
											{despacho.arrival_time !== null
												? despacho.arrival_time.replace(":", "")
												: "Pending..."}
											{extra.time !== "" ? `/${extra.time}` : ""}
										</p>
										{codigodemora !== null ? <p>{cadenaDemora()}</p> : null}
										<p>
											{" "}
											{extra.remain !== ""
												? `Remain: ${extra.remain} Kgs`
												: ""}{" "}
										</p>
										<p>Remarks</p>
									</>
								) : null}
							</div>
						</div>
						{/* Se posiciona cada uno de los botones */}
						<div className="hijo-mov">
							<Grid
								container
								justify="center"
								alignItems="center"
								direction="column"
								style={{ minHeight: "50vh" }}
							>
								<Grid item>
									<Grid
										container
										direction="column"
										alignItems="center"
										justifyContent="center"
									>
										{!visible ? (
											<>
												<Button
													variant="contained"
													name="departure"
													onClick={handleChange}
													endIcon={<FlightTakeoffIcon />}
													style={{
														width: 100,
														margin: "2em 0",
													}}
												>
													Departure
												</Button>
												<Button
													variant="contained"
													name="arrival"
													onClick={handleChange}
													endIcon={<FlightLandIcon />}
													style={{ width: 100 }}
												>
													Arrival
												</Button>
											</>
										) : null}

										{visible ? (
											<>
												<TextField
													id="outlined-multiline-flexible"
													label="Remarks"
													name="observaciones"
													InputLabelProps={{
														style: {
															fontSize: 18,
															fontWeight: "bold",
														},
													}}
													multiline
													fullWidth
													rows={4}
													onChange={handleChangeRemak}
													value={
														typemessage.departure
															? despacho.observaciones
															: despacho.observacionesArrival
													}
													InputProps={{
														style: {
															fontSize: 16,
														},
													}}
													style={{ width: "30vw", marginTop: 20 }}
												/>
												{typemessage.arrival ? (
													<div>
														<input
															type="number"
															name="remain"
															placeholder="Remain KG"
															onChange={(e) =>
																setExtra({
																	...extra,
																	[e.target.name]: e.target.value,
																})
															}
															value={extra.remain}
														/>
														<input
															type="text"
															name="time"
															placeholder="Time"
															onChange={(e) =>
																setExtra({
																	...extra,
																	[e.target.name]: e.target.value,
																})
															}
															value={extra.time}
														/>
													</div>
												) : null}

												<Button
													variant="contained"
													onClick={sendMessage}
													endIcon={<SendIcon />}
													disabled={!validarSend}
													style={{
														width: 100,
														marginTop: "2em",
													}}
												>
													Send
												</Button>
											</>
										) : null}
										<Button
											variant="contained"
											onClick={validarCancel}
											endIcon={<SendIcon />}
											style={{
												width: 100,
												marginTop: "2em",
											}}
										>
											{limpiar ? "Cancel" : "Back"}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</div>
					</div>
				</main>
			</div>
		</>
	);
};

export default Movimiento;

/*
<h1>Desde la pagina de movimiento</h1>
            <center><h3>takeOff </h3>
            <h3>estimated </h3></center>

            <h2>Departure</h2>
            <h2>arrival</h2>
*/
