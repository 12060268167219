/**
 * Describes styles and data for "reporte mayor" report, for its sheet 
 * "combustible"
 */

const colors = {
    azul: "1155cc",
    verde: "38761d",
    naranja: "fb8605"
}

const border = {
    top: {
        style: "thin"
    },
    bottom: {
        style: "thin"
    },
    left: {
        style: "thin"
    },
    right: {
        style: "thin"
    }
}

const cellStyle = {
    font: {
        sz: "10",
    },
    border
};

function getStyle(color) {
    return {
        font: {
            sz: "10",
            bold: true,
            color: {
                rgb: "ffffff"
            }
        },
        fill: {
            patternType: "solid",
            fgColor: {
                rgb: colors[color]
            }
        },
        border
    };
}

function getCombustibleDataSet(data) {
    return [
        {
            columns: [
                { title: "Fecha", width: { wpx: 85 }, style: getStyle("azul") },
                { title: "Mes", width: { wpx: 50 }, style: getStyle("azul") },
                { title: "Ruta", width: { wpx: 100 }, style: getStyle("azul") },
                { title: "Estado", width: { wpx: 60 }, style: getStyle("azul") },
                { title: "Avion", width: { wpx: 60 }, style: getStyle("azul") },
                { title: "Fuel Added Gls", width: { wpx: 60 }, style: getStyle("azul") },
                { title: "# Ticket", width: { wpx: 80 }, style: getStyle("azul") },
                { title: "Proveedor de combustible", width: { wpx: 85 }, style: getStyle("azul") },
                { title: "Precio x galon USD", width: { wpx: 85 }, style: getStyle("azul") },
                { title: "Precio x Gls COP", width: { wpx: 85 }, style: getStyle("azul") },
                { title: "Costo de combustible añadido", width: { wpx: 85 }, style: getStyle("azul") },
                { title: "Causado", width: { wpx: 85 }, style: getStyle("azul") },
                { title: "5", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Mes facturado", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Numero de ticket", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Numero de factura", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Galones facturados", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Precio x galones facturado", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Valor de la factura", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Moneda", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Valor en USD", width: { wpx: 85 }, style: getStyle("naranja") },
                { title: "Valor en COP", width: { wpx: 85 }, style: getStyle("naranja") },
                { title: "Moneda", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Valor de la factura", width: { wpx: 85 }, style: getStyle("verde") },
                { title: "Valor en USD", width: { wpx: 85 }, style: getStyle("naranja") },
                { title: "Valor en COP", width: { wpx: 85 }, style: getStyle("naranja") },
                { title: "TRM", width: { wpx: 85 }, style: getStyle("naranja") },
                { title: "", width: { wpx: 85 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
            ],
            data: data.map(
                (data) => [
                    { value: data.fecha ? data.fecha.split("T")[0] : "", style: cellStyle },
                    { value: data.mes ? data.mes : "", style: cellStyle },
                    { value: data.ruta ? data.ruta : "", style: cellStyle },
                    { value: data.nombre_estado ? data.nombre_estado : "", style: cellStyle },
                    { value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "", style: cellStyle },
                    { value: data.fuel_added_gls ? data.fuel_added_gls : "", style: cellStyle },
                    { value: data.ticket ? data.ticket : "", style: cellStyle },
                    { value: data.nombre_provedor ? data.nombre_provedor : "", style: cellStyle },
                    { value: data.precio_galon_facturado ? data.precio_galon_facturado : "", style: cellStyle },
                    { value: data.concecutivo_vuelo ? data.concecutivo_vuelo : "", style: cellStyle },
                    { value: data.costo_combustible_anadido ? data.costo_combustible_anadido : "", style: cellStyle },
                    //{ value: data.causado ? "VERDADERO" : (data.causado === 0 ? "FALSO" : ""), style: cellStyle },
                    { value: data.causado ? data.causado : "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: (typeof data.ticket === "string" || !data.ticket) ? "" : data.ticket, style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: "", style: cellStyle },
                    { value: data.trm_dia ? data.trm_dia : "", style: cellStyle },
                    { value: "" }
                ]
            )
        }
    ];
}

export default getCombustibleDataSet;