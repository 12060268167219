/**
 * El componente permite fijar el rango de fechas para obtener determinado reporte.
 */

import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

/**
 *
 * @param {object} value contine los valores para fechaInicio y fechaFin
 * @param {function} handleChange lo que ocurre cuando se cambia el valor del input
 * @param {boolean} disable Si el input está deshabilitado o no
 * @param {string} title El reporte que se va a generar
 * @param {Array} datasets El conjunto de datasets (hojas) que un reporte puede tener
 * @param {boolean} fileReady Si el archivo está descargandose
 * @param {boolean} loading Si la petición no ha retornado nada
 * @param {funtion} search Función que se ejecuta cuando se hace clic sobre el botón cuando tiene el texto de buscar
 * @returns
 */
const DateRange = ({
	value,
	handleChange,
	title,
	datasets,
	fileReady,
	loading,
	search,
	employee,
	nombre = "",
}) => {
	return (
		<>
			<p
				style={{
					fontSize: "125%",
					fontFamily: "sans-serif",
					margin: 15,
					fontWeight: "bold",
					textAlign: "center",
				}}
			>
				{title}
			</p>

			<label>Start date:</label>
			<input
				type="date"
				name="fechaInicio"
				disabled={loading || fileReady}
				onChange={handleChange}
				value={value.fechaInicio}
				style={{ display: "block", margin: "10px 0px" }}
			/>

			<label>Final date:</label>
			<input
				type="date"
				name="fechaFinal"
				min={value.fechaInicio}
				disabled={!value.fechaInicio || loading || fileReady}
				onChange={handleChange}
				value={value.fechaInicio ? value.fechaFinal : ""}
				style={{ display: "block", margin: "10px 0px" }}
			/>
			{employee ? <label style={{ marginRight: 40 }}>Crew:</label> : null}
			{employee ? (
				<input
					type="text"
					name="empleado"
					onChange={employee}
					style={{ marginTop: 10 }}
					placeholder="Name"
					required={true}
				/>
			) : null}

			{loading ? (
				<input
					type="button"
					disabled
					className="btn btn-primario btn-block"
					value="Searching . . ."
				/>
			) : fileReady ? (
				<>
					<input
						type="button"
						disabled
						className="btn btn-primario btn-block"
						value="Downloading"
					/>
					<ExcelFile
						hideElement
						filename={`${nombre.toUpperCase()} ${title} from ${
							value.fechaInicio
						} to ${value.fechaFinal}`}
					>
						{datasets.map((dataset, index) => {
							return (
								<ExcelSheet
									dataSet={dataset.dataset}
									name={dataset.sheetTitle}
									key={index}
								/>
							);
						})}
					</ExcelFile>
				</>
			) : (
				<input
					type="button"
					className="btn btn-primario btn-block"
					value="Search"
					onClick={search}
				/>
			)}
		</>
	);
};

export default DateRange;
