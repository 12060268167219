import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";

import http from "../utils/Http";
import { makeStyles } from "@material-ui/core/styles";

const Combobox = ({
    url,
    property,
    value,
    handleChange,
    oName,
    origen,
    destino,
    vuelo,
    guardarVuelo,
    actualValue,
    desabilitar,
    params,
    isIATACode
}) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 180,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();

    const [select, setSelect] = useState(0);

    const [operacion, setOperacion] = useState([]);



    useEffect(() => {
        http
            .get(url, {
                params: {
                    aircraftType: params
                }
            })
            .then((response) => {
                setOperacion(response.data[0]);
            })
            .catch((error) => {
                console.log("es del error de combobox");
                console.log(error);
            });

    }, [url, params]);

    useEffect(() => {
        if (actualValue) {
            setSelect(parseInt(actualValue));
        }
    }, [actualValue]);

    const obtenerSeleccionOrigen = (id) => {
        if (id) {
            const ciudad = operacion.filter(
                (item) => item.id_ciudad_origen === id
            )[0];
            const tmp = ciudad.codigo_ciudad_origen;
            return tmp;
        } else {
            return "";
        }
    };

    const obtenerSeleccionDestino = (id) => {
        if (id) {
            const ciudad = operacion.filter(
                (item) => item.id_ciudad_destino === id
            )[0];
            const ciudad_cod = ciudad.codigo_ciudad_destino;
            return ciudad_cod;
        } else {
            return "";
        }
    };

    const obtenerCodigoIATA = (id) => {
        if (id !== 0 && id !== '0') {
            const iata_code = operacion.filter(
                (item) => item.id_iata_code === id
            )[0];
            const codigo = iata_code.codigo;
            return { target: { name: "codigo", value: codigo } };
        } else {
            return "";
        }
    };

    const guardarCampo = (e) => {
        const id = e.target.value;

        setSelect(id);
        handleChange(e);

        if (origen) {
            const ori = obtenerSeleccionOrigen(id);
            const des = obtenerSeleccionOrigen(vuelo.ciudad_destino);
            const route = ori + (des ? "-" + des : "");

            guardarVuelo((prevState) => ({
                ...prevState,
                ruta: route,
            }));
        }
        if (destino) {
            const ori = obtenerSeleccionDestino(vuelo.ciudad_origen);
            const des = obtenerSeleccionDestino(id);
            const route = (ori ? ori + "-" : "") + des;

            guardarVuelo((prevState) => ({
                ...prevState,
                ruta: route,
            }));
        }
        if (isIATACode) {
            const iataCode = obtenerCodigoIATA(id);
            if (iataCode) {
                handleChange(iataCode);
            }
        }
    };

    return (
        <Fragment>
            <FormControl className={classes.formControl}>
                <Select
                    displayEmpty
                    name={oName}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    onChange={guardarCampo}
                    disabled={desabilitar}
                    style={{
                        fontSize: 18,

                    }}
                >
                    <MenuItem value="0">Select</MenuItem>
                    {operacion.length !== 0 ? (
                        operacion.map((item, index) => {
                            return (
                                <MenuItem
                                    key={index}
                                    value={item[value]}
                                    style={{ fontSize: 16 }}
                                >
                                    {item[property]}
                                </MenuItem>
                            );
                        })
                    ) : (
                        <MenuItem value={""}>{""}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </Fragment>
    );
};

export default Combobox;
