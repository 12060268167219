import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";

import http from "../utils/Http";
import Combobox from "../utils/Combobox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const CreateItinerario = () => {
	const local = JSON.parse(window.localStorage.getItem("datos"));
	//console.log(local);

	const MySwal = withReactContent(Swal);

	const [itinerario, setItinerario] = useState({
		fecha: "",
		cod_operacion: 0,
		cod_cliente: 0,
		ciudad_origen: "",
		ciudad_destino: "",
		ruta: "",
		cod_equipo_programado: 0,
		cod_matricula_avion: "",
		departure_prog: "",
		arrival_prog: "",
		cod_antiguedad: 0,
		observaciones: "",
	});

	const [redirect, setRedirect] = useState(false);
	const {
		cod_operacion,
		cod_cliente,
		cod_equipo_programado,
		fecha,
		ciudad_origen,
		ciudad_destino,
		ruta,
		departure_prog,
		arrival_prog,
		cod_matricula_avion,
	} = itinerario;

	const handleChange = (e) => {
		const { name, value } = e.target;

		setItinerario((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const guardarDatos = () => {
		//Validar que los campos requeridos esten llenos
		if (
			fecha === "" ||
			cod_operacion === "0" ||
			cod_cliente === "0" ||
			ciudad_origen === "" ||
			ciudad_destino === "" ||
			ruta === "" ||
			departure_prog === "" ||
			arrival_prog === "" ||
			cod_equipo_programado === "0" ||
			cod_matricula_avion === "0"
		) {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: <p style={{ fontSize: "200%" }}>There are empty fields</p>,
				icon: "warning",
				confirmButtonText: "Try again",
			});
			return;
		}

		const data = { data: [itinerario, local] };

		//Hacer el push a la base de datos
		http
			.post("/itinerario", data)
			.then((response) => {
				if (response.data.insert) {
					setItinerario({
						fecha: "",
						cod_operacion: 0,
						cod_cliente: 0,
						ciudad_origen: "",
						ciudad_destino: "",
						ruta: "",
						cod_equipo_programado: 0,
						cod_matricula_avion: "",
						departure_prog: "",
						arrival_prog: "",
						cod_antiguedad: 0,
						observaciones: "",
					});
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Success</p>,
						html: <p style={{ fontSize: "200%" }}>Succesfully created</p>,
						icon: "success",
						confirmButtonText: "Continue",
					})
						.then(() => {
							setRedirect(true);
						})
						.catch(() => {
							console.log("IDK how this would be possible");
						});
				}
			})
			.catch((error) => {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Error</p>,
					html: <p style={{ fontSize: "200%" }}>Failed update</p>,
					icon: "error",
					confirmButtonText: "Try again",
				});
				console.log(error);
			});
		//Esconder los campos
	};

	const ancho = { width: "40%" };

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<div className="form-usuario">
				<div className="contenedor-form sombra-dark">
					<h2>New Itinerary</h2>

					<div className="campo-form">
						<label> Date: </label>
						<input
							type="date"
							name="fecha"
							onChange={handleChange}
							value={itinerario.fecha}
							style={ancho}
						/>
					</div>

					<div className="campo-form">
						<label>Operation</label>
						<Combobox
							url="/operacion"
							oName="cod_operacion"
							property="operacion"
							value="id_operacion"
							handleChange={handleChange}
						/>
					</div>

					<div className="campo-form">
						<label> Customer</label>
						<Combobox
							url="/cliente"
							oName="cod_cliente"
							property="cliente"
							value="id_cliente"
							handleChange={handleChange}
						/>
					</div>

					<div className="campo-form">
						<label> Origin </label>
						<Combobox
							url="/ciudadO"
							oName="ciudad_origen"
							property="nombre_ciudad_origen"
							value="id_ciudad_origen"
							handleChange={handleChange}
							origen="origen"
							vuelo={itinerario}
							guardarVuelo={setItinerario}
						/>
					</div>

					<div className="campo-form">
						<label> Destination</label>
						<Combobox
							url="/ciudadD"
							oName="ciudad_destino"
							property="nombre_ciudad_destino"
							value="id_ciudad_destino"
							handleChange={handleChange}
							destino="destino"
							vuelo={itinerario}
							guardarVuelo={setItinerario}
						/>
					</div>

					<div className="campo-form">
						<label> Route </label>
						<input
							disabled
							type="text"
							name="ruta"
							value={itinerario.ruta}
							style={ancho}
						/>
					</div>

					<div className="campo-form">
						<label> Aircraft</label>
						<Combobox
							url="/equipo_programado"
							oName="cod_equipo_programado"
							property="nombre_equipo_programado"
							value="id_equipo_programado"
							handleChange={handleChange}
						/>
					</div>

					<div className="campo-form">
						<label> Tail </label>

						<Combobox
							url="/matricula_avion"
							oName="cod_matricula_avion"
							property="nombre_matricula_avion"
							value="id_matricula_avion"
							// params={itinerario.cod_equipo_programado}
							handleChange={handleChange}
							actualValue={
								itinerario.cod_matricula_avion === 0
									? "0"
									: parseInt(itinerario.cod_matricula_avion)
							}
						/>
					</div>

					<div className="campo-form">
						<label> STD </label>
						<input
							type="time"
							name="departure_prog"
							value={itinerario.departure_prog}
							onChange={handleChange}
							style={ancho}
						/>
						<label style={{ paddingLeft: 20 }}>UTC</label>
					</div>

					<div className="campo-form">
						<label> STA </label>
						<input
							type="time"
							name="arrival_prog"
							value={itinerario.arrival_prog}
							onChange={handleChange}
							style={ancho}
						/>
						<label style={{ paddingLeft: 20 }}>UTC</label>
					</div>

					<div className="campo-form">
						<label> Antiquity </label>
						<Combobox
							url="/antiguedad"
							oName="cod_antiguedad"
							property="titulo_antiguedad"
							value="id_antiguedad"
							handleChange={handleChange}
						/>
					</div>

					<div className="campo-form">
						<label> Remarks </label>
						<textarea
							name="observaciones"
							onChange={handleChange}
							value={itinerario.observaciones}
						></textarea>
					</div>

					<div className="campo-form">
						<input
							type="button"
							className="btn btn-primario btn-block"
							value="Save"
							onClick={guardarDatos}
						/>
					</div>

					<div className="campo-form">
						<input
							type="button"
							className="btn btn-primario btn-block"
							value="Cancel"
							onClick={() => setRedirect(true)}
						/>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CreateItinerario;
