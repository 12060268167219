import React, { useState, useEffect, Fragment } from "react";
import http from "../utils/Http";
import { Redirect } from "react-router-dom";

import Tabla from "../utils/Table";
import Combobox from "../utils/Combobox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const CompleteProgramacion = ({ location }) => {
	const vuelo = location.vuelo;

	const [redirect, setRedirect] = useState(false);
	const [programacion, setProgramacion] = useState({
		id_programacion: "",
		cod_comercial: 0,
		cod_capitan: "",
		cod_copiloto: "",
		cod_ingeniero: null,
		cod_tecnico: null,
		concecutivo_vuelo: 0,
		takeoff: "",
		landing: "",
		tow: null,
		nivel_vuelo: 0,
		total_fuel: 0,
		fuel_burn: 0,
		fuel_add: 0,
		cod_capitan_observador: null,
		cod_copiloto_observador: null,
		cod_ingeniero_observador: null,
		cod_tecnico_observador: null,
		hora_reporte_trip: "",
	});

	useEffect(() => {
		const loadData = async () => {
			http
				.get(`/programacionraw/${vuelo.id_comercial}`)
				.then((response) => {
					if (response.data.length > 0) {
						setProgramacion(response.data[0]);
					}
				})
				.catch((error) => {
					console.log("es del error desde lista");
					console.log(error);
				});
		};

		loadData();
	}, [vuelo.id_comercial]);

	const handleChange = (e) => {
		setProgramacion({
			...programacion,
			[e.target.name]: e.target.value,
			cod_comercial: vuelo.id_comercial,
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		http
			.put("/programacion", programacion)
			.then((response) => {
				if (response.data.insert) {
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Success</p>,
						html: <p style={{ fontSize: "200%" }}>Succesfully updated</p>,
						icon: "success",
						confirmButtonText: "Continue",
					})
						.then(() => {
							setRedirect(true);
						})
						.catch(() => {
							console.log("IDK how this would be possible");
						});
				}
			})
			.catch((error) => {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Error</p>,
					html: <p style={{ fontSize: "200%" }}>Failed update</p>,
					icon: "error",
					confirmButtonText: "Try again",
				});
				console.log(error);
			});
	};

	const checkIngeneer = () => {
		let check = false;
		if (vuelo) {
			if (vuelo.cod_equipo_programado.includes("727")) {
				check = true;
			}
		}
		return check;
	};

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<h1>PCP</h1>

			<Tabla vuelo={vuelo} />

			<div className="contenedor-tareas">
				<div className="form-usuario4">
					<div className="contenedor-form sombra-dark">
						<form onSubmit={onSubmit}>
							<div className="campo-form">
								<label>
									Captain <span className="required">*</span>
								</label>

								<Combobox
									url="/capitan"
									oName="cod_capitan"
									property="nombre_capitan"
									value="id_capitan"
									handleChange={handleChange}
									desabilitar={true}
									actualValue={
										programacion.cod_capitan === ""
											? null
											: programacion.cod_capitan
									}
								/>
							</div>

							{programacion.cod_capitan_observador === null ? null : (
								<div className="campo-form">
									<label>Captain Observer</label>

									<Combobox
										url="/capitan"
										oName="cod_capitan_observador"
										property="nombre_capitan"
										value="id_capitan"
										handleChange={handleChange}
										desabilitar={true}
										actualValue={
											programacion.cod_capitan_observador === ""
												? null
												: programacion.cod_capitan_observador
										}
									/>
								</div>
							)}

							<div className="campo-form">
								<label>
									First Officer <span className="required">*</span>
								</label>

								<Combobox
									url="/copiloto"
									oName="cod_copiloto"
									property="nombre_copiloto"
									value="id_copiloto"
									desabilitar={true}
									handleChange={handleChange}
									actualValue={
										programacion.cod_copiloto === ""
											? null
											: programacion.cod_copiloto
									}
								/>
							</div>

							{programacion.cod_copiloto_observador === null ? null : (
								<div className="campo-form">
									<label>First Officer Observer</label>

									<Combobox
										url="/copiloto"
										oName="cod_copiloto_observador"
										property="nombre_copiloto"
										value="id_copiloto"
										desabilitar={true}
										handleChange={handleChange}
										actualValue={
											programacion.cod_copiloto_observador === ""
												? null
												: programacion.cod_copiloto_observador
										}
									/>
								</div>
							)}

							{checkIngeneer() ? (
								<>
									<div className="campo-form">
										<label>
											Flight Engineer <span className="required">*</span>
										</label>

										<Combobox
											url="/ingeniero"
											oName="cod_ingeniero"
											property="nombre_ingeniero"
											value="id_ingeniero"
											desabilitar={true}
											handleChange={handleChange}
											actualValue={
												programacion.cod_ingeniero === ""
													? null
													: programacion.cod_ingeniero
											}
										/>
									</div>

									{programacion.cod_ingeniero_observador === null ? null : (
										<div className="campo-form">
											<label>Flight Engineer Obsever</label>

											<Combobox
												url="/ingeniero"
												oName="cod_ingeniero_observador"
												property="nombre_ingeniero"
												value="id_ingeniero"
												desabilitar={true}
												handleChange={handleChange}
												actualValue={
													programacion.cod_ingeniero_observador === ""
														? null
														: programacion.cod_ingeniero_observador
												}
											/>
										</div>
									)}
								</>
							) : null}

							<div className="campo-form">
								<label>Technitian</label>

								<Combobox
									url="/tecnico"
									oName="cod_tecnico"
									property="nombre_tecnico"
									value="id_tecnico"
									handleChange={handleChange}
									desabilitar={true}
									actualValue={
										programacion.cod_tecnico === ""
											? null
											: programacion.cod_tecnico
									}
								/>
							</div>

							{programacion.cod_tecnico_observador === null ? null : (
								<div className="campo-form">
									<label>Technitian Observer</label>

									<Combobox
										url="/tecnico"
										oName="cod_tecnico_observador"
										property="nombre_tecnico"
										value="id_tecnico"
										desabilitar={true}
										handleChange={handleChange}
										actualValue={
											programacion.cod_tecnico_observador === ""
												? null
												: programacion.cod_tecnico_observador
										}
									/>
								</div>
							)}

							<div className="campo-form">
								<label>Log Page</label>
								<input
									type="number"
									name="concecutivo_vuelo"
									min="0"
									onChange={handleChange}
									value={programacion.concecutivo_vuelo}
								/>
							</div>

							<div className="campo-form">
								<label>TakeOff</label>
								<input
									type="time"
									name="takeoff"
									onChange={handleChange}
									value={programacion.takeoff}
									style={{ width: "40%" }}
								/>
							</div>

							<div className="campo-form">
								<label>Landing</label>
								<input
									type="time"
									name="landing"
									onChange={handleChange}
									value={programacion.landing}
									style={{ width: "40%" }}
								/>
							</div>

							<div className="campo-form">
								<label>TOW</label>
								<input
									type="number"
									name="tow"
									min="0"
									onChange={handleChange}
									value={programacion.tow}
								/>
							</div>

							<div className="campo-form">
								<label>Flight level</label>
								<input
									type="number"
									name="nivel_vuelo"
									min="0"
									onChange={handleChange}
									value={programacion.nivel_vuelo}
								/>
							</div>

							<div className="campo-form">
								<label>Total Fuel</label>
								<input
									type="number"
									name="total_fuel"
									min="0"
									onChange={handleChange}
									value={programacion.total_fuel}
								/>
							</div>

							<div className="campo-form">
								<label>Fuel Burned</label>
								<input
									type="number"
									name="fuel_burn"
									min="0"
									onChange={handleChange}
									value={programacion.fuel_burn}
								/>
							</div>

							<div className="campo-form">
								<label>Fuel added</label>
								<input
									type="number"
									name="fuel_add"
									min="0"
									onChange={handleChange}
									value={programacion.fuel_add}
								/>
							</div>

							<div className="campo-form">
								<input
									type="submit"
									className="btn btn-primario btn-block"
									value="Save"
								/>
							</div>
						</form>

						<div className="campo-form">
							<input
								type="button"
								className="btn btn-primario btn-block"
								value="Cancel"
								onClick={() => setRedirect(true)}
							/>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default CompleteProgramacion;
