import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import Tabla from '../utils/Table';
import http from '../utils/Http';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Combustible = ({ location }) => {
    const MySwal = withReactContent(Swal)

    const vuelo = location.vuelo;
    //console.log(vuelo.id_comercial);

    const [redirect, setRedirect] = useState(false);

    const [combustible, setCombustible] = useState({
        cod_comercial: "",
        fecha: "",
        precio_galon_facturado: "",
    });

    const handleChange = (e) => {
        setCombustible({
            ...combustible,
            [e.target.name]: e.target.value,
            cod_comercial: vuelo.id_comercial,
            fecha: vuelo.fecha.split("T")[0]
        });
    };

    const { moneda } = combustible

    const guardarDatos = () => {

        if (moneda === "") {
            MySwal.fire({
                title: <p style={{ fontSize: '200%' }}>Warning</p>,
                html: <p style={{ fontSize: '200%' }}>Select a currency</p>,
                icon: 'warning',
                confirmButtonText: 'Try again'
            });
            return;
        }

        http
            .post("/combustible", combustible)
            .then((response) => {
                if (response.data.insert) {
                    MySwal.fire({
                        title: <p style={{ fontSize: '200%' }}>Success</p>,
                        html: <p style={{ fontSize: '200%' }}>Succesfully updated</p>,
                        icon: 'success',
                        confirmButtonText: 'Continue'
                    }).then(() => {
                        setRedirect(true);
                    }).catch(() => {
                        console.log("IDK how this would be possible");
                    });
                }
            })
            .catch((error) => {
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Error</p>,
                    html: <p style={{ fontSize: '200%' }}>Failed update</p>,
                    icon: 'error',
                    confirmButtonText: 'Try again'
                });
                console.log({ error });
            })

    }

    return (

        <Fragment>

            {redirect ? <Redirect to={"/main"} /> : ""}

            <h1 style={{ marginTop: 10 }}>Fuel</h1>

            <Tabla vuelo={vuelo} />

            <div className="contenedor-tareas">
                <div className="form-usuario7">
                    <div className="contenedor-form sombra-dark">

                        <div className="campo-form">
                            <label> Date:</label>
                            <label>{vuelo.fecha.split("T")[0]}</label>

                        </div>


                        <div className="campo-form">
                            <label> Billed Gallon Price (USD): </label>
                            <input
                                type="number"
                                name="precio_galon_facturado"
                                onChange={handleChange}
                                value={combustible.precio_galon_facturado}
                            />
                        </div>


                        <div className="campo-form">
                            <input
                                type="button"
                                className="btn btn-primario btn-block"
                                value="Save"
                                onClick={guardarDatos}
                            />
                        </div>

                        <div className="campo-form">
                            <input
                                type="button"
                                className="btn btn-primario btn-block"
                                value="Cancel"
                                onClick={() => setRedirect(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default Combustible;
