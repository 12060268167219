/**
 * Describe el estilo y los datos del reporte de la tripulación de los vuelos
 * para la hoja tripulación
 */

const colors = {
  azul: "1155cc",
  verde: "38761d",
  naranja: "fb8605",
};

const border = {
  top: {
    style: "thin",
  },
  bottom: {
    style: "thin",
  },
  left: {
    style: "thin",
  },
  right: {
    style: "thin",
  },
};

const cellStyle = {
  font: {
    sz: "10",
  },
  border,
};

function getStyle(color) {
  return {
    font: {
      sz: "10",
      bold: true,
      color: {
        rgb: "ffffff",
      },
    },
    fill: {
      patternType: "solid",
      fgColor: {
        rgb: colors[color],
      },
    },
    border,
  };
}

function getReporteTripulacion(data) {
  return [
    {
      columns: [
        { title: "Date", width: { wpx: 100 }, style: getStyle("azul") },
        { title: "Route", width: { wpx: 100 }, style: getStyle("azul") },
        { title: "Tail Number", width: { wpx: 100 }, style: getStyle("azul") },
        { title: "CA", width: { wpx: 100 }, style: getStyle("azul") },
        { title: "FO", width: { wpx: 100 }, style: getStyle("azul") },
        {
          title: "Who Made Landing",
          width: { wpx: 100 },
          style: getStyle("azul"),
        },
        {
          title: "Who Made Takeoff",
          width: { wpx: 100 },
          style: getStyle("azul"),
        },
        { title: "ATD", width: { wpx: 100 }, style: getStyle("azul") },
        { title: "ATA", width: { wpx: 100 }, style: getStyle("azul") },
        { title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
      ],
      data: data.map((data) => [
        { value: data.fecha ? data.fecha.split("T")[0] : "", style: cellStyle },
        { value: data.ruta ? data.ruta : "", style: cellStyle },
        {
          value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "",
          style: cellStyle,
        },
        {
          value: data.nombre_capitan ? data.nombre_capitan : "",
          style: cellStyle,
        },
        {
          value: data.nombre_copiloto ? data.nombre_copiloto : "",
          style: cellStyle,
        },
        {
          value: data.who_made_landing ? data.who_made_landing : "",
          style: cellStyle,
        },
        {
          value: data.who_made_takeoff ? data.who_made_takeoff : "",
          style: cellStyle,
        },
        {
          value: data.departure_time ? data.departure_time : "",
          style: cellStyle,
        },
        { value: data.arrival_time ? data.arrival_time : "", style: cellStyle },
        { value: "" },
      ]),
    },
  ];
}

export default getReporteTripulacion;
