import React, { useState } from "react";
import http from "../Http";
import DateRange from "../DateRange";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import excelBitacora from "./excelBitacora";

const MySwal = withReactContent(Swal);

const ReporteBitacora = () => {
	const [dateRange, setRange] = useState({
		fechaInicio: new Date().toISOString().split("T")[0],
		fechaFinal: new Date().toISOString().split("T")[0],
	});

	const [employee, setEmployee] = useState("");
	const [tripulacion, setTripulacion] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFileReady, setIsFileReady] = useState(false);

	const handleChange = (e) => {
		setRange({
			...dateRange,
			[e.target.name]: e.target.value,
		});
	};

	const handleEmployee = (e) => {
		setEmployee(e.target.value);
	};

	const search = async () => {
		if (employee === "") {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Info</p>,
				html: (
					<p style={{ fontSize: "200%" }}>Write a name for the crew Logbook</p>
				),
				icon: "info",
				confirmButtonText: "Try again",
			});
			return;
		}

		try {
			setIsLoading(true);
			const tripulacion = await http.get("/bitacora", {
				params: {
					startDate: dateRange.fechaInicio,
					endDate: dateRange.fechaFinal,
					tripulante: employee,
				},
			});

			if (tripulacion.data.length === 0) {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Info</p>,
					html: (
						<p style={{ fontSize: "200%" }}>No such data for selected range</p>
					),
					icon: "info",
					confirmButtonText: "Try again",
				});
			} else {
				setTripulacion(excelBitacora(tripulacion.data));
				setIsFileReady(true);
				setTimeout(() => {
					setIsFileReady(false);
				}, 1000);
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Error</p>,
				html: <p style={{ fontSize: "200%" }}>An error has occurred</p>,
				icon: "error",
				confirmButtonText: "Try again",
			});
			console.log({ error });
		}
	};

	return (
		<div>
			<DateRange
				value={dateRange}
				handleChange={handleChange}
				title={`LogBook`}
				datasets={[{ dataset: tripulacion, sheetTitle: `${employee}` }]}
				fileReady={isFileReady}
				loading={isLoading}
				search={search}
				employee={handleEmployee}
				nombre={`${employee}`}
			/>
		</div>
	);
};

export default ReporteBitacora;
