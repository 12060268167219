import React, {Fragment,useState} from 'react';
import {Link} from "react-router-dom";

const RecuperarContraseña = () => {

    const[usuario,guardarUsuario]= useState({
        contrasena:"",
        confirmar:""
    });

    const {contrasena,confirmar} = usuario;

    return (
        <Fragment>

            <div className="form-usuario">
                <div className="contenedor-form sombra-dark">
           
                    <h1>Recuperar contraseña</h1>

                    <form>

                        <div className="campo-form">
                            <label htmlFor="password"> Contraseña </label>
                            <input
                                type="password"
                                id="password"
                                placeholder="Tu contraseña"
                                value={contrasena}
                                onChange={(e)=>guardarUsuario({contrasena:e.target.value,confirmar})}
                            />
                        </div>

                        <div className="campo-form">
                            <label htmlFor="confirmar"> Confirmar </label>
                            <input 
                                type="password"
                                id="confirmar"
                                value={confirmar}
                                placeholder="Confirma la contraseña"
                                onChange={(e)=>guardarUsuario({contrasena,confirmar:e.target.value})}
                            />
                        </div>

                        <div className="campo-form">
                            <input 
                                type="submit"
                                className="btn btn-primario btn-block"
                                value="recuperar"
                                
                            />
                        </div>


                    </form>

                    <Link to={"/"} className="enlace-cuenta">
                        Regresar
                    </Link>

                </div>

            </div>
            

        </Fragment>
    );
};

export default RecuperarContraseña;