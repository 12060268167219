import React, { Fragment, useState } from "react";
import Tabla from "../utils/Table";
import { Redirect } from "react-router-dom";
import http from '../utils/Http'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const PlanificacionFinanciera = ({ location }) => {
    const vuelo = location.vuelo;

    const [redirect, setRedirect] = useState(false);

    const [planificacion, setPlanificacion] = useState({
        cod_comercial: "",
        causado: "",
    });


    const handleChange = e => {
        setPlanificacion({
            ...planificacion,
            [e.target.name]: e.target.value,
            cod_comercial: vuelo.id_comercial
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (!planificacion.causado) {
            MySwal.fire({
                title: <p style={{ fontSize: '200%' }}>Warning</p>,
                html: <p style={{ fontSize: '200%' }}>You must choose an option</p>,
                icon: 'warning',
                confirmButtonText: 'Try again'
            });
            return;
        }

        // Despues de que pasen todas las validaciones redirecionar
        http.post("/api/planificacion", planificacion)
            .then((response) => {
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Success</p>,
                    html: <p style={{ fontSize: '200%' }}>Succesfully created</p>,
                    icon: 'success',
                    confirmButtonText: 'Continue'
                }).then(() => {
                    setRedirect(true);
                }).catch(() => {
                    console.log("IDK how this would be possible");
                });
            })
            .catch((error) => {
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Error</p>,
                    html: <p style={{ fontSize: '200%' }}>Failed update</p>,
                    icon: 'error',
                    confirmButtonText: 'Try again'
                });
                console.error(error);
            })
    };


    return (
        <Fragment>
            {redirect ? <Redirect to={"/main"} /> : ""}

            <div className="contenedor-app">
                <div className="seccion-principal">
                    <h1>Planeación Financiera</h1>

                    <Tabla vuelo={vuelo} />

                    <div className="contenedor-tareas">
                        <div
                            className="form-usuario7"
                            style={{ maxHeight: "500px", overflow: "auto" }}
                        >
                            <div className="contenedor-form sombra-dark">
                                <form onSubmit={onSubmit}>
                                    <div className="campo-form">
                                        <label> Causado </label>
                                        <select
                                            name="causado"
                                            style={{ width: "40%" }}
                                            onChange={handleChange}

                                        >
                                            <option value="">
                                                Select option
                                            </option>
                                            <option value="1">Caused</option>
                                            <option value="0">Cancel</option>
                                        </select>
                                    </div>

                                    <div className="campo-form">
                                        <input
                                            type="submit"
                                            className="btn btn-primario btn-block"
                                            value="Save"
                                        />
                                    </div>
                                </form>

                                <div className="campo-form">
                                    <input
                                        type="button"
                                        className="btn btn-primario btn-block"
                                        value="Cancelar"
                                        onClick={() => setRedirect(true)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PlanificacionFinanciera;
