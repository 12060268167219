import React, { Fragment, useEffect, useState } from "react";
import Tabla from "../utils/Table";
import { Redirect } from "react-router-dom";
import http from "../utils/Http";
import Combobox from ".././utils/Combobox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Check from "../utils/CheckBox";
import ToolTip from "../utils/ToolTip";

const MySwal = withReactContent(Swal);

const Programacion = ({ location }) => {
	const vuelo = location.vuelo;

	const [programacion, setProgramacion] = useState({
		id_programacion: "",
		cod_comercial: 0,
		cod_capitan: "",
		cod_copiloto: "",
		cod_ingeniero: null,
		cod_tecnico: null,
		concecutivo_vuelo: 0,
		takeoff: "",
		landing: "",
		tow: 0,
		nivel_vuelo: 0,
		total_fuel: 0,
		fuel_burn: 0,
		fuel_add: 0,
		cod_capitan_observador: null,
		cod_copiloto_observador: null,
		cod_ingeniero_observador: null,
		cod_tecnico_observador: null,
		hora_reporte_trip: "",
	});

	const [despacho, setDespacho] = useState({
		cod_comercial: 0,
		departure_time: "",
		arrival_time: "",
		dayplusATD: false,
		dayminusATD: false,
	});

	const handleChangeDespacho = (e) => {
		const { name, value } = e.target;

		setDespacho((prevState) => ({
			...prevState,
			[name]: value,
			cod_comercial: vuelo.id_comercial,
		}));
	};

	const handleCheck = (e) => {
		setDespacho({
			...despacho,
			[e.target.name]: e.target.checked,
		});
	};

	const { cod_capitan, cod_copiloto, cod_ingeniero, cod_tecnico } =
		programacion;

	const [redirect, setRedirect] = useState(false);

	let [actualizar, setActualizar] = useState(false);
	let [despachoActualizar, setDespachoActualizar] = useState(false);

	const handleChange = (e) => {
		setProgramacion({
			...programacion,
			[e.target.name]: e.target.value,
			cod_comercial: vuelo.id_comercial,
		});
	};

	const actualizarDespacho = () => {
		http
			.put("/despacho", despacho)
			.then((response) => {
				if (response.data.insert) {
					console.log("Si se guardo los datos de despacho");
				}
			})
			.catch((error) => {
				console.log({ error });
			});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		// Despues de que pasen todas las validaciones redirecionar

		if (cod_capitan === "" || cod_copiloto === "" || cod_tecnico === "") {
			// if (checkIngeneer) {
			if (cod_ingeniero === "") {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Warning</p>,
					html: <p style={{ fontSize: "200%" }}>There are required fields</p>,
					icon: "warning",
					confirmButtonText: "Try again",
				});
				return;
			}
			// }
			return;
		}

		if (actualizar) {
			// Se hace la petición al end point para actualizar
			http
				.put("/programacion", programacion)
				.then((response) => {
					if (response.data.insert) {
						MySwal.fire({
							title: <p style={{ fontSize: "200%" }}>Success</p>,
							html: <p style={{ fontSize: "200%" }}>Succesfully updated</p>,
							icon: "success",
							confirmButtonText: "Continue",
						})
							.then(() => {
								setRedirect(true);
							})
							.catch(() => {
								console.log("IDK how this would be possible");
							});
					}
				})
				.catch((error) => {
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Max time (24 hours) reached. Please contact process leader
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
					console.log(error);
				});
			if (despachoActualizar) {
				actualizarDespacho();
			}
		} else {
			http
				.post("/programacion", programacion)
				.then((response) => {
					if (response.data.insert) {
						MySwal.fire({
							title: <p style={{ fontSize: "200%" }}>Success</p>,
							html: <p style={{ fontSize: "200%" }}>Succesfully updated</p>,
							icon: "success",
							confirmButtonText: "Continue",
						})
							.then(() => {
								setRedirect(true);
							})
							.catch(() => {
								console.log("IDK how this would be possible");
							});
					}
				})
				.catch((error) => {
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Max time (24 hours) reached. Please contact administrator
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
					console.log(error);
				});
			if (despachoActualizar) {
				actualizarDespacho();
			}
		}
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				const result = await http.get(`/programacionraw/${vuelo.id_comercial}`);
				if (result.data.length !== 0) {
					setProgramacion(result.data[0]);
					setActualizar(true);
				}
			} catch (error) {
				console.log(error);
			}
		};

		const loadDataDespacho = async () => {
			try {
				const result = await http.get(`/despachoraw/${vuelo.id_comercial}`);
				if (result.data.length !== 0) {
					setDespacho(result.data[0]);
					setDespacho((d) => {
						return {
							...d,
							cod_matricula_avion: vuelo.cod_matricula_avion,
						};
					});
					setDespachoActualizar(true);
				}
			} catch (error) {
				console.log(error);
			}
		};

		loadData();
		loadDataDespacho();
	}, [vuelo.id_comercial, vuelo.cod_matricula_avion]);

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<h1>Flight Operations</h1>

			<Tabla vuelo={vuelo} />

			<div className="contenedor-tareas">
				<div className="form-usuario4">
					<div className="contenedor-form sombra-dark">
						<form onSubmit={onSubmit}>
							<div className="campo-form">
								<label>
									Captain <span className="required">*</span>
								</label>

								<Combobox
									url="/capitan"
									oName="cod_capitan"
									property="nombre_capitan"
									value="id_capitan"
									handleChange={handleChange}
									actualValue={
										programacion.cod_capitan === ""
											? null
											: programacion.cod_capitan
									}
								/>
							</div>

							<div className="campo-form">
								<label>Captain Observer</label>

								<Combobox
									url="/capitan"
									oName="cod_capitan_observador"
									property="nombre_capitan"
									value="id_capitan"
									handleChange={handleChange}
									actualValue={
										programacion.cod_capitan_observador === ""
											? null
											: programacion.cod_capitan_observador
									}
								/>
							</div>

							<div className="campo-form">
								<label>
									First Officer <span className="required">*</span>
								</label>

								<Combobox
									url="/copiloto"
									oName="cod_copiloto"
									property="nombre_copiloto"
									value="id_copiloto"
									handleChange={handleChange}
									actualValue={
										programacion.cod_copiloto === ""
											? null
											: programacion.cod_copiloto
									}
								/>
							</div>

							<div className="campo-form">
								<label>First Officer Observer</label>

								<Combobox
									url="/copiloto"
									oName="cod_copiloto_observador"
									property="nombre_copiloto"
									value="id_copiloto"
									handleChange={handleChange}
									actualValue={
										programacion.cod_copiloto_observador === ""
											? null
											: programacion.cod_copiloto_observador
									}
								/>
							</div>

							{
								/*checkIngeneer()*/ true ? (
									<>
										<div className="campo-form">
											<label>
												Flight Engineer <span className="required">*</span>
											</label>

											<Combobox
												url="/ingeniero"
												oName="cod_ingeniero"
												property="nombre_ingeniero"
												value="id_ingeniero"
												handleChange={handleChange}
												actualValue={
													programacion.cod_ingeniero === ""
														? null
														: programacion.cod_ingeniero
												}
											/>
										</div>

										<div className="campo-form">
											<label>Flight Engineer Obsever</label>

											<Combobox
												url="/ingeniero"
												oName="cod_ingeniero_observador"
												property="nombre_ingeniero"
												value="id_ingeniero"
												handleChange={handleChange}
												actualValue={
													programacion.cod_ingeniero_observador === ""
														? null
														: programacion.cod_ingeniero_observador
												}
											/>
										</div>
									</>
								) : null
							}

							<div className="campo-form">
								<label>Technitian</label>

								<Combobox
									url="/tecnico"
									oName="cod_tecnico"
									property="nombre_tecnico"
									value="id_tecnico"
									handleChange={handleChange}
									actualValue={
										programacion.cod_tecnico === ""
											? null
											: programacion.cod_tecnico
									}
								/>
							</div>

							<div className="campo-form">
								<label>Technitian Observer</label>

								<Combobox
									url="/tecnico"
									oName="cod_tecnico_observador"
									property="nombre_tecnico"
									value="id_tecnico"
									handleChange={handleChange}
									actualValue={
										programacion.cod_tecnico_observador === ""
											? null
											: programacion.cod_tecnico_observador
									}
								/>
							</div>

							<div className="campo-form">
								<label>Crew report hour</label>
								<input
									type="time"
									style={{ width: "40%" }}
									name="hora_reporte_trip"
									value={programacion.hora_reporte_trip}
									onChange={handleChange}
								/>
								<label style={{ marginLeft: 10 }}>UTC</label>
							</div>

							<hr></hr>

							<div className="campo-form">
								<label>ATD</label>
								<input
									type="time"
									name="departure_time"
									value={despacho.departure_time}
									onChange={handleChangeDespacho}
									style={{ width: "40%" }}
									disabled={!despachoActualizar}
								/>

								<table>
									<tr>
										<td>
											{" "}
											<label style={{ marginLeft: 10 }}>UTC</label>
										</td>
										<td>
											{" "}
											<label style={{ marginLeft: 17 }}>+</label>
											<Check
												oname="dayplusATD"
												value={despacho.dayplusATD}
												handleCheck={handleCheck}
											/>
										</td>
										<td>
											<label style={{ marginLeft: 17 }}> - </label>
											<Check
												oname="dayminusATD"
												value={despacho.dayminusATD}
												handleCheck={handleCheck}
											/>
										</td>
										<td>
											<ToolTip mensaje="Seleccionar si toca sumarle o restarle un dia a la fecha actual." />
										</td>
									</tr>
								</table>
							</div>

							<div className="campo-form">
								<label>ATA</label>
								<input
									type="time"
									name="arrival_time"
									value={despacho.arrival_time}
									onChange={handleChangeDespacho}
									style={{ width: "40%" }}
									disabled={!despachoActualizar}
								/>
								<label style={{ marginLeft: 10 }}>UTC</label>
							</div>

							<div className="campo-form">
								<input
									type="submit"
									className="btn btn-primario btn-block"
									value="Save"
								/>
							</div>
						</form>

						<div className="campo-form">
							<input
								type="button"
								className="btn btn-primario btn-block"
								value="Cancel"
								onClick={() => setRedirect(true)}
							/>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Programacion;
