import React, { Fragment, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Tabla from "../utils/Table";
import http from "../utils/Http";
import VerFlightOperations from "./VerFlightOperations";
import VerPcp from "./VerPcp";

const VerProgramacion = ({ location }) => {
	const local = JSON.parse(window.localStorage.getItem("datos"));

	const { rol } = local;

	const titulo = rol === 2;

	const vuelo = location.vuelo;

	const [redirect, setRedirect] = useState(false);
	const [programacion, setProgramacion] = useState({
		id_programacion: "",
		cod_comercial: "",
		nombre_capitan: null,
		nombre_copiloto: null,
		nombre_ingeniero: null,
		nombre_tecnico: null,
		concecutivo_vuelo: 0,
		takeoff: "",
		landing: "",
		tow: 0,
		nivel_vuelo: 0,
		total_fuel: 0,
		fuel_burn: 0,
		fuel_add: 0,
		cod_capitan_observador: null,
		cod_copiloto_observador: null,
		cod_ingeniero_observador: null,
		cod_tecnico_observador: null,
		hora_reporte_trip: "",
	});

	useEffect(() => {
		const id = vuelo.id_comercial;
		http
			.get(`/programacionRaw/${id}`)
			.then((response) => {
				if (response.data.length > 0) {
					setProgramacion(response.data[0]);
				}
			})
			.catch((error) => {
				console.log(error.message);
			});
	}, [vuelo.id_comercial]);

	const checkIngeneer = () => {
		let check = false;
		if (vuelo) {
			if (vuelo.cod_equipo_programado.includes("727")) {
				check = true;
			}
		}
		return check;
	};

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<div>
				<div>
					<h1>{titulo ? "Flight Operations" : "PCP"}</h1>
					<Tabla vuelo={vuelo} />
				</div>

				<div className="contenedor-tareas">
					<div className={titulo ? "form-usuario4" : "form-usuario4"}>
						<div className="contenedor-form sombra-dark">
							{titulo ? (
								<VerFlightOperations
									programacion={programacion}
									ingeneer={checkIngeneer()}
								/>
							) : (
								<VerPcp
									equipoProgramado={vuelo.cod_equipo_programado}
									programacion={programacion}
								/>
							)}

							<div className="campo-form">
								<input
									type="button"
									className="btn btn-primario btn-block"
									value="Back"
									onClick={() => setRedirect(true)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default VerProgramacion;
