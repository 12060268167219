import React, { useState, useEffect } from "react";
import Barra from "../layout/Barra";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import http from "../utils/Http";

const Reagendar = () => {
    const [aviones, setAviones] = useState([]);

    const [mantenimientos, setMantenimientos] = useState([]);

    const [mantenimiento, setMantenimiento] = useState({
        matricula_avion: "0",
        tipo: "",
        date_in: "",
        hour_in: "",
        date_out: "",
        hour_out: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setMantenimiento((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        http.get("/matricula_avion")
            .then((response) => {
                setAviones(response.data[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        http.get(`/mantenimiento/${mantenimiento.matricula_avion}`)
            .then((response) => {
                setMantenimientos(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [mantenimiento.matricula_avion]);

    const deleteMaintenance = (id_mantenimiento) => {
        http.delete(`/mantenimiento/${id_mantenimiento}`)
            .then((response) => {
                const deletedMaintenance = mantenimientos.filter(
                    (mantenimiento) => {
                        return (
                            mantenimiento.id_mantenimiento !== id_mantenimiento
                        );
                    }
                );

                setMantenimientos(deletedMaintenance);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div>
            <header>
                <Barra />
            </header>

            <center>
                <section style={{ marginTop: 30, marginBottom: 30 }}>
                    <select
                        name="matricula_avion"
                        value={mantenimiento.matricula_avion}
                        onChange={handleChange}
                        style={{ width: "20%" }}
                    >
                        <option value="0">Select</option>
                        {aviones.map((avion, index) => (
                            <option
                                value={avion.id_matricula_avion}
                                key={index}
                            >
                                {avion.nombre_matricula_avion}
                            </option>
                        ))}
                    </select>

                    <Button
                        variant="contained"
                        component={Link}
                        style={{
                            marginLeft: 50,
                            height: 35,
                            backgroundColor: "#f0c60a",
                            color: "#000",
                        }}
                        to={{
                            pathname: "mantenimiento",
                        }}
                    >
                        Cancel
                    </Button>
                </section>

                <section>
                    <table border="1px">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Date in</td>
                                <td>Hour in</td>
                                <td>Date out</td>
                                <td>Hour out</td>
                                <td>Tipo</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {mantenimientos.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.id_mantenimiento}</td>
                                        <td>{item.date_in.split("T")[0]}</td>
                                        <td>{item.hour_in}</td>
                                        <td>{item.date_out.split("T")[0]}</td>
                                        <td>{item.hour_out}</td>
                                        <td>{item.tipo}</td>
                                        <td>
                                            <Button
                                                variant="contained"
                                                component={Link}
                                                color="primary"
                                                to={{
                                                    pathname:
                                                        "verMantenimiento",
                                                    item: item,
                                                    aviones: aviones,
                                                }}
                                            >
                                                Modify
                                            </Button>
                                        </td>

                                        <td>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    deleteMaintenance(
                                                        item.id_mantenimiento
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </section>
            </center>
        </div>
    );
};

export default Reagendar;
