import React from 'react'

const Barra = () => {

    const cerrarSesion = () =>{
        window.localStorage.clear();
    }

    const llave = JSON.parse(window.localStorage.getItem("datos"));

    return ( 
        <header className="app-header">
            <p className="nombre-usuario"> Welcome <span>{llave.nombre}</span></p>
            <p className="nombre-usuario">{llave.departamento}</p>
            <nav className="nav-principal">
                <a 
                    href="/"
                    onClick={cerrarSesion}
                >Sign out</a>

            </nav>

        </header>
     );
}
 
export default Barra;