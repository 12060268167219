import React, { useState, useEffect } from "react";
import Barra from "../layout/Barra";
import { Redirect } from "react-router";
import http from "../utils/Http";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TextField from "@mui/material/TextField";
import Report from "./Report";

const Mantenimiento = () => {
	// Se obtiene el rol del usuario
	const local = JSON.parse(window.localStorage.getItem("datos"));

	const MySwal = withReactContent(Swal);
	const [mantenimiento, setMantenimiento] = useState({
		matricula_avion: "0",
		tipo: "",
		date_in: "",
		hour_in: "",
		date_out: "",
		hour_out: "",
		remarks: "",
		cod_submantenimiento: "0",
	});

	const [hide, setHide] = useState(true);
	const [hideReSheduled, setHideReSheduled] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [aviones, setAviones] = useState([]);
	const [subMantenimiento, setSubMantenimiento] = useState([]);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setMantenimiento((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const { matricula_avion, date_in, hour_in, date_out, hour_out, tipo } =
		mantenimiento;

	const limpiarCampos = () => {
		setMantenimiento({
			matricula_avion: "0",
			date_in: "",
			hour_in: "",
			date_out: "",
			hour_out: "",
		});
		setHide(true);
	};

	const validarDisponibilidad = async () => {
		let answer = null;

		try {
			const consulta = await http.get("/reporte/mantenimiento/tail", {
				params: {
					startDate: date_in,
					endDate: date_out,
					tail: matricula_avion,
				},
			});

			if (consulta.data.length > 0) {
				// debo verificar las horas.
				/* 
					Mientras las hora de entrada selecionada sea menor que la salida de los ya agendados.
				*/
				answer = consulta.data.find(
					(mantenimiento) =>
						(hour_in > mantenimiento.hour_in &&
							hour_out > mantenimiento.hour_in) ||
						hour_in < mantenimiento.hour_out
				);

				if (answer === undefined) {
					answer = null;
				}
			}
		} catch (error) {}
		return answer;
	};

	const guardarDatos = async () => {
		if (
			matricula_avion === "0" ||
			date_in === "" ||
			hour_in === "" ||
			date_out === "" ||
			hour_out === "" ||
			tipo === ""
		) {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: <p style={{ fontSize: "200%" }}>All fields are required</p>,
				icon: "warning",
				confirmButtonText: "Try again",
			});
			return;
		}

		// Aca se debe validar si el mantenimiento no interconecta con otro
		const validate = await validarDisponibilidad();
		if (validate) {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Error</p>,
				html: (
					<p style={{ fontSize: "200%" }}>
						{`Already exist a maintenance for this slot with the following ID: ${validate.id_mantenimiento}.`}
					</p>
				),
				icon: "error",
				confirmButtonText: "Try again",
			});
			return;
		}

		const data = { mantenimiento, local };

		http
			.post("/mantenimiento", data)
			.then((response) => {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Success</p>,
					html: <p style={{ fontSize: "200%" }}>Succesfully updated</p>,
					icon: "success",
					confirmButtonText: "Continue",
				})
					.then(() => {
						setHide(true);
					})
					.catch(() => {
						console.log("IDK how this would be possible");
					});
				limpiarCampos();
			})
			.catch((error) => {
				console.log(error.message);
			});
	};

	useEffect(() => {
		http
			.get("/matricula_avion")
			.then((response) => {
				setAviones(response.data[0]);
			})
			.catch((error) => {
				console.log(error);
			});
		http
			.get("/subMantenimiento")
			.then((response) => {
				setSubMantenimiento(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div>
			{hideReSheduled === true ? <Redirect to={"/reagendar"} /> : ""}
			<Report
				open={openModal}
				handleClose={() => {
					setOpenModal(false);
				}}
			/>
			<header>
				<Barra />
			</header>

			<main style={{ marginTop: 20 }}>
				<center>
					{hide ? (
						<div
							style={{
								alignContent: "center",
								marginTop: 50,
								padding: 20,
							}}
						>
							<input
								type="submit"
								value="New maintenance"
								onClick={() => {
									setHide(false);
								}}
								style={{ marginRight: 20 }}
							/>

							<input
								type="submit"
								value="Reschedule"
								onClick={() => {
									setHideReSheduled(true);
								}}
								style={{ marginRight: 20 }}
							/>

							<input
								type="submit"
								value="Report"
								onClick={() => {
									setOpenModal(true);
								}}
							/>
						</div>
					) : (
						<>
							{" "}
							<div className="contenedor-form2 ">
								<div className="campo-form">
									<label>
										Tail <span className="required">*</span>
									</label>

									<select
										name="matricula_avion"
										value={mantenimiento.matricula_avion}
										onChange={handleChange}
										style={{ width: "40%" }}
									>
										<option value="0">Select</option>
										{aviones.map((avion, index) => (
											<option value={avion.id_matricula_avion} key={index}>
												{avion.nombre_matricula_avion}
											</option>
										))}
									</select>

									<label>Type</label>

									<select
										name="tipo"
										style={{ width: "40%" }}
										onChange={handleChange}
										value={mantenimiento.tipo}
									>
										<option value="">Choose a option</option>
										<option value="Scheduled">Scheduled</option>
										<option value="Not Scheduled">Not Scheduled</option>
									</select>
								</div>

								<div className="campo-form">
									<label>SubType</label>

									<select
										name="cod_submantenimiento"
										value={mantenimiento.cod_submantenimiento}
										onChange={handleChange}
										style={{ width: "36.5%" }}
									>
										{" "}
										<option value="0">Choose a option</option>
										{subMantenimiento.map((item) => {
											return (
												<option
													key={item.id_subMantenimiento}
													value={item.id_subMantenimiento}
												>
													{item.nombre_subMantenimiento}
												</option>
											);
										})}
									</select>
								</div>

								<div className="campo-form">
									<label> Date in: </label>
									<input
										type="date"
										name="date_in"
										style={{ width: "40%" }}
										onChange={handleChange}
										value={mantenimiento.date_in}
									/>

									<label> Date out: </label>
									<input
										type="date"
										name="date_out"
										style={{ width: "40%" }}
										onChange={handleChange}
										value={mantenimiento.date_out}
									/>
								</div>

								<div className="campo-form">
									<label> Hour in: </label>
									<input
										type="time"
										name="hour_in"
										style={{ width: "40%" }}
										onChange={handleChange}
										value={mantenimiento.hour_in}
									/>

									<label> Hour out: </label>
									<input
										type="time"
										name="hour_out"
										style={{ width: "40%" }}
										onChange={handleChange}
										value={mantenimiento.hour_out}
									/>
								</div>

								<TextField
									id="outlined-multiline-flexible"
									label="Remarks"
									name="remarks"
									InputLabelProps={{
										style: {
											fontSize: 18,
											fontWeight: "bold",
										},
									}}
									multiline
									fullWidth
									rows={4}
									onChange={handleChange}
									value={mantenimiento.remakrs}
									InputProps={{
										style: {
											fontSize: 16,
										},
									}}
								/>

								<div className="campo-form">
									<input
										type="button"
										className="btn btn-primario btn-block"
										value="Save"
										onClick={guardarDatos}
									/>
								</div>

								<div className="campo-form">
									<input
										type="button"
										className="btn btn-primario btn-block"
										value="Cancel"
										onClick={() => limpiarCampos()}
									/>
								</div>
							</div>
						</>
					)}
				</center>
			</main>
		</div>
	);
};

export default Mantenimiento;
