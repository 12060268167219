import React from "react";
import { Redirect } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import Barra from "../layout/Barra";
import IngresarUsuario from "./ingresarUsuario";

const GestionAplicacion = () => {
  const local = JSON.parse(window.localStorage.getItem("datos"));

  const { rol } = local;

  const gestion = rol !== 10;

  return (
    <>
      {gestion ? (
        <Redirect to={"/"} />
      ) : (
        <>
          <Barra />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "80vw",
                height: 140,
                marginLeft: "10vw",
              },
            }}
          >
            <Paper elevation={12}>
              <IngresarUsuario />
            </Paper>
            <Paper elevation={12}>Clientes</Paper>
          </Box>
        </>
      )}
    </>
  );
};

export default GestionAplicacion;