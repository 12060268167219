import React, { useState } from "react";
import http from "../utils/Http";
import getBaseDataSet from "../utils/Reports/baseSheet";
import getCombustibleDataSet from "../utils/Reports/combustibleSheet";
import DateRange from "../utils/DateRange";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const ReporteMayorRange = () => {
  const [dateRange, setRange] = useState({
    fechaInicio: new Date().toISOString().split("T")[0],
    fechaFinal: new Date().toISOString().split("T")[0],
  });

  const [dataSet, setDataSet] = useState([]);
  const [combustibleDataSet, setCombustibleDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileReady, setIsFileReady] = useState(false);

  const handleChange = (e) => {
    setRange({
      ...dateRange,
      [e.target.name]: e.target.value,
    });
  };

  const search = async () => {
    try {
      setIsLoading(true);
      const tmp = await http.get("/api/reporte", {
        params: {
          fechaInicio: dateRange.fechaInicio,
          fechaFinal: dateRange.fechaFinal,
        },
      });

      if (tmp.data.length === 0) {
        MySwal.fire({
          title: <p style={{ fontSize: "200%" }}>Info</p>,
          html: (
            <p style={{ fontSize: "200%" }}>No such data for selected range</p>
          ),
          icon: "info",
          confirmButtonText: "Try again",
        });
      } else {
        setDataSet(getBaseDataSet(tmp.data));
        setCombustibleDataSet(getCombustibleDataSet(tmp.data));
        setIsFileReady(true);
        setTimeout(() => {
          setIsFileReady(false);
        }, 1000);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      MySwal.fire({
        title: <p style={{ fontSize: "200%" }}>Error</p>,
        html: <p style={{ fontSize: "200%" }}>An error has occurred</p>,
        icon: "error",
        confirmButtonText: "Try again",
      });
      console.log({ error });
    }
  };

  return (
    <div>
      <DateRange
        value={dateRange}
        handleChange={handleChange}
        disable={isLoading || isFileReady}
        title={"Report"}
        datasets={[
          { dataset: dataSet, sheetTitle: "Base" },
          { dataset: combustibleDataSet, sheetTitle: "Combustible" },
        ]}
        fileReady={isFileReady}
        loading={isLoading}
        search={search}
      />
    </div>
  );
};

export default ReporteMayorRange;
