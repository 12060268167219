import * as React from "react";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

export default function BasicTooltip({ mensaje }) {
	return (
		<Tooltip title={mensaje}>
			<IconButton>
				<HelpIcon />
			</IconButton>
		</Tooltip>
	);
}
