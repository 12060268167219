import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
//import { Link } from "react-router-dom";
import http from "./utils/Http";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import logo from "../img/logo1.png";

const MySwal = withReactContent(Swal);

const Login = () => {
	const [usuario, guardarUsuario] = useState({
		login: "",
		contrasena: "",
	});

	const [redirect, guardarRedirect] = useState(false);

	const { login, contrasena } = usuario;

	const verificarUsuario = (e) => {
		e.preventDefault();

		// verificar si los campos estan llenos
		if (login === "" || contrasena === "") {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: <p style={{ fontSize: "200%" }}>All fields are required</p>,
				icon: "warning",
				confirmButtonText: "Try again",
			});
			return;
		}

		// verificar si el usuario y contraseña estan en la base de datos
		http
			.post("/login", {
				login: usuario.login,
				pasword: usuario.contrasena,
			})
			.then((response) => {
				const { validacion, rol, email, nombre, departamento, id_usuario } =
					response.data;

				if (validacion) {
					// OPbtener el token de validacion

					window.localStorage.setItem("token", 1);
					window.localStorage.setItem(
						"datos",
						JSON.stringify({
							rol,
							email,
							nombre,
							departamento,
							id_usuario,
						})
					);

					guardarRedirect(true);
				}
			})
			.catch((error) => {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Error</p>,
					html: (
						<p style={{ fontSize: "200%" }}>Invalid username or password</p>
					),
					icon: "error",
					confirmButtonText: "Try again",
				});
				console.log("es del error de login");
				console.log(error);
			});
		// redirecionar a la nueva pagina
	};

	return (
		<Fragment>
			{/* <div
                style={{
                    background:
                        "linear-gradient(270deg, rgba(71,160,196,1) 48%, rgba(37,62,143,1) 100%)",
                    textAlign:"center",
                    paddingTop:20,
                    marginBottom:0
                    
                }}
            >
                <h1 style={{display:"inline-block",color:"#fff", }}>LAS FLY</h1>
            </div> */}

			<div className="form-usuario-login">
				<div className="contenedor-form sombra-dark">
					<table>
						<tbody>
							<tr>
								<td>
									{" "}
									<img src={logo} alt="" style={{ paddingLeft: 30 }} />
								</td>
								<td>
									{" "}
									<h1 style={{ paddingTop: 50, paddingLeft: 10 }}>LAS Fly</h1>
								</td>
							</tr>
						</tbody>
					</table>

					<h2>Iniciar Sesión</h2>

					<form>
						<div className="campo-form">
							<label htmlFor="email"> Email </label>
							<input
								type="email"
								id="email"
								value={login}
								placeholder="Tu email es el usuario"
								onChange={(e) =>
									guardarUsuario({
										login: e.target.value,
										contrasena,
									})
								}
							/>
						</div>

						<div className="campo-form">
							<label htmlFor="password"> Contraseña </label>
							<input
								type="password"
								id="password"
								placeholder="Ingresa tu contraseña"
								value={contrasena}
								onChange={(e) =>
									guardarUsuario({
										login,
										contrasena: e.target.value,
									})
								}
							/>
						</div>

						<div className="campo-form">
							<input
								type="submit"
								className="btn btn-primario btn-block"
								value="Iniciar Sesión"
								onClick={verificarUsuario}
							/>
						</div>
					</form>

					{/* <Link to={"/recuperar-contraseña"} className="enlace-cuenta">
                        Recuperar contraseña
                    </Link> */}

					<center>
						<div style={{ marginTop: 20, width: 200 }}>version 1.14.0</div>
					</center>
				</div>
				<div>
					<p style={{ marginTop: 20, color: "#fff" }}>
						&copy;2021. Líneas Aéreas Suramericanas S.A.S Todos los derechos
						reservados
					</p>
				</div>
			</div>

			{redirect === true ? <Redirect to={"/main"} /> : ""}
		</Fragment>
	);
};

export default Login;
