/**
 * Describe el estilo y los datos del reporte de la tripulación de los vuelos
 */

import { getCellStyle, colors, getFont, getFill } from "../cellstyle.util";
// import moment from "moment";
import "moment-duration-format";

const maintenanceSheet = (data) => {
	return [
		{
			columns: styleHeaders(),
			data: styleData(data),
		},
	];
};

// const calcularBloque = (atd, ata) => {
// 	const atdTmp = moment.duration(atd);
// 	const ataTmp = moment.duration(ata);

// 	if (ataTmp < atdTmp) {
// 		ataTmp.add(1, "d");
// 	}

// 	let bloque = ataTmp.clone().subtract(atdTmp);
// 	return bloque._data.hours === 0
// 		? `00:${bloque.format("hh:mm")}`
// 		: bloque.format("hh:mm");
// };

const styleHeaders = () => {
	const headerStyle = getCellStyle(
		getFont(undefined, true, "ffffff"),
		getFill(colors["azulClaro"])
	);

	return [
		{ title: "ID", width: { wpx: 100 }, style: headerStyle },
		{ title: "TAIL", width: { wpx: 100 }, style: headerStyle },
		{ title: "DATE IN", width: { wpx: 100 }, style: headerStyle },
		{ title: "TIME IN", width: { wpx: 100 }, style: headerStyle },
		{ title: "DATE OUT", width: { wpx: 100 }, style: headerStyle },
		{ title: "TIME OUT", width: { wpx: 100 }, style: headerStyle },
		{ title: "TYPE", width: { wpx: 100 }, style: headerStyle },
		{ title: "SUBTYPE", width: { wpx: 100 }, style: headerStyle },
		{ title: "REMARKS", width: { wpx: 100 }, style: headerStyle },
		{ title: "TOTAL MAINTENANCE", width: { wpx: 100 }, style: headerStyle },
		{ title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
	];
};

const styleData = (data) => {
	return data.map((data) => [
		{
			value: data.id_mantenimiento ? parseInt(data.id_mantenimiento) : "",
			style: getCellStyle(1),
		},
		{
			value: data.nombre_matricula_avion ? data.nombre_matricula_avion : "",
			style: getCellStyle(),
		},
		{
			value: data.date_in ? data.date_in : "",
			style: getCellStyle(),
		},
		{ value: data.hour_in ? data.hour_in : "", style: getCellStyle() },
		{
			value: data.date_out ? data.date_out : "",
			style: getCellStyle(),
		},
		{
			value: data.hour_out ? data.hour_out : "",
			style: getCellStyle(),
		},
		{
			value: data.tipo ? data.tipo : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_subMantenimiento ? data.nombre_subMantenimiento : "",
			style: getCellStyle(),
		},
		{
			value: data.remarks ? data.remarks : "",
			style: getCellStyle(),
		},
		{
			value: data.totalHoras ? data.totalHoras : "",
			style: getCellStyle(2),
		},
		{ value: "" },
	]);
};

export { maintenanceSheet };
