import React, { useState, useEffect } from "react";
import Combobox from "../utils/Combobox";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import ListItemIcon from '@mui/material/ListItemIcon';
//I need departureFormHidden: boolean, openDepartureCodes: fucntion, listTitle: string,
//openList: boolean, delayCodes: delayCode[], removeDelayCode: function, value: state, 

const DelayCodes = ({
    delayCodes,
    addCode,
    removeCode,
    idComercial
}) => {

    const [visibleForm, setVisibleForm] = useState(false);
    const [isListOpen, setIsListOpen] = useState(false);
    const [actualCode, setActualCode] = useState({
        cod_iata_category: 0,
        codigo: "",
        descripcion: "",
        cod_comercial: 0,
        cod_iata_code: 0,
        delay_time: "00:00",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setActualCode((prevState) => ({
            ...prevState,
            [name]: value,
            cod_comercial: idComercial,
        }));
    };

    const onAddCode = (actualCode) => {
        setActualCode({ delay_time: "00:00" });
        addCode(actualCode);
    }

    useEffect(() => {
        setActualCode({
            cod_iata_category: actualCode.cod_iata_category,
            codigo: 0,
            descripcion: "",
            cod_comercial: 0,
            cod_iata_code: 0,
            delay_time: "00:00"
        });
    }, [actualCode.cod_iata_category]);

    return (
        <>
            {
                !visibleForm ?
                    <>
                        <List>
                            <ListItemButton
                                disableGutters
                                onClick={() => { setIsListOpen(!isListOpen) }}
                            >
                                <ListItemText
                                    primary="Departure delay codes"
                                    primaryTypographyProps={{ fontSize: "111%", fontWeight: "bold" }}
                                />
                                {isListOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={isListOpen} timeout="auto" unmountOnExit>
                                <List disablePadding>
                                    {
                                        delayCodes.map((value, key) => {
                                            return (
                                                <ListItem
                                                    key={key}
                                                    secondaryAction={
                                                        <IconButton
                                                            onClick={() => removeCode(key)}
                                                        >
                                                            <DeleteForeverOutlinedIcon
                                                                color="error"
                                                                fontSize="large"
                                                            />
                                                        </IconButton>
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemText
                                                            primary={`Delay code: ${value.codigo}, delay time: ${value.delay_time}`}
                                                            secondary={null}
                                                            primaryTypographyProps={{ fontSize: "100%" }}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })
                                    }
                                    <ListItemButton
                                        disabled={delayCodes.length >= 3}
                                        onClick={() => { setVisibleForm(!visibleForm) }}
                                    >
                                        <ListItemIcon>
                                            <AddIcon
                                                color="primary"
                                                fontSize="large"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={delayCodes.length >= 3 ? "Code limit reached" : "Add new code"}
                                            primaryTypographyProps={{ fontSize: "100%" }}
                                        />
                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </List>
                    </>
                    :
                    <div>
                        <>
                            <div className="campo-form">
                                <label>Delay code category</label>
                                <Combobox
                                    url="/categorias"
                                    oName="cod_iata_category"
                                    property="category"
                                    value="id_iata_category"
                                    handleChange={handleChange}
                                    actualValue={actualCode.cod_iata_category === 0 ? '0' : parseInt(
                                        actualCode.cod_iata_category
                                    )}
                                />
                            </div>

                            <div className="campo-form">
                                <label>Delay code</label>
                                <Combobox
                                    url={`/categorias/${actualCode.cod_iata_category}/codigos`}
                                    oName="cod_iata_code"
                                    property="codigo"
                                    value="id_iata_code"
                                    handleChange={handleChange}
                                    desabilitar={actualCode.cod_iata_category === '0'}
                                    isIATACode={true}
                                    actualValue={actualCode.cod_iata_code === 0 ? '0' : parseInt(
                                        actualCode.cod_iata_code
                                    )}
                                />
                            </div>

                            <div className="campo-form">
                                <label>Delay Time</label>
                                <input
                                    type="time"
                                    name="delay_time"
                                    onChange={handleChange}
                                    value={actualCode.delay_time}
                                    disabled={actualCode.cod_iata_code === '0' || actualCode.cod_iata_code === 0}
                                    style={{ width: "40%" }}
                                />

                            </div>

                            <div className="campo-form">
                                <input
                                    type="button"
                                    className="btn btn-primario btn-block"
                                    value={
                                        actualCode.code_iata_code === 0 || actualCode.code_iata_code === '0' ?
                                            "Select a code to add" : "Add Code"
                                    }

                                    disabled={
                                        actualCode.code_iata_code === 0 || actualCode.code_iata_code === '0'
                                    }
                                    onClick={() => { onAddCode(actualCode); setVisibleForm(false) }}
                                />

                                <input
                                    type="button"
                                    className="btn btn-primario btn-block"
                                    value="Cancel"
                                    onClick={() => { setVisibleForm(false) }}
                                />

                            </div>
                        </>
                    </div>
            }
        </>
    );
};

export default DelayCodes;