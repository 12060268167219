import { getCellStyle, colors, getFont, getFill } from "../cellstyle.util";
import "moment-duration-format";

const comercialReport = (data) => {
	return [
		{
			columns: styleComercialHeaders(),
			data: styleComercialData(data),
		},
	];
};

const styleComercialHeaders = () => {
	const headerStyle = getCellStyle(
		getFont(undefined, true, undefined),
		getFill(colors["azulClaro"])
	);
	return [
		{ title: "#", width: { wpx: 100 }, style: headerStyle },
		{ title: "DATE", width: { wpx: 100 }, style: headerStyle },
		{ title: "OPERATION", width: { wpx: 100 }, style: headerStyle },
		{ title: "CUSTOMER", width: { wpx: 100 }, style: headerStyle },
		{ title: "ORIGIN", width: { wpx: 100 }, style: headerStyle },
		{ title: "DESTINATION", width: { wpx: 100 }, style: headerStyle },
		{ title: "ROUTE", width: { wpx: 100 }, style: headerStyle },
		{ title: "POSITION", width: { wpx: 100 }, style: headerStyle },
		{ title: "STD", width: { wpx: 100 }, style: headerStyle },
		{ title: "STA", width: { wpx: 100 }, style: headerStyle },
		{ title: "AIRCRAFT", width: { wpx: 100 }, style: headerStyle },
		{ title: "CHARGEABLE WEIGHT", width: { wpx: 100 }, style: headerStyle },
		{ title: "STATE", width: { wpx: 100 }, style: headerStyle },
		{ title: "REMARKS", width: { wpx: 100 }, style: headerStyle },
		{ title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
	];
};

const styleComercialData = (data) => {
	//const numberStyle = getCellStyle(undefined, undefined, undefined, 1);
	return data.map((data) => [
		{
			value: data.id_comercial ? data.id_comercial : "",
			style: getCellStyle(),
		},
		{
			value: data.fecha ? data.fecha.split("T")[0] : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_operacion ? data.cod_operacion : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_cliente ? data.cod_cliente : "",
			style: getCellStyle(),
		},
		{
			value: data.ciudad_origen ? data.ciudad_origen : "",
			style: getCellStyle(),
		},
		{
			value: data.ciudad_destino ? data.ciudad_destino : "",
			style: getCellStyle(),
		},
		{
			value: data.ruta ? data.ruta : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_posicion ? data.nombre_posicion : "",
			style: getCellStyle(),
		},
		{
			value: data.departure_prog ? data.departure_prog : "",
			style: getCellStyle(),
		},
		{
			value: data.arrival_prog ? data.arrival_prog : "",
			style: getCellStyle(),
		},
		{
			value: data.cod_equipo_programado ? data.cod_equipo_programado : "",
			style: getCellStyle(),
		},
		{
			value: data.peso_comercial ? data.peso_comercial : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_estado ? data.nombre_estado : "",
			style: getCellStyle(),
		},
		{
			value: data.observaciones ? data.observaciones : "",
			style: getCellStyle(),
		},

		{ value: "" },
	]);
};

export { comercialReport };
