import { useEffect, useState, Fragment } from "react";
import moment from "moment";
import http from "../utils/Http";
import Modal from "../utils/Modal";

const Availability = () => {
	const [maintenances, setMaintenances] = useState([]);
	const [date, setDate] = useState(moment().format("yyyy-MM"));
	const daysOfMonth = parseInt(moment(date).daysInMonth());
	const [validteMaintenances, setValidateMaintenances] = useState([]);

	const colors = {
		base: "#a9d08e",
		sch: "#c7570f",
		usch: "#a6a6a6",
		landingGear: "#fb0000",
		engine: "#001f5c",
		apu: "#bf8f00",
		aircraft: "#a9d08e",
	};

	// Se carga nueva data la primera vez o cada ves qeu cambia
	useEffect(() => {
		const loadData = async () => {
			try {
				const monthBegin = moment(date).startOf("month");
				const monthEnd = moment(date).endOf("month");
				const data = await http.get("/availability", {
					params: {
						startDate: date,
					},
				});
				setMaintenances(data.data);

				const data2 = await http.get("/reporte/mantenimiento", {
					params: {
						startDate: monthBegin.format("yyyy-MM-DD"),
						endDate: monthEnd.format("yyyy-MM-DD"),
					},
				});

				setValidateMaintenances(data2.data);
			} catch (error) {
				console.log(error);
			}
		};

		loadData();
	}, [date]);

	let ac = [];

	// const calcularHorasTotal = (data, startDate, endDate) => {
	// 	// fecha consulta
	// 	const fechaConsultaInicial = moment(`${startDate}T00:00`);
	// 	const fechaConsultaFinal = moment(`${endDate}T23:59`);
	// 	// fecha del mantenimiento
	// 	const fechaInicial = moment(`${data.date_in}T${data.hour_in}`);
	// 	const fechaFinal = moment(`${data.date_out}T${data.hour_out}`);

	// 	let bool1 = false;
	// 	let bool2 = false;

	// 	let answer;

	// 	if (fechaInicial.clone().diff(fechaConsultaInicial, "m") >= 0) {
	// 		// booleano 1
	// 		bool1 = true;
	// 	}

	// 	if (fechaFinal.clone().diff(fechaConsultaFinal, "m") >= 0) {
	// 		// booleano 1
	// 		bool2 = true;
	// 	}

	// 	if (bool1 && bool2) {
	// 		// Caso 3
	// 		answer = fechaConsultaFinal.clone().diff(fechaInicial, "m");
	// 	} else if (!bool1 && bool2) {
	// 		// Caso 2
	// 		answer = fechaConsultaFinal.clone().diff(fechaConsultaInicial, "m");
	// 	} else if (bool1 && !bool2) {
	// 		// caso 1
	// 		answer = fechaFinal.clone().diff(fechaInicial, "m");
	// 	} else {
	// 		// caso 4
	// 		answer = fechaFinal.clone().diff(fechaConsultaInicial, "m");
	// 	}

	// 	return Math.round((answer / 60) * 100) / 100; // devuelvo la diferencia del total y la suma de los estremos
	// };

	const calculateAC = () => {
		// const monthBegin = moment(date).startOf("month");
		// const monthEnd = moment(date).endOf("month");
		let totalMaintenance = 0.0;
		// const limite = parseInt(monthEnd.format("DD")) * 24;
		let countAirplanes = 0;

		maintenances.forEach((item) => {
			// // Se almacena las horas para cada avion
			// let horaTmp = 0;
			// item.maintenance?.forEach((manto) => {
			// 	horaTmp = calcularHorasTotal(
			// 		manto,
			// 		monthBegin.format("yyyy-MM-DD"),
			// 		monthEnd.format("yyyy-MM-DD")
			// 	);
			// });

			// // Se suman las horas de mantenimiento de cada avion a las del mantenimiento total
			// if (horaTmp >= limite) {
			// 	totalMaintenance += limite;
			// } else {
			// 	totalMaintenance += horaTmp;
			// }
			countAirplanes++;
			// horaTmp = 0;
		});

		validteMaintenances.forEach((item) => {
			totalMaintenance += item.totalHoras;
		});

		const totalMes = countAirplanes * 24 * 31;
		const libresMes = totalMes - totalMaintenance;

		// console.log(maintenances);
		// console.log("Total mantenimiento = ", totalMaintenance);
		// console.log("Total de aviones = ", countAirplanes);
		return ((libresMes / totalMes) * countAirplanes).toFixed(2);
	};

	const dayOfMonth = () => {
		const days = [];

		const monthBegin = moment(date).startOf("month");
		const monthEnd = moment(date).endOf("month");

		for (var m = moment(monthBegin); m.isBefore(monthEnd); m.add(1, "days")) {
			days.push(
				<th key={m.format("D")}>
					<table>
						<thead>
							<tr>
								<th>{m.format("ddd")}</th>
							</tr>
							<tr>
								<th>
									<p style={{ fontSize: 20 }}>{m.format("DD")}</p>
								</th>
							</tr>
						</thead>
					</table>
				</th>
			);
		}
		return days;
	};

	function RowMaintenance({ manto, tail }) {
		const [open, setOpen] = useState(false);
		const [modalContent, setModalContent] = useState({});
		let days = [];

		const monthBegin = moment(date).startOf("month");
		const monthEnd = moment(date).endOf("month");
		let column;

		function popUp(mt) {
			console.log(mt);
			setOpen(true);
			setModalContent({
				date_in: mt?.date_in,
				hour_in: mt?.hour_in,
				date_out: mt?.date_out,
				hour_out: mt?.hour_out,
				remarks: mt?.remarks,
				tipo_mantenimiento: mt?.tipo_mantenimiento,
				nombre_subMantenimiento: mt?.nombre_subMantenimiento,
				id: mt?.id_mantenimiento,
				hk: mt?.nombre_matricula_avion,
				update: mt?.update_date,
			});
		}

		//days = loop(monthBegin, monthEnd, manto, setOpen, setModalContent);
		for (let m = moment(monthBegin); m.isBefore(monthEnd); m.add(1, "days")) {
			let fondo = colors.base;

			column = <td key={m.format("D")} style={{ backgroundColor: fondo }}></td>;

			const tempArr = manto.filter((testItem) => {
				//
				let columnDate = m.format("YYYY-MM-DD"); // 2022-06-06

				//[date_in: 2022-06-06 hour_out: 13:00, date_in :2022-06-06   hour_in:  14:00 ]
				// date_in 2022-06-05
				// date_oiut 2022-06-10
				if (columnDate >= testItem.date_in && columnDate <= testItem.date_out) {
					return true;
				}

				return false;
			});

			if (tempArr.length > 0) {
				let temp = {};
				if (tempArr.length === 1) {
					temp = tempArr[0];
				} else {
					// get last value
					let first = tempArr[0];
					let second = tempArr[1];

					if (first.hour_out <= second.hour_in) {
						temp = second;
					} else {
						temp = first;
					}
				}

				fondo =
					temp?.nombre_subMantenimiento === null
						? temp?.tipo_mantenimiento === "Scheduled"
							? colors.sch
							: colors.usch
						: temp?.nombre_subMantenimiento === "Landing Gear"
						? colors.landingGear
						: temp?.nombre_subMantenimiento === "Engine"
						? colors.engine
						: temp?.nombre_subMantenimiento === "APU"
						? colors.apu
						: temp?.nombre_subMantenimiento === "Aircraft" &&
						  temp?.tipo_mantenimiento === "Scheduled"
						? colors.sch
						: colors.usch;

				column = (
					<td
						key={m.format("D")}
						style={{ backgroundColor: fondo }}
						onClick={() => popUp(temp)}
					></td>
				);

				ac = [...ac, temp];
			} else {
				column = (
					<td key={m.format("D")} style={{ backgroundColor: fondo }}></td>
				);
			}

			days.push(column);
		}

		return (
			<>
				<Modal
					data={modalContent}
					open={open}
					handleClose={() => {
						setOpen(false);
					}}
					type={"availability"}
				/>
				{days}
			</>
		);

		//return days;
	}

	const cerrarSesion = () => {
		window.localStorage.clear();
	};

	const absolute = {
		position: "fixed",
		background: "#003783",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		top: 0,
		left: 0,
		right: 0,
		height: 75,
		zIndex: 1000,
	};

	return (
		<Fragment>
			<div style={absolute}>
				<p style={{ color: "#fff", fontSize: 55, marginLeft: "200px" }}>
					Fleet Availability
				</p>
				<a
					href="/"
					onClick={cerrarSesion}
					style={{ marginLeft: "200px", color: "#fff" }}
				>
					Sign out
				</a>
			</div>
			<div style={{ height: 80 }}></div>

			<div style={{ marginLeft: "10vh" }}>
				<h4 style={{ marginTop: "40px" }}>Pick a Month:</h4>

				<input
					type="month"
					onChange={(e) => {
						setDate(e.target.value);
					}}
					style={{ marginTop: 10 }}
				/>
			</div>
			<div style={{ display: "flex" }}>
				<div
					style={{
						marginLeft: "10vh",
						marginTop: "20px",
					}}
				>
					<table>
						<thead>
							<tr>
								<th
									style={{
										backgroundColor: colors.base,
										color: "#fff",
										minWidth: 115,
									}}
								>
									Available
								</th>
								<th
									style={{
										backgroundColor: colors.sch,
										color: "#fff",
										minWidth: 115,
									}}
								>
									SCH
								</th>
								<th
									style={{
										backgroundColor: colors.landingGear,
										color: "#fff",
										minWidth: 115,
									}}
								>
									Landing Gear
								</th>
								<th
									style={{
										backgroundColor: colors.engine,
										color: "#fff",
										minWidth: 115,
									}}
								>
									Engine
								</th>
								<th
									style={{
										backgroundColor: colors.apu,
										color: "#fff",
										minWidth: 115,
									}}
								>
									APU
								</th>
								<th
									style={{
										backgroundColor: colors.usch,
										color: "#fff",
										minWidth: 115,
									}}
								>
									{" "}
									USCH
								</th>
							</tr>
						</thead>
					</table>
				</div>
				<div
					style={{
						width: 100,
						height: 100,
						backgroundColor: "#98bbe3",
						marginLeft: "20%",
						fontSize: 22,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					SC {calculateAC()}
				</div>
			</div>
			<div style={{ marginTop: "4vh", marginLeft: "10vh" }}>
				<table border="1">
					<thead>
						<tr>
							<th colSpan={daysOfMonth + 2}>{moment(date).format("MMMM")} </th>
						</tr>
						<tr>
							<th rowSpan={2}>Tail</th>
							<th rowSpan={2}>Fleet</th>
							{dayOfMonth()}
						</tr>
						<tr></tr>
					</thead>
					<tbody>
						{maintenances?.map((manto, index) => {
							return (
								<tr key={index}>
									<td style={{ minWidth: 90 }}>{manto.tail}</td>
									<td style={{ minWidth: 90 }}>{manto.tipo}</td>
									<RowMaintenance manto={manto.maintenance} tail={manto.tail} />
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

export default Availability;

// 	let result;
// 	let tmp = manto.find(
// 		(element) =>
// 			element.date_in === m.format("YYYY-MM-DD") ||
// 			element.date_out === m.format("YYYY-MM-DD")
// 	);
// 	// verifica si algun mantenimiento menor al inicio de mes
// 	const before = manto.find(
// 		(mantenimiento) =>
// 			mantenimiento.date_in < monthBegin.format("YYYY-MM-DD")
// 	);
// 	// Verifico si estoy en la primera columna
// 	if (
// 		before &&
// 		m.format("YYYY-MM-DD") === monthBegin.format("YYYY-MM-DD")
// 	) {
// 		//Calcula la diferencia en dias
// 		const diferenciaDias = diferencia(before.date_in, before.date_out);
// 		console.log("antes", diferenciaDias);
// 		// Verifica el tamaño del mes. si lo sobrepasa.
// 		let space =
// 			diferenciaDias > daysOfMonth
// 				? diferencia(m.format("YYYY-MM-DD"), before.date_out)
// 				: diferenciaDias;
// 		console.log("Debo pintar");
// 		console.log(before.date_in);
// 		console.log(m.format("YYYY-MM-DD"));
// 		console.log("span:", space);
// 		mantener = before;
// 		space = m.format("YYYY-MM-DD") === before.date_out ? 1 : space + 1;
// 		columns = space;
// 		result = (
// 			<td
// 				key={m.format("D")}
// 				colSpan={space}
// 				style={{ textAlign: "center" }}
// 			>
// 				{before.tipo_mantenimiento}
// 			</td>
// 		);
// 	}
// 	// Si el dia en el que estoy parado hay un mantenimiento
// 	else if (tmp) {
// 		//Calcula la diferencia en dias
// 		const diferenciaDias = diferencia(tmp.date_in, tmp.date_out);
// 		// Verifica el tamaño del mes. si lo sobrepasa.
// 		let space =
// 			diferenciaDias > daysOfMonth
// 				? diferencia(m.format("YYYY-MM-DD"), monthEnd.format("YYYY-MM-DD"))
// 				: diferenciaDias;
// 		// Verfico si es la enrtrada.
// 		if (m.format("YYYY-MM-DD") === tmp.date_in) {
// 			mantener = tmp;
// 			columns = diferenciaDias === 0 ? columns + 1 : columns + space + 1;
// 			// colocar el span
// 			result = (
// 				<td
// 					key={m.format("D")}
// 					colSpan={1 + space}
// 					style={{ textAlign: "center" }}
// 				>
// 					{tmp.tipo_mantenimiento}
// 				</td>
// 			);
// 		}
// 		// Verifico si es la salida.
// 		if (m.format("YYYY-MM-DD") === tmp.date_out) {
// 			mantener = null;
// 		}
// 	} else {
// 		if (mantener && isInside(mantener.date_in, mantener.date_out, m)) {
// 			result = null;
// 		} else {
// 			mantener = null;
// 			result = <td key={m.format("D")}></td>;
// 			columns = columns + 1;
// 		}
// 	}
// 	days.push(result);
// 	console.log("Columnas:", columns);
// 	if (columns >= daysOfMonth) {
// 		break;
// 	}
