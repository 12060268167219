import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox' } };

export default function Checkboxes({value,handleCheck,oname}) {
  return (
    <>
      {/* <Checkbox {...label} defaultChecked /> */}
      <Checkbox 
        sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
        name={oname}
        checked={value}
        onChange={handleCheck}
        {...label} 
      />
      {/* <Checkbox {...label} disabled />
      <Checkbox {...label} disabled checked /> */}
    </>
  );
}
