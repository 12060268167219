import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";
import { createTheme, ThemeProvider } from "@material-ui/core";
// import "bootstrap/dist/css/bootstrap.min.css";

import RecuperarContraseña from "./components/RecuperarContraseña";
import WrapAuthComponent from "./components/hoc/WrapAuthComponent";
import Login from "./components/Login";
import Main from "./components/Main";
import NuevoVuelo from "./components/vuelo/NuevoVuelo";
import Operaciones from "./components/operaciones_vuelo/Operaciones";
import PlanificacionFinanciera from "./components/planificacion_financiera/PlanificacionFinanciera";
import Despacho from "./components/despacho/Despacho";
import EditarPlanificacion from "./components/planificacion_financiera/EditarDepartamentos";
import Programacion from "./components/programacion/Programacion";
import CompletarComercial from "./components/comercial/CompletarComercial";
import VerProgramacion from "./components/programacion/VerProgramacion";
import VerDespacho from "./components/despacho/VerDespacho";
import Combustible from "./components/combustible/Combustible";
import Itinerario from "./components/Las_watch/Itinerario";
import CompletarProgramacion from "./components/programacion/Complete_programacion";
import VerCombustible from "./components/combustible/VerCombustible";
import Mantenimiento from "./components/mantenimiento/Mantenimiento";
import Trm from "./components/planificacion_financiera/Trm";
import ReporteMayor from "./components/planificacion_financiera/ReporteMayorRange";
import DespachoCuenta from "./components/despacho/DespachoCuenta";
import Reagendar from "./components/mantenimiento/Reagendar";
import VerMantenimiento from "./components/mantenimiento/VerMantenimiento";
import VerFlightOperations from "./components/programacion/VerFlightOperations";
import VerPcp from "./components/programacion/VerPcp";
import Movimiento from "./components/despacho/Movimiento";
import Nomina from "./components/nomina/Nomina";
import Gestion from "./components/gestion/gestion";
import ItinerarioComercial from "./components/Itinerario/ItinerarioComercial";
import CreateItinerario from "./components/Itinerario/CreateItinerario";
import CompletarItinerario from "./components/Itinerario/CompletarItinerario";
import Availability from "./components/mantenimiento/availability";

const theme = createTheme({
	palette: {
		primary: {
			main: "#117504",
		},
		secondary: {
			main: "#bf0615",
		},
	},
});

const App = () => {
	//const [rol,setRol] = useState(null);

	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Switch>
					<Route exact path="/" component={Login} />
					<Route
						exact
						path="/recuperar-contraseña"
						component={RecuperarContraseña}
					/>
					<Route exact path="/main" component={WrapAuthComponent(Main)} />
					<Route
						exact
						path="/NuevoVuelo"
						component={WrapAuthComponent(NuevoVuelo)}
					/>
					<Route
						exact
						path="/CompletarComercial"
						component={WrapAuthComponent(CompletarComercial)}
					/>
					<Route
						exact
						path="/operaciones_vuelo"
						component={WrapAuthComponent(Operaciones)}
					/>
					<Route
						exact
						path="/planificacion_financiera"
						component={WrapAuthComponent(PlanificacionFinanciera)}
					/>
					<Route
						exact
						path="/editar_planificacion_financiera"
						component={WrapAuthComponent(EditarPlanificacion)}
					/>
					<Route
						exact
						path="/Despacho"
						component={WrapAuthComponent(Despacho)}
					/>
					<Route
						exact
						path="/Programacion"
						component={WrapAuthComponent(Programacion)}
					/>
					<Route
						exact
						path="/VerProgramacion"
						component={WrapAuthComponent(VerProgramacion)}
					/>
					<Route
						exact
						path="/VerDespacho"
						component={WrapAuthComponent(VerDespacho)}
					/>
					<Route
						exact
						path="/combustible"
						component={WrapAuthComponent(Combustible)}
					/>
					<Route
						exact
						path="/verCombustible"
						component={WrapAuthComponent(VerCombustible)}
					/>
					<Route
						exact
						path="/itinerario"
						component={WrapAuthComponent(Itinerario)}
					/>
					<Route
						exact
						path="/completarProgramacion"
						component={WrapAuthComponent(CompletarProgramacion)}
					/>
					<Route
						exact
						path="/mantenimiento"
						component={WrapAuthComponent(Mantenimiento)}
					/>
					<Route exact path="/trm" component={WrapAuthComponent(Trm)} />
					<Route
						exact
						path="/pickRange"
						component={WrapAuthComponent(ReporteMayor)}
					/>
					<Route
						exact
						path="/despachocuenta"
						component={WrapAuthComponent(DespachoCuenta)}
					/>
					<Route
						exact
						path="/reagendar"
						component={WrapAuthComponent(Reagendar)}
					/>
					<Route
						exact
						path="/verMantenimiento"
						component={WrapAuthComponent(VerMantenimiento)}
					/>
					<Route
						exact
						path="/verflitghoperations"
						component={WrapAuthComponent(VerFlightOperations)}
					/>
					<Route exact path="/ver-pcp" component={WrapAuthComponent(VerPcp)} />
					<Route
						exact
						path="/movimiento"
						component={WrapAuthComponent(Movimiento)}
					/>
					<Route exact path="/nomina" component={WrapAuthComponent(Nomina)} />
					<Route exact path="/gestion" component={WrapAuthComponent(Gestion)} />
					<Route
						exact
						path="/itinerarioComercial"
						component={WrapAuthComponent(ItinerarioComercial)}
					/>
					<Route
						exact
						path="/createItinerario"
						component={WrapAuthComponent(CreateItinerario)}
					/>
					<Route
						exact
						path="/completarItinerario"
						component={WrapAuthComponent(CompletarItinerario)}
					/>
					<Route
						exact
						path="/availability"
						component={WrapAuthComponent(Availability)}
					/>
				</Switch>
			</Router>
		</ThemeProvider>
	);
};

export default App;
