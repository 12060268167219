import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Combobox from "../utils/Combobox";
import http from "../utils/Http";

const IngresarUsuario = () => {
    const [usuario, setUsuario] = useState({
        cod_rol: "",
        email: "",
        contrasena: "",
        nombre: "",
    });

    const MySwal = withReactContent(Swal);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setUsuario({
            ...usuario,
            [name]: value,
        });
    };

    const { cod_rol, email, contrasena, nombre } = usuario;

    const insertUser = () => {
        if (
            cod_rol === "" ||
            email === "" ||
            contrasena === "" ||
            nombre === ""
        ) {
            MySwal.fire({
                title: <p style={{ fontSize: "200%" }}>Warning</p>,
                html: (
                    <p style={{ fontSize: "200%" }}>There are empty fields</p>
                ),
                icon: "warning",
                confirmButtonText: "Try again",
            });
            return;
        }

        http.post("/setUsuario", usuario)
            .then((response) => {
                if (response.data.insert) {
                    MySwal.fire({
                        title: <p style={{ fontSize: "200%" }}>Success</p>,
                        html: (
                            <p style={{ fontSize: "200%" }}>
                                Succesfully created
                            </p>
                        ),
                        icon: "success",
                        confirmButtonText: "Continue",
                    });
                    setUsuario({
                        cod_rol: "",
                        email: "",
                        contrasena: "",
                        nombre: "",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                MySwal.fire({
                    title: <p style={{ fontSize: "200%" }}>Error</p>,
                    html: <p style={{ fontSize: "200%" }}>Failed update: email already exist</p>,
                    icon: "error",
                    confirmButtonText: "Try again",
                });
            });
    };

    return (
        <>
            <h1>Usuario</h1>
            <Box
                sx={{
                    "& > :not(style)": { m: 1, width: "25ch" },
                }}
            >
                <TextField
                    label="Email"
                    name="email"
                    variant="standard"
                    onChange={handleChange}
                    value={usuario.email}
                    InputLabelProps={{
                        style: { fontSize: 18, fontWeight: "bold" },
                    }}
                    InputProps={{
                        style: {
                            fontSize: 16,
                        },
                    }}
                />
                <TextField
                    label="Password"
                    name="contrasena"
                    type="password"
                    variant="standard"
                    onChange={handleChange}
                    value={usuario.contrasena}
                    InputLabelProps={{
                        style: { fontSize: 18, fontWeight: "bold" },
                    }}
                    InputProps={{
                        style: {
                            fontSize: 16,
                        },
                    }}
                />
                <TextField
                    label="Nombre"
                    name="nombre"
                    variant="standard"
                    onChange={handleChange}
                    value={usuario.nombre}
                    InputLabelProps={{
                        style: { fontSize: 18, fontWeight: "bold" },
                    }}
                    InputProps={{
                        style: {
                            fontSize: 16,
                        },
                    }}
                />
                <Combobox
                    url="/getRol"
                    oName="cod_rol"
                    property="nombre"
                    value="id_rol"
                    handleChange={handleChange}
                />

                <Button variant="contained" onClick={insertUser}>
                    Ingresar
                </Button>
            </Box>
        </>
    );
};

export default IngresarUsuario;
