import React, { Fragment, useState, useEffect } from "react";
import http from "../utils/Http";
import { Redirect, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Button from "@material-ui/core/Button";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const ListarVuelo = () => {
	const [vuelos, setVuelos] = useState(null);
	const [nuevoVuelo, agregarNuevoVuelo] = useState(false);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);
	const [rows, setRows] = useState(0);

	const [query, setQuery] = useState("");

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Se obtiene el rol del usuario
	const local = JSON.parse(window.localStorage.getItem("datos"));

	const { rol } = local;

	let llenar = "";
	let ver = "";

	if (rol === 1) {
		llenar = "/CompletarComercial";
	} else if (rol === 2) {
		llenar = "/Programacion";
		ver = "/VerProgramacion";
	} else if (rol === 3) {
		llenar = "/Despacho";
		ver = "/VerDespacho";
	} else if (rol === 4) {
		llenar = "/operaciones_vuelo";
		ver = "/VerDespacho";
	} else if (rol === 5) {
		llenar = "/planificacion_financiera";
		ver = "/VerPlanificacion";
	} else if (rol === 6) {
		llenar = "/combustible";
		ver = "/verCombustible";
	} else if (rol === 8) {
		llenar = "/completarProgramacion";
		ver = "/VerProgramacion";
	}

	const validarLog = rol === 8;

	// El minimo tamaño que tendra la tabla
	const useStyles = makeStyles({
		table: {
			minWidth: 650,
			fontSize: "200pt",
		},
	});

	const classes = useStyles();

	// Se opbtiene los vuelos desde el Api
	useEffect(() => {
		http
			.get("/listar")
			.then((response) => {
				setVuelos(response.data);
				setRows(response.data.length);
				//console.log(response.data);
			})
			.catch((error) => {
				console.log("es del error desde lista");
				console.log(error);
			});
	}, []);

	const formatFecha = (fecha) => {
		const result = fecha.split("T")[0];
		return result;
	};

	const search = (rows) => {
		return rows.filter(
			(row) =>
				row.id_comercial.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.fecha.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.cod_operacion.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.cod_cliente.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.ciudad_origen.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.ciudad_destino.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.ruta.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.nombre_posicion.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.departure_prog.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.arrival_prog.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.cod_equipo_programado.toLowerCase().indexOf(query.toLowerCase()) >
					-1 ||
				row.peso_comercial
					.toString()
					.toLowerCase()
					.indexOf(query.toLowerCase()) > -1 ||
				row.nombre_estado.toLowerCase().indexOf(query.toLowerCase()) > -1
		);
	};

	const validarInformacion = (row) => {
		switch (rol) {
			case 2:
				if (row.id_programacion) {
					return true;
				} else {
					return false;
				}

			case 3:
				if (row.id_Despacho) {
					return true;
				} else {
					return false;
				}
			case 5:
				if (row.id_planificacion_financiera) {
					return true;
				} else {
					return false;
				}
			case 6:
				if (row.id_combustible) {
					return true;
				} else {
					return false;
				}
			case 8:
				if (row.concecutivo_vuelo) {
					return true;
				} else {
					return false;
				}

			default:
				break;
		}
	};

	return (
		<Fragment>
			{rol === 1 ? (
				<input
					type="submit"
					value="New Flight"
					onClick={() => agregarNuevoVuelo(true)}
				/>
			) : null}

			{nuevoVuelo ? <Redirect to={"/NuevoVuelo"} /> : ""}
			<div className="alinear">
				<label style={{ marginRight: 10 }}>Search:</label>
				<input
					type="text"
					value={query}
					onChange={(e) => setQuery(e.target.value)}
				/>
			</div>
			<div align="center" style={{ display: "inline", marginLeft: 15 }}>
				<ReactHTMLTableToExcel
					id="botonExportarExcel"
					table="vuelos"
					filename="vuelos"
					sheet="pagina 1"
					buttonText="Export Excel"
				/>
			</div>

			<Table className={classes.table} id="vuelos">
				<TableHead>
					<TableRow>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							#
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Date
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Operation
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Customer
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Origin
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Destination
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Route
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Position
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							STD
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							STA
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Aircraft
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Chargeable weight
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							State
						</TableCell>
						<TableCell align="center" style={{ fontSize: "14px" }}>
							Remarks
						</TableCell>
						{validarLog ? (
							<TableCell align="center" style={{ fontSize: "14px" }}>
								Log Page
							</TableCell>
						) : null}

						<TableCell align="center" style={{ fontSize: "14px" }}></TableCell>
						{rol === 5 ? (
							<TableCell
								align="center"
								style={{ fontSize: "14px" }}
							></TableCell>
						) : null}
					</TableRow>
				</TableHead>
				{vuelos ? (
					<TableBody>
						{search(vuelos)
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => (
								<TableRow key={row.id_comercial}>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.id_comercial}
									</TableCell>

									<TableCell align="center" style={{ fontSize: "14px" }}>
										{formatFecha(row.fecha)}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.cod_operacion}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.cod_cliente}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.ciudad_origen}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.ciudad_destino}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.ruta}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.nombre_posicion}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.departure_prog}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.arrival_prog}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.cod_equipo_programado}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.peso_comercial}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.nombre_estado}
									</TableCell>
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{row.observaciones}
									</TableCell>
									{validarLog ? (
										<TableCell align="center" style={{ fontSize: "14px" }}>
											{row.logPage}
										</TableCell>
									) : null}
									<TableCell align="center" style={{ fontSize: "14px" }}>
										{rol === 5 ? null : (
											<Button
												variant="contained"
												component={Link}
												color={
													rol === 1
														? "inherit"
														: validarInformacion(row)
														? "primary"
														: "secondary"
												}
												style={{ width: 75 }}
												to={{
													pathname: llenar,
													vuelo: row,
												}}
											>
												Fill out
											</Button>
										)}
									</TableCell>
									{rol === 4 ||
									rol === 3 ||
									rol === 2 ||
									rol === 8 ||
									rol === 6 ? (
										<TableCell align="center" style={{ fontSize: "14px" }}>
											<Button
												variant="contained"
												component={Link}
												style={{
													width: 75,
													background: "#2a24ed",
													color: "#fff",
												}}
												to={{
													pathname: ver,
													vuelo: row,
												}}
											>
												{" "}
												View{" "}
											</Button>
										</TableCell>
									) : null}
									{rol === 3 ? (
										<TableCell align="center" style={{ fontSize: "14px" }}>
											<Button
												variant="contained"
												component={Link}
												to={{
													pathname: "despachocuenta",
													vuelo: row,
												}}
											>
												{" "}
												Value{" "}
											</Button>
										</TableCell>
									) : null}
									{rol === 3 ? (
										<TableCell align="center" style={{ fontSize: "14px" }}>
											<Button
												variant="contained"
												component={Link}
												style={{
													background: "#fcdb00",
												}}
												endIcon={<MailOutlineIcon />}
												to={{
													pathname: "movimiento",
													vuelo: row,
												}}
											>
												MVT
											</Button>
										</TableCell>
									) : null}
								</TableRow>
							))}
					</TableBody>
				) : null}
			</Table>

			<TablePagination
				component="div"
				count={rows}
				page={page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				sx={
					{
						// fontSize: 18,
						// '.MuiTablePagination-selectLabel': {
						//     fontSize: 18
						// },
						// '.MuiTablePagination-displayedRows': {
						//     fontSize: 18
						// },
						// '.MuiTablePagination-actions': {
						//     fontSize: "large"
						// }
					}
				}
				nextIconButtonProps={{
					color: "primary",
					size: "large",
				}}
			/>
		</Fragment>
	);
};

export default ListarVuelo;
