import React, { Fragment, useState, useEffect } from "react";
import Tabla from "../utils/Table";
import { Redirect } from "react-router-dom";
import Combobox from "../utils/Combobox";
import http from "../utils/Http";

const VerDespacho = ({ location }) => {
    const vuelo = location.vuelo;

    const [redirect, setRedirect] = useState(false);

    const [despacho, setDespacho] = useState({
        id_despacho: 0,
        cod_comercial: 0,
        cod_provedor_combustible: 0,
        cod_matricula_avion: 0,
        codigo_demora_salida: "",
        codigo_demora_llegada: "",
        observaciones: "",
        numero_vuelo: "",
        landing_weight: 0,
        cg: 0,
        departure_time: "",
        arrival_time: "",
        payload: 0,
        ticket: 0,
        basic_fuel: 0,
        alternate_fuel: 0,
        holding_fuel: 0,
        contingency: 0,
        xtr_fuel: 0,
        taxi_fuel: 0,
        combustible_taxeo_real: 0,
        actual_ramp_fuel: 0,
        takeoff_fuel: 0,
        landing_fuel: 0,
        valor_factura: 0,
        pallets: 0,
        who_made_takeoff: "",
        who_made_landing: "",
        addfuel: 0
    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setDespacho((prevState) => ({
            ...prevState,
            [name]: value,
            cod_comercial: vuelo.id_comercial,
        }));
    };

    useEffect(() => {
        const id = vuelo.id_comercial;
        http.get(`/despacho/${id}`)
            .then((response) => {
                if (response.data.length > 0) {
                    setDespacho(response.data[0]);
                    //console.log(response.data[0]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [vuelo.id_comercial]);

    return (
        <Fragment>
            {redirect ? <Redirect to={"/main"} /> : ""}

            <div>
                <div>
                    <h1>Dispatch</h1>

                    <Tabla vuelo={vuelo} />
                </div>

                <div className="contenedor-tareas">
                    <div className="form-usuario6">
                        <div className="contenedor-form sombra-dark">
                            <div className="campo-form">
                                <label>
                                    Tail <span className="required">*</span>
                                </label>

                                <Combobox
                                    url="/matricula_avion"
                                    oName="cod_matricula_avion"
                                    property="nombre_matricula_avion"
                                    value="id_matricula_avion"
                                    handleChange={handleChange}
                                    actualValue={parseInt(despacho.cod_matricula_avion)}
                                    desabilitar={true}
                                />
                            </div>

                            <div className="campo-form">
                                <label>
                                    Flight number{" "}
                                    <span className="required">*</span>
                                </label>
                                <input
                                    type="text"
                                    name="numero_vuelo"
                                    onChange={handleChange}
                                    value={despacho.numero_vuelo}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Actual Departure time</label>
                                <input
                                    type="time"
                                    name="departure_time"
                                    value={despacho.departure_time}
                                    onChange={handleChange}
                                    style={{ width: "40%" }}
                                    disabled
                                />
                                <label style={{ marginLeft: 10 }}>UTC</label>
                            </div>

                            <div className="campo-form">
                                <label>Departure delay time</label>
                                <input
                                    type="text"
                                    name="codigo_demora_llegada"
                                    onChange={handleChange}
                                    value={despacho.codigo_demora_llegada}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Departure delay code</label>
                                <input
                                    type="text"
                                    name="codigo_demora_salida"
                                    onChange={handleChange}
                                    value={despacho.codigo_demora_salida}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label> Remarks </label>
                                <textarea
                                    name="observaciones"
                                    onChange={handleChange}
                                    value={despacho.observaciones}
                                    disabled
                                ></textarea>
                            </div>

                            <div className="campo-form">
                                <label>Landing Weight</label>
                                <input
                                    type="number"
                                    name="landing_weight"
                                    onChange={handleChange}
                                    value={despacho.landing_weight}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>CG% Mac TakeOff </label>
                                <input
                                    type="number"
                                    name="cg"
                                    onChange={handleChange}
                                    value={despacho.cg}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Payload</label>
                                <input
                                    type="number"
                                    name="payload"
                                    onChange={handleChange}
                                    value={despacho.payload}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Pallets</label>
                                <input
                                    type="number"
                                    name="pallets"
                                    onChange={handleChange}
                                    value={despacho.pallets}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Basic Fuel</label>
                                <input
                                    type="number"
                                    name="basic_fuel"
                                    onChange={handleChange}
                                    value={despacho.basic_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Alternate fuel</label>
                                <input
                                    type="number"
                                    name="alternate_fuel"
                                    onChange={handleChange}
                                    value={despacho.alternate_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label> Holding Fuel</label>
                                <input
                                    type="number"
                                    name="holding_fuel"
                                    onChange={handleChange}
                                    value={despacho.holding_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Contingecy Fuel </label>
                                <input
                                    type="number"
                                    name="contingecy"
                                    onChange={handleChange}
                                    value={despacho.contingency}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>XTR Fuel </label>
                                <input
                                    type="number"
                                    name="xtr_fuel"
                                    onChange={handleChange}
                                    value={despacho.xtr_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label> Taxi Fuel </label>
                                <input
                                    type="number"
                                    name="taxi_fuel"
                                    onChange={handleChange}
                                    value={despacho.taxi_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label> Actual Ramp </label>
                                <input
                                    type="number"
                                    name="actual_ramp_fuel"
                                    onChange={handleChange}
                                    value={despacho.actual_ramp_fuel}
                                    disabled
                                />
                            </div>


                            <div className="campo-form">
                                <label>TakeOff Fuel </label>
                                <input
                                    type="number"
                                    name="takeoff_fuel"
                                    onChange={handleChange}
                                    value={despacho.takeoff_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Real taxi fuel </label>
                                <input
                                    type="number"
                                    name="combustible_taxeo_real"
                                    onChange={handleChange}
                                    value={despacho.actual_ramp_fuel - despacho.takeoff_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Landing Fuel </label>
                                <input
                                    type="number"
                                    name="landing_fuel"
                                    onChange={handleChange}
                                    value={despacho.landing_fuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Ticket</label>
                                <input
                                    type="number"
                                    name="ticket"
                                    onChange={handleChange}
                                    value={despacho.ticket}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Fuel Suplier</label>

                                <Combobox
                                    url="/provedor_combustible"
                                    oName="cod_provedor_combustible"
                                    property="nombre_provedor"
                                    value="id_provedor_combustible"
                                    handleChange={handleChange}
                                    actualValue={parseInt(despacho.cod_provedor_combustible)}
                                    desabilitar={true}
                                />
                            </div>

                            <div className="campo-form">
                                <label>Tank Fuelling</label>
                                <input
                                    type="number"
                                    name="addfuel"
                                    onChange={handleChange}
                                    value={despacho.addfuel === null ? 0 : despacho.addfuel}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Who TakeOff</label>
                                <input
                                    type="text"
                                    name="who_made_takeoff"
                                    onChange={handleChange}
                                    value={despacho.who_made_takeoff}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Who Landed</label>
                                <input
                                    type="text"
                                    name="who_made_landing"
                                    onChange={handleChange}
                                    value={despacho.who_made_landing}
                                    disabled
                                />
                            </div>

                            <div className="campo-form">
                                <label>Actual Arrival time</label>
                                <input
                                    type="time"
                                    name="arrival_time"
                                    value={despacho.arrival_time}
                                    onChange={handleChange}
                                    style={{ width: "40%" }}
                                    disabled
                                />
                                <label style={{ marginLeft: 10 }}>UTC</label>
                            </div>

                            <center>
                                <input
                                    type="button"
                                    className="btn btn-primario btn-block"
                                    value="Back"
                                    onClick={() => setRedirect(true)}
                                />
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default VerDespacho;
