import axios from "axios";

export default axios.create({
	baseURL: "https://do-microservices-eastus2-007-prod.azurewebsites.net",
	timeout: 100000,
	headers: {
		"Content-type": "application/json",
	},
});

// Desarrollo: http://localhost:5000
// Producion: https://do-microservices-eastus2-007-prod.azurewebsites.net
// otro QA : https://do-microservices-eastus2-007-qa.azurewebsites.net
