import React from 'react';
import Comercial from '../comercial/Comercial';

const NuevoVuelo = () => {
    return (
        //<h1>Desde el NuevoVuelo</h1>
        <Comercial />
      );
}
 
export default NuevoVuelo;