import React, { Fragment, useEffect, useState } from "react";
import Tabla from "../utils/Table";
import { Redirect } from "react-router-dom";
import http from "../utils/Http";
import Combobox from "../utils/Combobox";
import DelayCodes from "./delayCodes";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Check from "../utils/CheckBox";
import ToolTip from "../utils/ToolTip";

const Despacho = ({ location }) => {
	const MySwal = withReactContent(Swal);
	const local = JSON.parse(window.localStorage.getItem("datos"));
	const vuelo = location.vuelo;
	console.log(vuelo);
	const [redirect, setRedirect] = useState(false);
	const [actualizar, setActualizar] = useState(false);
	const [despacho, setDespacho] = useState({
		id_despacho: 0,
		cod_comercial: 0,
		cod_provedor_combustible: 3,
		cod_matricula_avion: 0,
		observaciones: "",
		numero_vuelo: "",
		landing_weight: 0,
		cg: 0,
		departure_time: "",
		arrival_time: "",
		payload: null,
		ticket: null,
		basic_fuel: 0,
		alternate_fuel: 0,
		holding_fuel: 0,
		contingency: 0,
		xtr_fuel: 0,
		taxi_fuel: 0,
		combustible_taxeo_real: 0,
		actual_ramp_fuel: 0,
		takeoff_fuel: 0,
		landing_fuel: 0,
		valor_factura: 0,
		pallets: null,
		who_made_takeoff: "",
		who_made_landing: "",
		addfuel: null,
		categoria_demora: 0,
		cod_iata_code_salida: 0,
		takeoff: "",
		eta: "",
		cod_estado: vuelo.cod_estado,
		dayplusATD: false,
		dayminusATD: false,
		departureMessage: null,
		tankering: null,
		ballast: null,
		ticket2: null,
	});

	const comercial = {
		id_comercial: vuelo.id_comercial,
		cod_matricula_avion: despacho.cod_matricula_avion,
		cod_estado: despacho.cod_estado,
	};

	const { cod_comercial, cod_matricula_avion, numero_vuelo } = despacho;

	const handleChange = (e) => {
		const { name, value } = e.target;

		setDespacho((prevState) => ({
			...prevState,
			[name]: value,
			cod_comercial: vuelo.id_comercial,
		}));
	};

	const handleCheck = (e) => {
		setDespacho({
			...despacho,
			[e.target.name]: e.target.checked,
		});
	};

	const onSubmit = (e) => {
		e.preventDefault();

		const data = { data: [comercial, local] };

		if (
			cod_comercial.toString() === "0" ||
			cod_matricula_avion.toString() === "0" ||
			numero_vuelo === ""
		) {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: <p style={{ fontSize: "200%" }}>All fields are required</p>,
				icon: "warning",
				confirmButtonText: "Try again",
			});
			return;
		}

		if (actualizar) {
			// Se hace la petición al end point para actualizar
			http
				.put("/despacho", despacho)
				.then((response) => {
					if (response.data.insert) {
						http
							.put("/comercial", data)
							.then((response) => {
								if (response.data.insert) {
									MySwal.fire({
										title: <p style={{ fontSize: "200%" }}>Success</p>,
										html: (
											<p style={{ fontSize: "200%" }}>Succesfully updated</p>
										),
										icon: "success",
										confirmButtonText: "Continue",
									})
										.then(() => {
											setRedirect(true);
										})
										.catch(() => {
											console.log("IDK how this would be possible");
										});
								}
							})
							.catch((error) => {
								console.log("no se pudo actualizar comercial.", { error });
							});
					}
				})
				.catch((error) => {
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: <p style={{ fontSize: "200%" }}>Failed update</p>,
						icon: "error",
						confirmButtonText: "Try again",
					});
					console.log(error);
				});
		} else {
			// Despues de que pasen todas las validaciones redirecionar
			http
				.post("/despacho", despacho)
				.then((response) => {
					http
						.put("/comercial", data)
						.then((response) => {
							if (response.data.insert) {
								MySwal.fire({
									title: <p style={{ fontSize: "200%" }}>Success</p>,
									html: <p style={{ fontSize: "200%" }}>Succesfully updated</p>,
									icon: "success",
									confirmButtonText: "Continue",
								})
									.then(() => {
										setRedirect(true);
									})
									.catch(() => {
										console.log("IDK how this would be possible");
									});
							}
						})
						.catch((error) => {
							console.log("no se pudo actualizar comercial.", {
								error,
							});
						});
				})
				.catch((error) => {
					console.log({ error });
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: <p style={{ fontSize: "200%" }}>Failed update</p>,
						icon: "error",
						confirmButtonText: "Try again",
					});
				});
		}

		//Nota: Hacer la petición aunque no tenga ningún código que añadir, en el caso de que se eliminen los que habían

		//Nota 2: Pensar en hacer una validación en la que solo se haga una petición vacía cuando no haya ningún código
		//ej.: if(get "departureCodes".length === 0) //No hacer put si no se añaden más códigos de salida
		//Put inserta y actualiza

		if (departureDelayCodes.length === 0) {
			http
				.put("/departure_delay_codes", [{ cod_comercial: vuelo.id_comercial }])
				.then(/*do nothing*/)
				.catch((error) => {
					console.error(error);
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Error updating departure delay codes
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
				});
		} else {
			http
				.put("/departure_delay_codes", departureDelayCodes)
				.then(/*Do nothing*/)
				.catch((error) => {
					console.error(error);
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Error updating departure delay codes
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
				});
		}

		if (arrivalDelayCodes.length === 0) {
			http
				.put("/arrival_delay_codes", [{ cod_comercial: vuelo.id_comercial }])
				.then(/*Do nothing.*/)
				.catch((error) => {
					console.error(error);
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Error updating arrival delay codes
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
				});
		} else {
			//Put inserta y actualiza
			http
				.put("/arrival_delay_codes", arrivalDelayCodes)
				.then(/*Do nothing*/)
				.catch((error) => {
					console.error(error);
					MySwal.fire({
						title: <p style={{ fontSize: "200%" }}>Error</p>,
						html: (
							<p style={{ fontSize: "200%" }}>
								Error updating arrival delay codes
							</p>
						),
						icon: "error",
						confirmButtonText: "Try again",
					});
				});
		}
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				const result = await http.get(`/despachoraw/${vuelo.id_comercial}`);
				if (result.data.length !== 0) {
					setDespacho(result.data[0]);
					setDespacho((d) => {
						return {
							...d,
							cod_matricula_avion: vuelo.cod_matricula_avion,
						};
					});
					setActualizar(true);

					try {
						const result = await http.get(
							`/comercial/${vuelo.id_comercial}/departure_delay_codes`
						);

						if (result.data.length !== 0) {
							setDepartureDelayCodes(result.data[0]);
						}
					} catch (error) {
						console.log(error);
					}

					try {
						const result = await http.get(
							`/comercial/${vuelo.id_comercial}/arrival_delay_codes`
						);

						if (result.data.length !== 0) {
							setArrivalDelayCodes(result.data[0]);
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					setDespacho((d) => {
						return {
							...d,
							cod_matricula_avion: vuelo.cod_matricula_avion,
						};
					});
				}
			} catch (error) {
				console.log(error);
			}
		};

		loadData();
	}, [vuelo.id_comercial, vuelo.cod_matricula_avion]);

	useEffect(() => {
		if (despacho.cod_matricula_avion === "4") {
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Warning</p>,
				html: (
					<p style={{ fontSize: "200%" }}>
						Asegurarse que la base tenga arrancador neumático por problemas de
						APU.
					</p>
				),
				icon: "warning",
				confirmButtonText: "Ok",
			});
		}
	}, [despacho.cod_matricula_avion]);

	const [departureDelayCodes, setDepartureDelayCodes] = useState([]);
	const [arrivalDelayCodes, setArrivalDelayCodes] = useState([]);

	const addDepartureCode = (actualCode) => {
		const existingCodesArray = departureDelayCodes.map(
			(value) => value.cod_iata_code
		);
		const actualCodeArray = [actualCode];

		const mergedCodes = [
			...departureDelayCodes,
			...actualCodeArray.filter(
				(c) => !existingCodesArray.includes(c.cod_iata_code)
			),
		];
		setDepartureDelayCodes(mergedCodes);
	};

	const removeDepartureCode = (key) => {
		//Remove item by its key
		const filteredArray = departureDelayCodes.filter((element, index) => {
			return index !== key;
		});
		setDepartureDelayCodes(filteredArray);
	};

	const addArrivalCode = (actualCode) => {
		const existingCodesArray = arrivalDelayCodes.map(
			(value) => value.cod_iata_code
		);
		const actualCodeArray = [actualCode];

		const mergedCodes = [
			...arrivalDelayCodes,
			...actualCodeArray.filter(
				(c) => !existingCodesArray.includes(c.cod_iata_code)
			),
		];
		setArrivalDelayCodes(mergedCodes);
	};

	const removeArrivalCode = (key) => {
		//Remove item by its key
		const filteredArray = arrivalDelayCodes.filter((element, index) => {
			return index !== key;
		});
		setArrivalDelayCodes(filteredArray);
	};

	return (
		<Fragment>
			{redirect ? <Redirect to={"/main"} /> : ""}

			<div className="contenedor-app">
				<div className="seccion-principal">
					<h1>Dispatch</h1>

					<Tabla vuelo={vuelo} />

					<div className="contenedor-tareas">
						<div className="form-usuario6">
							<div className="contenedor-form sombra-dark">
								<form onSubmit={onSubmit}>
									<div className="campo-form">
										<label>
											Tail <span className="required">*</span>
										</label>

										<Combobox
											url="/matricula_avion"
											oName="cod_matricula_avion"
											property="nombre_matricula_avion"
											value="id_matricula_avion"
											// params={vuelo.cod_equipo_programado}
											handleChange={handleChange}
											actualValue={
												despacho.cod_matricula_avion === 0
													? "0"
													: parseInt(despacho.cod_matricula_avion)
											}
										/>
									</div>

									<div className="campo-form">
										<label>
											Flight number <span className="required">*</span>
										</label>
										<input
											type="text"
											name="numero_vuelo"
											onChange={handleChange}
											value={despacho.numero_vuelo}
											required
										/>
									</div>

									<div className="campo-form">
										<label>ATD</label>
										<input
											type="time"
											name="departure_time"
											value={despacho.departure_time}
											onChange={handleChange}
											style={{ width: "40%" }}
										/>

										<table>
											<tr>
												<td>
													{" "}
													<label style={{ marginLeft: 10 }}>UTC</label>
												</td>
												<td>
													{" "}
													<label style={{ marginLeft: 17 }}>+</label>
													<Check
														oname="dayplusATD"
														value={despacho.dayplusATD}
														handleCheck={handleCheck}
													/>
												</td>
												<td>
													<label style={{ marginLeft: 17 }}> - </label>
													<Check
														oname="dayminusATD"
														value={despacho.dayminusATD}
														handleCheck={handleCheck}
													/>
												</td>
												<td>
													<ToolTip mensaje="Seleccionar si toca sumarle o restarle un dia a la fecha actual." />
												</td>
											</tr>
										</table>
									</div>

									<div className="campo-form">
										<label>Take Off</label>
										<input
											type="time"
											name="takeoff"
											value={despacho.takeoff}
											onChange={handleChange}
											style={{ width: "40%" }}
										/>
										<label style={{ marginLeft: 10 }}>UTC</label>
									</div>

									<div className="campo-form">
										<label>ETA</label>
										<input
											type="time"
											name="eta"
											value={despacho.eta}
											onChange={handleChange}
											style={{ width: "40%" }}
										/>
										<label style={{ marginLeft: 10 }}>UTC</label>
									</div>

									<div className="campo-form">
										<label> State</label>
										<Combobox
											url="/estado"
											oName="cod_estado"
											property="nombre_estado"
											value="id_estado"
											handleChange={handleChange}
											actualValue={
												despacho.cod_estado === 4
													? "4"
													: parseInt(despacho.cod_estado)
											}
										/>
									</div>

									<DelayCodes
										delayCodes={departureDelayCodes}
										addCode={addDepartureCode}
										removeCode={removeDepartureCode}
										idComercial={vuelo.id_comercial}
									/>

									<DelayCodes
										delayCodes={arrivalDelayCodes}
										addCode={addArrivalCode}
										removeCode={removeArrivalCode}
										idComercial={vuelo.id_comercial}
									/>

									<div className="campo-form">
										<label>Landing Weight</label>
										<input
											type="number"
											name="landing_weight"
											onChange={handleChange}
											value={despacho.landing_weight}
										/>
									</div>

									<div className="campo-form">
										<label>CG% Mac TakeOff </label>
										<input
											type="number"
											name="cg"
											onChange={handleChange}
											value={despacho.cg}
										/>
									</div>

									<div className="campo-form">
										<label>Payload</label>
										<input
											type="number"
											name="payload"
											onChange={handleChange}
											value={despacho.payload}
										/>
									</div>

									<div className="campo-form">
										<label>Pallets</label>
										<input
											type="number"
											name="pallets"
											onChange={handleChange}
											value={despacho.pallets}
										/>
									</div>

									<div className="campo-form">
										<label>Basic Fuel</label>
										<input
											type="number"
											name="basic_fuel"
											onChange={handleChange}
											value={despacho.basic_fuel}
										/>
									</div>

									<div className="campo-form">
										<label>Alternate fuel</label>
										<input
											type="number"
											name="alternate_fuel"
											onChange={handleChange}
											value={despacho.alternate_fuel}
										/>
									</div>

									<div className="campo-form">
										<label> Holding Fuel</label>
										<input
											type="number"
											name="holding_fuel"
											onChange={handleChange}
											value={despacho.holding_fuel}
										/>
									</div>

									<div className="campo-form">
										<label>Contingecy Fuel </label>
										<input
											type="number"
											name="contingency"
											onChange={handleChange}
											value={despacho.contingency}
										/>
									</div>

									<div className="campo-form">
										<label>XTR Fuel </label>
										<input
											type="number"
											name="xtr_fuel"
											onChange={handleChange}
											value={despacho.xtr_fuel}
										/>
									</div>

									<div className="campo-form">
										<label> Taxi Fuel </label>
										<input
											type="number"
											name="taxi_fuel"
											onChange={handleChange}
											value={despacho.taxi_fuel}
										/>
									</div>

									<div className="campo-form">
										<label> Actual Ramp </label>
										<input
											type="number"
											name="actual_ramp_fuel"
											onChange={handleChange}
											value={despacho.actual_ramp_fuel}
										/>
									</div>

									<div className="campo-form">
										<label>TakeOff Fuel </label>
										<input
											type="number"
											name="takeoff_fuel"
											onChange={handleChange}
											value={despacho.takeoff_fuel}
										/>
									</div>

									<div className="campo-form">
										<label>Landing Fuel </label>
										<input
											type="number"
											name="landing_fuel"
											onChange={handleChange}
											value={despacho.landing_fuel}
										/>
									</div>

									<div className="campo-form">
										<label>Ticket</label>
										<input
											type="number"
											name="ticket"
											onChange={handleChange}
											value={despacho.ticket}
										/>
									</div>

									<div className="campo-form">
										<label>Ticket 2</label>
										<input
											type="number"
											name="ticket2"
											onChange={handleChange}
											value={despacho.ticket2}
										/>
									</div>

									<div className="campo-form">
										<label>Fuel Suplier</label>

										<Combobox
											url="/provedor_combustible"
											oName="cod_provedor_combustible"
											property="nombre_provedor"
											value="id_provedor_combustible"
											handleChange={handleChange}
											actualValue={
												despacho.cod_provedor_combustible === 3
													? "0"
													: parseInt(despacho.cod_provedor_combustible)
											}
										/>
									</div>

									<div className="campo-form">
										<label>Tank Fuelling</label>
										<input
											type="number"
											name="addfuel"
											onChange={handleChange}
											value={despacho.addfuel}
										/>
									</div>

									<div className="campo-form">
										<label>Tankering</label>
										<input
											type="number"
											name="tankering"
											onChange={handleChange}
											value={despacho.tankering}
										/>
									</div>

									<div className="campo-form">
										<label>Ballast</label>
										<input
											type="number"
											name="ballast"
											onChange={handleChange}
											value={despacho.ballast}
										/>
									</div>

									<div className="campo-form">
										<label>Who TakeOff</label>
										<input
											type="text"
											name="who_made_takeoff"
											onChange={handleChange}
											value={despacho.who_made_takeoff}
										/>
									</div>

									<div className="campo-form">
										<label>Who Landed</label>
										<input
											type="text"
											name="who_made_landing"
											onChange={handleChange}
											value={despacho.who_made_landing}
										/>
									</div>

									<div className="campo-form">
										<label>ATA</label>
										<input
											type="time"
											name="arrival_time"
											value={despacho.arrival_time}
											onChange={handleChange}
											style={{ width: "40%" }}
										/>
										<label style={{ marginLeft: 10 }}>UTC</label>
									</div>

									<div className="campo-form">
										<input
											type="submit"
											className="btn btn-primario btn-block"
											value="Save"
										/>
									</div>
								</form>

								<div className="campo-form">
									<input
										type="button"
										className="btn btn-primario btn-block"
										value="Cancel"
										onClick={() => setRedirect(true)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Despacho;
