import React, { useState } from "react";
import http from '../utils/Http';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const Trm = ({ setIngresarTrm }) => {
    const [trm, setTrm] = useState({
        fecha: new Date().toISOString().split("T")[0],
        trm_pesos: "",
    });

    const handleChange = (e) => {
        setTrm({
            ...trm,
            [e.target.name]: e.target.value,
        });
    };

    const saveData = () => {
        if (trm.fecha === "" || trm.trm_pesos === "") {
            MySwal.fire({
                title: <p style={{ fontSize: '200%' }}>Warning</p>,
                html: <p style={{ fontSize: '200%' }}>Both date and TRM are required</p>,
                icon: 'warning',
                confirmButtonText: 'Try again'
            });
            return;
        }

        http.post("/api/trm", trm)
            .then((response) => {
                if (response.data.insert) {
                    MySwal.fire({
                        title: <p style={{ fontSize: '200%' }}>Success</p>,
                        html: <p style={{ fontSize: '200%' }}>Succesfully updated</p>,
                        icon: 'success',
                        confirmButtonText: 'Continue'
                    }).then(() => {
                        setIngresarTrm(false);
                    }).catch(() => {
                        console.log("IDK how this would be possible");
                    });
                }
            })
            .catch((error) => {
                console.log({ error });
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Error</p>,
                    html: <p style={{ fontSize: '200%' }}>Failed update</p>,
                    icon: 'error',
                    confirmButtonText: 'Try again'
                });
            });
    }

    return (
        <div>
            <h2> Enter TRM </h2>
            <center>
                <label>Date:</label>
                <input
                    type="date"
                    name="fecha"
                    onChange={handleChange}
                    value={trm.fecha}
                    style={{ display: "block", margin: "10px 0px" }}
                />
                <label style={{ display: "block", marginBottom: 10 }}>
                    TRM:
                </label>
                <input type="number" name="trm_pesos" onChange={handleChange} value={trm.trm_pesos} />

                <input
                    className="btn btn-primario btn-half"
                    type="button"
                    value="save"
                    onClick={saveData}
                />
            </center>
        </div>
    );
};

export default Trm;
