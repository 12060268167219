import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { Redirect } from "react-router-dom";

import Barra from "../layout/Barra";
import Sidebar from "../layout/Sidebar";
import ListaItinerario from "./ListaItinerario";
import http from ".././utils/Http";

const ItinerarioComercial = () => {
	// Estate to validate if new itinerary is goin to be added
	const [newItinerary, setNewItinerary] = useState(false);
	const [word, setWord] = useState("");
	const [listaItinerario, setListaItinerario] = useState(null);

	const search = (rows) => {
		const query = word;
		return rows?.filter(
			(row) =>
				row?.id_itinerario.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.fecha.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.cod_operacion.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.cod_cliente.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.ciudad_origen.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.ciudad_destino.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.ruta.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.cod_equipo_programado.toLowerCase().indexOf(query.toLowerCase()) >
					-1 ||
				row.nombre_matricula_avion.toLowerCase().indexOf(query.toLowerCase()) >
					-1 ||
				row.departure_prog.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.arrival_prog.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.titulo_antiguedad.toLowerCase().indexOf(query.toLowerCase()) > -1 ||
				row.observaciones.toLowerCase().indexOf(query.toLowerCase()) > -1
		);
	};

	useEffect(() => {
		const loadData = async () => {
			try {
				const itinerarios = await http.get("/listaItinerario");
				setListaItinerario(itinerarios.data);
			} catch (error) {
				console.log({ error });
			}
		};

		loadData();
	}, []);

	return (
		<div className="contenedor-app">
			{newItinerary && <Redirect to={"/createItinerario"} />}
			<Sidebar />
			<div className="seccion-principal">
				<Barra />

				<main>
					<div className="contenedor-tareas">
						<Button
							variant="contained"
							size="medium"
							onClick={() => setNewItinerary(true)}
							color="inherit"
						>
							New Itinerary
						</Button>

						<div className="alinear">
							<label style={{ marginRight: 10 }}>Search:</label>
							<input
								type="text"
								onChange={(e) => setWord(e.target.value)}
								value={word}
							/>
						</div>

						<ListaItinerario listaItinerario={search(listaItinerario)} />
					</div>
				</main>
			</div>
		</div>
	);
};

export default ItinerarioComercial;
