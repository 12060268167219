import React, { useState } from "react";
import http from "../utils/Http";
import getTripulacionDataset from '../utils/Reports/reporteTripulacion';
import DateRange from "../utils/DateRange";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ReporteTripulacion = () => {
    const [dateRange, setRange] = useState({
        fechaInicio: new Date().toISOString().split("T")[0],
        fechaFinal: new Date().toISOString().split("T")[0],
    });

    const [tripulacion, setTripulacion] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileReady, setIsFileReady] = useState(false);

    const handleChange = (e) => {
        setRange({
            ...dateRange,
            [e.target.name]: e.target.value,
        });
    };



    const search = async () => {
        try {
            setIsLoading(true);
            const tripulacion = await http.get("/reporte/tripulacion", {
                params: {
                    fechaInicio: dateRange.fechaInicio,
                    fechaFin: dateRange.fechaFinal
                }
            });


            if (tripulacion.data.length === 0) {
                MySwal.fire({
                    title: <p style={{ fontSize: '200%' }}>Info</p>,
                    html: <p style={{ fontSize: '200%' }}>No such data for selected range</p>,
                    icon: 'info',
                    confirmButtonText: 'Try again'
                });
            } else {
                setTripulacion(getTripulacionDataset(tripulacion.data));
                setIsFileReady(true);
                setTimeout(() => { setIsFileReady(false) }, 1000);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            MySwal.fire({
                title: <p style={{ fontSize: '200%' }}>Error</p>,
                html: <p style={{ fontSize: '200%' }}>An error has occurred</p>,
                icon: 'error',
                confirmButtonText: 'Try again'
            });
            console.log({ error });
        }
    };

    return (
        <div>
            <DateRange
                value={dateRange}
                handleChange={handleChange}
                title={"Crew report"}
                datasets={[{ dataset: tripulacion, sheetTitle: "Base" }]}
                fileReady={isFileReady}
                loading={isLoading}
                search={search}
            />
        </div>
    );
};

export default ReporteTripulacion;
