import React, { useState } from "react";
import Trm from "../planificacion_financiera/Trm";
import ReporteMayor from "../planificacion_financiera/ReporteMayorRange";
import ReporteCombustibleRange from "../combustible/ReporteCombustibleRange";
import ReporteTripulacion from "../operaciones_vuelo/ReporteTripulacion";
import ReporteBH from "../nomina/Nomina";
import ReporteItinerario from "../Itinerario/ReporteItinerario";
import ReporteComercial from "../comercial/ReporteComercial";
import ReporteBitacora from "../utils/Reports/ReporteBitacora";

const Sidebar = () => {
	const local = JSON.parse(window.localStorage.getItem("datos"));

	const { rol } = local;

	const [ingresartrm, setIngresarTrm] = useState(false);
	const [ingresarRango, setIngresarRango] = useState(false);
	const [ingresarRangoFuel, setIngresarRangoFuel] = useState(false);
	const [itineraryReport, setItineraryReport] = useState(false);
	const [ingresarRangoTripulacion, setIngresarRangoTripulacion] =
		useState(false);
	const [ingresarRangoHB, setIngresarRangoHB] = useState(false);
	const [logBook, setlogBook] = useState(false);

	return (
		<aside>
			<h1>Las Fly</h1>

			<div className="proyectos">
				<h2>Options</h2>
			</div>

			{rol === 5 ? (
				<div>
					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Generate report"
							onClick={() => {
								setIngresarRango(true);
								setIngresarTrm(false);
							}}
						/>
					</div>

					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Set TRM"
							onClick={() => {
								setIngresarTrm(true);
								setIngresarRango(false);
							}}
						/>
					</div>

					{ingresartrm ? (
						<div style={{ marginTop: 50 }}>
							<Trm setIngresarTrm={setIngresarTrm} />
						</div>
					) : null}

					{ingresarRango ? (
						<div style={{ marginTop: 50 }}>
							<ReporteMayor />
						</div>
					) : null}
				</div>
			) : rol === 6 ? (
				<>
					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Generate tankering report"
							onClick={() => {
								setIngresarRangoTripulacion(false);
								setIngresarRangoFuel(true);
							}}
						/>
					</div>

					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Generate crew report"
							onClick={() => {
								setIngresarRangoTripulacion(true);
								setIngresarRangoFuel(false);
							}}
						/>
					</div>

					{ingresarRangoFuel ? (
						<div style={{ marginTop: 50 }}>
							<ReporteCombustibleRange />
						</div>
					) : null}

					{ingresarRangoTripulacion ? (
						<div style={{ marginTop: 50 }}>
							<ReporteTripulacion />
						</div>
					) : null}
				</>
			) : rol === 2 ? (
				<>
					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="BH Report"
							onClick={() => {
								setIngresarRangoTripulacion(false);
								setlogBook(false);
								setIngresarRangoHB(true);
							}}
						/>
					</div>

					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Generate crew report"
							onClick={() => {
								setIngresarRangoTripulacion(true);
								setIngresarRangoHB(false);
								setlogBook(false);
							}}
						/>
					</div>

					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Generate LogBook"
							onClick={() => {
								setIngresarRangoTripulacion(false);
								setIngresarRangoHB(false);
								setlogBook(true);
							}}
						/>
					</div>

					{ingresarRangoHB ? (
						<div style={{ marginTop: 50 }}>
							<ReporteBH />
						</div>
					) : null}

					{ingresarRangoTripulacion ? (
						<div style={{ marginTop: 50 }}>
							<ReporteTripulacion />
						</div>
					) : null}

					{logBook ? (
						<div style={{ marginTop: 50 }}>
							<ReporteBitacora />
						</div>
					) : null}
				</>
			) : rol === 11 || rol === 3 ? (
				<>
					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Itinerary Report"
							onClick={() => {
								setItineraryReport(true);
							}}
						/>
					</div>

					{itineraryReport ? (
						<div style={{ marginTop: 50 }}>
							<ReporteItinerario />
						</div>
					) : null}
				</>
			) : rol === 1 ? (
				<>
					<div>
						<input
							className="btn btn-primario btn-block"
							type="button"
							value="Comercial Report"
							onClick={() => {
								setItineraryReport(true);
							}}
						/>
					</div>

					{itineraryReport ? (
						<div style={{ marginTop: 50 }}>
							<ReporteComercial />
						</div>
					) : null}
				</>
			) : null}
		</aside>
	);
};

export default Sidebar;
