import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import http from "../utils/Http";
import { maintenanceSheet } from "../utils/Reports/maintenance";
import DateRange from "../utils/DateRange";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";

const MySwal = withReactContent(Swal);

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "max-content",
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	border: "1px solid #000",
	borderRadius: 3,
};

const Report = ({ open, handleClose }) => {
	const [dateRange, setRange] = useState({
		fechaInicio: new Date().toISOString().split("T")[0],
		fechaFinal: new Date().toISOString().split("T")[0],
	});

	const [dataSet, setDataSet] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isFileReady, setIsFileReady] = useState(false);

	const handleChange = (e) => {
		setRange({
			...dateRange,
			[e.target.name]: e.target.value,
		});
	};

	const search = async () => {
		try {
			setIsLoading(true);
			const tmp = await http.get("/reporte/mantenimiento", {
				params: {
					startDate: dateRange.fechaInicio,
					endDate: dateRange.fechaFinal,
				},
			});

			if (tmp.data.length === 0) {
				MySwal.fire({
					title: <p style={{ fontSize: "200%" }}>Info</p>,
					html: (
						<p style={{ fontSize: "200%" }}>No such data for selected range</p>
					),
					icon: "info",
					confirmButtonText: "Try again",
				});
			} else {
				setDataSet(maintenanceSheet(tmp.data));
				setIsFileReady(true);
				setTimeout(() => {
					setIsFileReady(false);
				}, 1000);
			}

			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			MySwal.fire({
				title: <p style={{ fontSize: "200%" }}>Error</p>,
				html: <p style={{ fontSize: "200%" }}>An error has occurred</p>,
				icon: "error",
				confirmButtonText: "Try again",
			});
			console.log(error);
		}
	};

	return (
		<Modal open={open} onClose={handleClose} sx={{ zIndex: 1000 }}>
			<Box sx={style}>
				<IconButton
					sx={{ position: "absolute", right: 10, top: 10 }}
					onClick={handleClose}
				>
					<CloseOutlinedIcon color="error" fontSize="large" />
				</IconButton>

				<DateRange
					value={dateRange}
					handleChange={handleChange}
					disable={isLoading || isFileReady}
					title={"Maintenance Report"}
					datasets={[{ dataset: dataSet, sheetTitle: "Maintenance" }]}
					fileReady={isFileReady}
					loading={isLoading}
					search={search}
				/>
			</Box>
		</Modal>
	);
};

export default Report;
