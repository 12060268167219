import React from 'react';

const EditarDepartamentos = () => {
    return ( 
        <div>
            <h1>Edicion de los departamentos</h1>
            
            <center>

                <img src="https://upload.wikimedia.org/wikipedia/commons/1/1e/SITIO-EN-CONSTRUCCION.jpg" alt="Site on construction"></img>

            </center>

        </div>

     );
}
 
export default EditarDepartamentos;