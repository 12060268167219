/**
 * Describe el estilo y los datos del reporte de la tripulación de los vuelos
 */

import { getCellStyle, colors, getFont, getFill } from "../cellstyle.util";
import moment from "moment";
import "moment-duration-format";

const getRawSheet = (data, data2) => {
	return [
		{
			columns: styleRawHeaders(),
			data: styleRawData(data),
		},
	];
};

const corregirData = (tripulante) => {
	// Se obtienen los tripulantes con la denominacion (IVA)
	const retirarTripulante = tripulante.filter((piloto) =>
		piloto.nombre.includes("(IVA)")
	);

	console.log("Remover data");
	// Removerlos de la data
	retirarTripulante.forEach((piloto) => {
		const pilotoTemp = tripulante.find(
			(element) => element.nombre === piloto.nombre
		);
		const posicion = tripulante.indexOf(pilotoTemp);
		tripulante.splice(posicion, 1);
	});

	// Encontrar los que se removieron y sumarles las nuevas horas
	retirarTripulante.forEach((piloto) => {
		const pilotoTemp = tripulante.find(
			(element) => element.cedula === piloto.cedula
		);
		const posicion = tripulante.indexOf(pilotoTemp);
		if (posicion > 0) {
			// horas bloque
			const horasTotal = moment.duration(pilotoTemp?.horaBloque);
			const horasSumar = moment.duration(piloto.horaBloque);
			const tmp1 = horasTotal.add(horasSumar).format("hh:mm");
			tripulante[posicion].horaBloque = tmp1.length === 2 ? `00:${tmp1}` : tmp1;

			// hora extra dia
			const horasExtraDia = moment.duration(pilotoTemp.horaExtraDia);
			const horasSumarExtraDia = moment.duration(piloto.horaExtraDia);
			const tmp2 = horasExtraDia.add(horasSumarExtraDia).format("hh:mm");
			tripulante[posicion].horaExtraDia =
				tmp2.length === 2 ? `00:${tmp2}` : tmp2;

			// hora extra noche
			const horaExtraNoche = moment.duration(pilotoTemp.horaExtraNoche);
			const horasSumarExtraNoche = moment.duration(piloto.horaExtraNoche);
			const tmp3 = horaExtraNoche.add(horasSumarExtraNoche).format("hh:mm");
			tripulante[posicion].horaExtraNoche =
				tmp3.length === 2 ? `00:${tmp3}` : tmp3;

			// hora nocturna
			const HorasNocturna = moment.duration(pilotoTemp.horaNocturna);
			const horasSumarNocturna = moment.duration(piloto.horaNocturna);
			const tmp4 = HorasNocturna.add(horasSumarNocturna).format("hh:mm");
			tripulante[posicion].horaNocturna =
				tmp4.length === 2 ? `00:${tmp4}` : tmp4;

			// hora transito
			const horaTransito = moment.duration(pilotoTemp.horaTransito);
			const horasSumarTransito = moment.duration(piloto.horaTransito);
			const tmp5 = horaTransito.add(horasSumarTransito).format("hh:mm");
			tripulante[posicion].horaTransito =
				tmp5.length === 2 ? `00:${tmp5}` : tmp5;
		}
	});

	// console.table(retirarTripulante);
	//console.log(tripulante);
};

const getHBSheet = (data) => {
	// Aca se filtra la data de los Instructores = (IVA)
	corregirData(data);
	return [
		{
			columns: styleHBHeaders(),
			data: styleHBData(data),
		},
	];
};

const calcularBloque = (atd, ata) => {
	const atdTmp = moment.duration(atd);
	const ataTmp = moment.duration(ata);

	if (ataTmp < atdTmp) {
		ataTmp.add(1, "d");
	}

	let bloque = ataTmp.clone().subtract(atdTmp);
	return bloque._data.hours === 0
		? `00:${bloque.format("hh:mm")}`
		: bloque.format("hh:mm");
};

const styleRawHeaders = () => {
	return [
		{ title: "ID Comercial", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "FECHA", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "RUTA", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "TAIL", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "DEPARTURE TIME", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "ARRIVAL TIME", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "BLOCK HOUR", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "CEDULA CAPITAN", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "NOMBRE CAPITAN", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "LICENCIA CAPITAN", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "CEDULA COPILOTO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "NOMBRE COPILOTO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "LICENCIA COPILOTO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "CEDULA INGENIERO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "NOMBRE INGENIERO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "LICENCIA INGENIERO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "CEDULA TÉCNICO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "NOMBRE TÉCNICO", width: { wpx: 100 }, style: getCellStyle() },
		{ title: "LICENCIA TÉCNICO", width: { wpx: 100 }, style: getCellStyle() },
		{
			title: "CEDULA CAPITAN OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "NOMBRE CAPITAN OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "LICENCIA CAPITAN OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "CEDULA COPILOTO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "NOMBRE COPILOTO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "LICENCIA COPILOTO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "CEDULA INGENIERO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "NOMBRE INGENIERO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "LICENCIA INGENIERO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "CEDULA TÉCNICO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "NOMBRE TÉCNICO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{
			title: "LICENCIA TÉCNICO OBSERVADOR",
			width: { wpx: 100 },
			style: getCellStyle(),
		},
		{ title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
	];
};

const styleRawData = (data) => {
	return data.map((data) => [
		{
			value: data.id_comercial ? data.id_comercial : "",
			style: getCellStyle(),
		},
		{
			value: data.fecha ? data.fecha.split("T")[0] : "",
			style: getCellStyle(),
		},
		{ value: data.ruta ? data.ruta : "", style: getCellStyle() },
		{ value: data.tail ? data.tail : "", style: getCellStyle() },
		{
			value: data.departure_time ? data.departure_time : "",
			style: getCellStyle(),
		},
		{
			value: data.arrival_time ? data.arrival_time : "",
			style: getCellStyle(),
		},
		{
			value:
				data.departure_time !== "" && data.arrival_time !== ""
					? calcularBloque(data.departure_time, data.arrival_time)
					: "",
			style: getCellStyle(),
		},

		{
			value: data.cedula_capitan ? data.cedula_capitan : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_capitan ? data.nombre_capitan : "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_capitan ? data.licencia_capitan : "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_copiloto ? data.cedula_copiloto : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_copiloto ? data.nombre_copiloto : "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_copiloto ? data.licencia_copiloto : "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_ingeniero ? data.cedula_ingeniero : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_ingeniero ? data.nombre_ingeniero : "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_ingeniero ? data.licencia_ingeniero : "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_tecnico ? data.nombre_tecnico : "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_tecnico ? data.nombre_tecnico : "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_tecnico ? data.licencia_tecnico : "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_capitan_observador
				? data.cedula_capitan_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_capitan_observador
				? data.nombre_capitan_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_capitan_observador
				? data.licencia_capitan_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_copiloto_observador
				? data.cedula_copiloto_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_copiloto_observador
				? data.nombre_copiloto_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_copiloto_observador
				? data.licencia_copiloto_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_ingeniero_observador
				? data.cedula_ingeniero_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_ingeniero_observador
				? data.nombre_ingeniero_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_ingeniero_observador
				? data.licencia_ingeniero_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.cedula_tecnico_observador
				? data.nombre_tecnico_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.nombre_tecnico_observador
				? data.nombre_tecnico_observador
				: "",
			style: getCellStyle(),
		},
		{
			value: data.licencia_tecnico_observador
				? data.licencia_tecnico_observador
				: "",
			style: getCellStyle(),
		},
		{ value: "" },
	]);
};

const styleHBHeaders = () => {
	const headerStyle = getCellStyle(
		getFont(undefined, true, undefined),
		getFill(colors["azulClaro"])
	);

	return [
		{ title: "NOMBRE", width: { wpx: 100 }, style: headerStyle },
		{ title: "CEDULA", width: { wpx: 100 }, style: headerStyle },
		{ title: "LICENCIA", width: { wpx: 100 }, style: headerStyle },
		{ title: "HH BLOQUE", width: { wpx: 100 }, style: headerStyle },
		{ title: "MM BLOQUE", width: { wpx: 100 }, style: headerStyle },
		{ title: "HH TRANSITO", width: { wpx: 100 }, style: headerStyle },
		{ title: "MM TRANSITO", width: { wpx: 100 }, style: headerStyle },
		{ title: "HH NOCTURNAS", width: { wpx: 100 }, style: headerStyle },
		{ title: "MM NOCTURNAS", width: { wpx: 100 }, style: headerStyle },
		{ title: "HH EXTRA DIA", width: { wpx: 100 }, style: headerStyle },
		{ title: "MM EXTRA DIA", width: { wpx: 100 }, style: headerStyle },
		{ title: "HH EXTRA NOCHE", width: { wpx: 100 }, style: headerStyle },
		{ title: "MM EXTRA NOCHE", width: { wpx: 100 }, style: headerStyle },
		{ title: "", width: { wpx: 100 }, style: { font: { sz: "28" } } }, // Esta columna hace que el encabezado tenga una altura de aproximadamente 50 px
	];
};

const styleHBData = (data) => {
	const numberStyle = getCellStyle(undefined, undefined, undefined, 1);
	return data.map((data) => [
		{ value: data.nombre ? data.nombre : "", style: getCellStyle() },
		{ value: data.cedula ? Number(data.cedula) : "", style: numberStyle },
		{ value: data.licencia ? data.licencia : "", style: numberStyle },
		{
			value: data.horaBloque ? parseInt(data.horaBloque?.split(":")[0]) : "",
			style: numberStyle,
		},
		{
			value: data.horaBloque ? parseInt(data.horaBloque?.split(":")[1]) : "",
			style: numberStyle,
		},
		{
			value: data.horaTransito ? parseInt(data.horaTransito.split(":")[0]) : "",
			style: numberStyle,
		},
		{
			value: data.horaTransito ? parseInt(data.horaTransito.split(":")[1]) : "",
			style: numberStyle,
		},
		{
			value: data.horaNocturna ? parseInt(data.horaNocturna.split(":")[0]) : "",
			style: numberStyle,
		},
		{
			value: data.horaNocturna ? parseInt(data.horaNocturna.split(":")[1]) : "",
			style: numberStyle,
		},
		{
			value: data.horaExtraDia ? parseInt(data.horaExtraDia.split(":")[0]) : "",
			style: numberStyle,
		},
		{
			value: data.horaExtraDia ? parseInt(data.horaExtraDia.split(":")[1]) : "",
			style: numberStyle,
		},
		{
			value: data.horaExtraNoche
				? parseInt(data.horaExtraNoche.split(":")[0])
				: "",
			style: numberStyle,
		},
		{
			value: data.horaExtraNoche
				? parseInt(data.horaExtraNoche.split(":")[1])
				: "",
			style: numberStyle,
		},
		{ value: "" },
	]);
};

export { getRawSheet, getHBSheet };
